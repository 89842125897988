<template>
    <div class="detail">
        <div class="inner section">
            <GreyBox :lists="noticeList" />

            <div class="detail-info">
                <div class="detail-thumb">
                    <SliderImage :images="getImage"/>
                </div>

                <div class="detail-text">
                    <h3>
                        {{ info['subject'] }}
                        <span>{{ getDday }}</span>
                    </h3>
                    <p>{{ info['regDt'] }} 작성</p>
                    <ul>
                        <li>강의분야: {{ getMainLecture(info['mainFieldCd']) }} / {{ getLecture(info['fieldCd']) }}</li>
                        <li>강의일시: {{ info['lectPeriod'] }} / {{ info['lectTime'] }}( {{ info['totalTime'] }}시간 )</li>
                        <li>강사비: {{ info['amt'] }}(시간당 : {{ info['estAmt'] }}원)</li>
                        <li>지역: {{ getArea1(info['area1']) }} / {{ getArea2(info['area2']) }}</li>
                    </ul>
                    <div>
                        <!-- <FontAwesomeIcon icon="thumbs-up" :class="{ 'active' : isThumbsUp }" @click="toggleThumbs" />
                        <FontAwesomeIcon icon="share" /> -->
                        <button type="button" @click="getFavorite(info['lcCd'])" class="white">
                            <font-awesome-icon v-if="info['likeCheck'] === 'N'" :icon="['far', 'star']"  />
                            <font-awesome-icon v-if="info['likeCheck'] === 'Y'" icon="fas fa-solid fa-star" class="orange"  />
                        </button>
                        <button @click="getRoomSet">1:1 문의하기</button>
                        <button class="orange" @click="showAsk = !showAsk">매칭 신청하기</button>
                    </div>
                </div>
            </div>

            <ul class="detail-spec">
                <!-- <li><span>일시</span>: {{ info['lectPeriod'] }} / {{ info['lectTime'] }} (총 {{ info['totalTime'] }}시간)</li> -->
                <li><span>수강대상</span>: {{ info['target'] }}</li> <!-- info cnt-->
                <li><span>강의장소</span>: {{ info['addr'] }} &nbsp; {{ info['addrDetail'] }}</li> <!-- info addr-->
                <li><span>제공장비</span>: {{ info['lectEq'] }}</li>
                <li><span>키워드</span>: {{ info['keyword'] }}</li>
                <li><span>접수기간</span>: {{ info['regPeriod'] }}</li>
            </ul>

            <div class="detail-description">

            </div>

            <div class="detail-spec">
                <h2>제출서류</h2>
                <li><span>필수사항: {{ info['reqDoc'] }}</span></li>
                <li><span>선택사항: {{ info['etcDoc'] }}</span></li>
            </div>

            <div class="detail-spec">
                <h2>제출서류 다운로드</h2>
                <li class="orange" v-for="(item, index) in info['fileList']" :key="index">
                    <a :href="`${downloadPath}/${item['filePath']}`" download>제출파일{{index + 1}}</a>
                </li>
            </div>

            <div class="detail-spec">
                <h2>기관정보</h2>
                <li><span>기관명: </span>{{ info['ogNm'] }}</li>
                <li><span>위치: </span>{{ info['ogAddr'] }}</li>
                <li><span>이메일: </span>{{ info['chEmail'] }}</li>
                <li><span>담당자: </span>{{ info['chPerNm'] }}</li>
                <li><span>전화번호: </span>{{ info['chPerTel'] }}</li>
            </div>

            <div v-html="info['lectMemo']"></div>
        </div>
    </div>

    <MainDetailPopup v-if="showAsk" @close-popup="getClosePopup" />
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import GreyBox from "@/components/common/NoticeBox.vue";
import MainDetailPopup from "@/views/include/MainDetailPopup.vue";
import SliderImage from "@/components/common/SliderImage.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from "vuex";
import { getMainInfo } from "@/assets/js/user/main";
import { getAxiosData } from "@/assets/js/function";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "MainDetail",
    components: {FontAwesomeIcon, MainDetailPopup, GreyBox, SliderImage, Confirm },
    computed: {
        ...mapState('data', {
            mainLecture : state => state.data.mainLecture,
            lecture     : state => state.data.lecture,
            area1       : state => state.data.area1,
            area2       : state => state.data.area2
        }),
        ...mapState('main', {
            info : state => state.main.info
        }),
        getImage() {
            const file = [];

            this.info['imgList'].map((item) => {
                file.push({
                    url : item.fileNm
                });
            })

            return file;
        },
        getDday() {
            const today      = new Date();
            const date       = this.info['regPeriod'].split(' ~ ');
            const targetDate = new Date(date[1]);

            const timeDiff = targetDate.getTime() - today.getTime();
            const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

            return daysDiff > 0 ? (daysDiff <= 3 ? '마감임박' : `D-${daysDiff}`) : 'D-Day';
        }
    },
    created() {
        this.getInfo();
    },
    data() {
        return {
            showAsk: false,
            noticeList: [
                { content: "이력서 및 관련 서류에 허위 정보를 기재하거나 위조하는 행위는 형사처벌의 대상이 될 수 있으며, 이로 인해 매칭 및 회원 자격이 취소될 수 있습니다." },
                { content: "이력서와 함께 제출된 모든 서류 및 개인정보는 매칭을 위한 수단으로만 사용되며, 다른 목적으로 이용되지 않습니다." },
                { content: "매칭 신청 전, 아직 기재하지 않은 사항이 있다면 추가하신 후 신청하시는 것이 좋습니다." },
            ],
            resultYn            : 'N',
            compYn              : 'N',
            isThumbsUp          : false,
            downloadPath        : 'https://elasticbeanstalk-ap-northeast-2-627549176645.s3.ap-northeast-2.amazonaws.com',
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            isFavorite          : false,
        }
    },
    methods: {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.compYn === 'N')
            {
                if(this.resultYn === 'Y')
                {
                    this.getRoomResult();
                }
            }

            if(this.compYn === 'Y')
            {
                this.compYn = 'N';
                this.$router.push('/user/mypage/message');
            }
        },
        async getFavorite(lcCd) {
            try
            {
                const res = await getAxiosData('https://data.wecls.com/organ/mainList/likeState', {lcCd : lcCd});

                if(res.status === 200)
                {
                    this.info['likeCheck'] = (this.info['likeCheck'] === 'Y' ? 'N' : 'Y');
                }
            }
            catch(error)
            {
                console.log(error);
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'inst');
                    this.$router.push('/login');
                }
                else
                {
                    alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
                    history.back();
                }
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getMainLecture(value) {
            const mainLecture = this.mainLecture.find(item => item['value'] === value);

            return mainLecture['name'];
        },
        getLecture(value) {
            const lecture = this.lecture.find(item => item['value'] === value);

            return lecture['name'];
        },
        getArea1(value) {
            const area1 = this.area1.find(item => item['value'] === value);

            return area1['name'];
        },
        getArea2(value) {
            const area2 = this.area2.find(item => item['value'] === value);

            return area2['name'];
        },
        getInfo() {
            const params = {
                lpCd : this.$route.query.lpCd
            };
            
            getMainInfo(params);
        },
        getClosePopup() {
            this.showAsk = false;
        },
        getRoomSet() {
            this.getConfirmSet('confirm', '문의', '1:1 문의 채팅방을 생성하시겠습니까?');
            this.resultYn = 'Y';
        },
        async getRoomResult() {
            try
            {
                const params = {
                    accCd : this.info['ogCd']
                }

                const res = await getAxiosData('https://data.wecls.com/chatMessage/createChat', params);

                console.log(res);

                if(res['status'] === 200)
                {
                    this.compYn = 'Y';
                    this.getConfirmSet('alert', '완료', '채팅방 생성이 완료되었습니다. 채팅 메세지 화면으로 이동합니다.');
                }

                const webSocket = new WebSocket('wss://vfqg19h2ja.execute-api.ap-northeast-2.amazonaws.com/production/');

                webSocket.onopen = () => {
                    const createRoom = {
                        action    : 'createRoom',
                        roomId    : res.data['roomId'],
                        instUser  : res.data['instUser'],
                        organUser : res.data['organUser']
                    };

                    console.log(createRoom);

                    webSocket.send(JSON.stringify(createRoom));
                };

                webSocket.onmessage = (result) => {
                    console.log(result);
                };
            }
            catch(error)
            {
                console.log(error);
                this.resultYn = 'N';

                if(error.response.data.status === 401)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'inst');
                    this.$router.push('/login');
                }
                else
                {
                    this.getConfirmSet('alert', '에러', '에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.detail {
    padding: 90px 0;

    &-info {
        margin: 46px 0;
        display: flex;
    }

    &-thumb {
        width: 374px;
        height: 374px;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 50px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &-text {
        width: calc(100% - 424px);
        position: relative;

        h3 {
            font-size: $font22;
            display: flex;
            align-items: center;

            span {
                background-color: $red-color-lv-5;
                color: $bk-color-lv-3;
                border-radius: 4px;
                padding: 1px 4px;
                display: inline-block;
                margin-left: 14px;
                font-size: $font16;
            }
        }

        p {
            color: $text-color-lv-4;
            font-size: $font18;
            font-weight: 500;
        }

        ul {
            margin-top: 50px;
            color: $text-color-lv-0;
            font-size: $font18;
            font-weight: 500;
            line-height: $font48;
        }

        div {
            position: absolute;
            text-align: right;
            right: 0;
            bottom: 0;

            svg{
                cursor: pointer;
                border: 1px soldi $bk-color-lv-6;
                color: $text-color-lv-3;
                font-size: $font28;
                margin-right: 20px;

                &.active{
                    color: $blue-color-lv-4;
                }
            }

            button {
                border-radius: 10px;
                background-color: $button-color-lv-1;
                padding: 16px 26px;
                color: $text-color-lv-5;
                font-size: $font16;
                font-weight: 700;

                &.orange {
                    background-color: $red-color-lv-5;
                    color: $bk-color-lv-3;
                }

                &.white{
                    background-color: $bk-color-lv-3;
                    box-shadow: 0 0 0 1px $button-color-lv-1 inset;
                    padding: 10px;
                    svg{
                        margin-right: 0;

                        &.orange{
                            color: $blue-color-lv-4;
                        }
                    }
                }
            }

            button+button {
                margin-left: 14px;
            }
        }
    }

    &-spec {
        padding: 20px 0;
        font-size: $font20;
        line-height: $font40;
        border-bottom: 1px solid $line-color-lv-3;

        h2 {
            font-size: $font28;
            margin-bottom: 15px;
        }

        li {
            color: $text-color-lv-5;

            span {
                color: $text-color-lv-0;
            }

            a[download] {
                color: $red-color-lv-5;
            }
        }
    }

    &-description {
        margin: 46px 0;
    }
}
</style>