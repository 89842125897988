import axios from "axios";

/**
 * @description 파일 체크
 */
export function getFileCheck(file)
{
    const allowedExtensions = ['.gif', '.jpg', '.jpeg', '.png'];
    const fileExtension     = file.name.toLowerCase().substr(file.name.lastIndexOf('.'));

    if(!allowedExtensions.includes(fileExtension)) 
    {
        return 'gif, jpg, jpeg, png 파일만 업로드 가능합니다.';
    }
  
    const maxSize = 2 * 1024 * 1024; // 2MB

    if(file.size > maxSize)
    {
        return '파일 크기는 2MB를 초과할 수 없습니다.';
    }
  
    return true;
}

/**
 * @description 숫자 콤마 처리
 */
export function getCommas(value) 
{
    if(typeof value !== 'number')
    {
        return value;
    }
    
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * @description 
 */
export function inputValCheck()
{
    
}

/**
 * @description 
 */
export function inputValue(type, value)
{
    switch(Number(type))
    {
        case 1: /** 숫자만 */
            return value.replace(/[^0-9]/g, '');
    }
}

/**
 * @description 오늘일자
 */
export function getTodayDate()
{
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

/**
 * @description 오늘일자(Y-m-d H:i)
 */
export function getTodayTimeDate()
{
    const currentDate = new Date();

    const year  = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day   = String(currentDate.getDate()).padStart(2, '0');
    const hour  = String(currentDate.getHours()).padStart(2, '0'); // 시 수정
    const min   = String(currentDate.getMinutes()).padStart(2, '0'); // 분 수정

    return `${year}-${month}-${day} ${hour}:${min}`;
}

/**
 * @description 형식별 날짜 가져오기
 */
export function getDate(type)
{
	let date = new Date();
	let val  = new Object();

	switch(type)
	{
		case 'day': /** 당일 */
            val['st'] = new Date(date.setDate(date.getDate()));
            val['et'] = new Date(date.setDate(date.getDate() +1));
			break;
		case 'mon': /** 이달 */
            val['st'] = new Date(date.getFullYear(), date.getMonth(), 1, 1);
            val['et'] = new Date(date.getFullYear(), date.getMonth() + 1, 0);
			break;
		case 'preMon': /** 지난달 */
            val['st'] = new Date(date.getFullYear(), date.getMonth() -1, 1);
            val['et'] = new Date(date.getFullYear(), date.getMonth(), 0);
			break;
		case 'mon1': /** 1개월 */
            val['st'] = new Date(date.getFullYear(), date.getMonth() -1, 1);
            val['et'] = date;
			break;
		case 'mon2': /** 2개월 */
            val['st'] = new Date(date.getFullYear(), date.getMonth() -2, 1);
            val['et'] = date;
			break;
		case 'mon3': /** 3개월 */
            val['st'] = new Date(date.getFullYear(), date.getMonth() -3, 1);
            val['et'] = date;
			break;
		case 'year1': /** 1년 */
            val['st'] = new Date(new Date().setYear(date.getFullYear() - 1));
            val['et'] = date;
			break;
	}

	return val;
}

/**
 * @description 날짜 변환 처리
 */
export function getConvertDate(date, type) 
{
	let y = date.getFullYear();
	let m = (date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1);
	let d = date.getDate() < 10 ? '0'+date.getDate() : date.getDate();
	let h = date.getHours() < 10 ? '0'+date.getHours() : date.getHours();
	let i = date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes();
	let s = date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds();

	let weekStr = ['일', '월', '화', '수', '목', '금', '토'];
	let w       = weekStr[date.getDay()];
	let yy      = y.toString().substring(2,4);

	let result;

	switch (type) 
    {
		case "yyyy-mm-dd" :
			result = y+'-'+m+'-'+d;
			break;
		case "yy-mm-dd" :
			result = yy +'-'+m+'-'+d;
			break;
		case "yy.mm.dd" :
			result = yy +'.'+m+'.'+d;
			break;
		case "yyyy-mm" :
			result = y+'-'+m;
			break;
		case "yy-mm" :
			result = yy +'-'+m;
			break;
		case "yy.mm" :
			result = yy +'.'+m;
			break;
		case "yyyy-mm-dd hh:ii" :
			result = y+'-'+m+'-'+d+' '+h+':'+i;
			break;
		case "yyyy-mm-dd hh:ii:ss" :
			result = y+'-'+m+'-'+d+' '+h+':'+i+':'+s;
			break;
		case "hh:ii" :
			result = h+':'+i;
			break;
		case "mm%dd%w% hh:ii" :
			result = m+'월'+d+'일('+w+') '+h+':'+i;
			break;
		case "mm%dd%w%" :
			result = m+'월'+d+'일('+w+')';
			break;
		case "mm%dd%w%2" :
			result = m+'월'+d+'일 '+w+'요일';
			break;
		case "mm%dd%" :
			result = m+'월'+d+'일';
			break;
		case "yyyy" :
			result = y;
			break;
		case "mm" :
			result = m;
			break;
		case "ymdhis" :
			result = String(y)+String(m)+String(d)+String(h)+String(i)+String(s)
			break;
		case "mm-dd":
			result = m+'-'+d;
			break;
		default :
			result = y+'-'+m+'-'+d;
			break;
	}

	return result;
}

export async function confirmPayment(secretKey, requestData) 
{
    // 토스페이먼츠 API는 시크릿 키를 사용자 ID로 사용하고, 비밀번호는 사용하지 않습니다.
    // 비밀번호가 없다는 것을 알리기 위해 시크릿 키 뒤에 콜론을 추가합니다.
    // @docs https://docs.tosspayments.com/reference/using-api/authorization#%EC%9D%B8%EC%A6%9D
    const encryptedSecretKey = btoa(secretKey+":");

    // ------ 결제 승인 API 호출 ------
    // @docs https://docs.tosspayments.com/guides/payment-widget/integration#3-결제-승인하기
    const response = await fetch("https://api.tosspayments.com/v1/payments/confirm", {
		method	: "POST",
		headers	: {
			"Authorization": `Basic ${encryptedSecretKey}`,
			"Content-Type": "application/json",
		},
		body	: JSON.stringify(requestData),
    });
    
    const json = await response.json();

    return { response, json };
}

/**
 * @description axios 통신
 */
export async function getAxiosData(url, params)
{   
    const instance = axios.create({
        withCredentials : true,
        headers : {
            'X-Subdomain'  : window.location.hostname
        }
    });

    const res = await instance.post(url, JSON.stringify(params));

    return res.data;
}


/**
 * @description axios 통신(첨부 파일)
 */
export async function getAxiosData2(url, formData) {
    const instance = axios.create({
        withCredentials: true
    });

    const res = await instance.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return res.data;
}