/**
 * @description 대쉬보드 vuex 모듈 관련
 * @author 정해원, @date 2023-11-22
 */
const dashUserModule = {
    namespaced: true,
    state: {
        dashInfo: getDashInfo() /* 대쉬 보드 정보 */,
    },
    mutations: {
        setDashInfo(state, newDashInfo) {
            state.dashInfo = newDashInfo;
        }
    },
};

function getDashInfo() {
    return {
        matchReady  : 0, // 초기값을 0으로 변경했습니다.
        matchComp   : 0,
        matchFailed : 0,
        instReady   : 0,
        instWeek    : 0,
        instEnd     : 0,
        payReady    : 0,
        payComp     : 0,
        payPrice    : 0,
        rateReady   : 0,
        rateComp    : 0,
        rateTotal   : 0
    };
}

export default dashUserModule;
