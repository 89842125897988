/**
 * @description 입력 필요 data
 * @author 김원명, @date 2023-12-12
 */

const dataModule = {
    namespaced: true,
    state: {
        data : {
            /** */
            dateType  : [
                { value : 'mon', name : '이달' },
                { value : 'day', name : '당일' },
                { value : 'mon1', name : '1개월' },
                { value : 'mon2', name : '2개월' },
                { value : 'mon3', name : '3개월' },
                { value : 'year1', name : '1년' }
            ],
            /** 지역1 */
            area1 : [
                { name : '전국', value : '' },
                { name : '서울', value : 'SE' },
                { name : '부산', value : 'BU' },
                { name : '대구', value : 'DG' },
                { name : '대전', value : 'DJ' },
                { name : '광주', value : 'GJ' },
                { name : '인천', value : 'IC' },
                { name : '세종', value : 'SJ' },
                { name : '울산', value : 'US' },
                { name : '경기', value : 'KG' },
                { name : '강원', value : 'GW' },
                { name : '충북', value : 'CN' },
                { name : '충남', value : 'CS' },
                { name : '전북', value : 'JN' },
                { name : '전남', value : 'JS' },
                { name : '경북', value : 'KN' },
                { name : '경남', value : 'KS' },
                { name : '제주', value : 'JJ' }
            ],
            /** 지역2 */
            area2 : [
                {key :'SE', value :'SE01', name : '종로구'},
                {key :'SE', value :'SE02', name : '중구'},
                {key :'SE', value :'SE03', name : '용산구'},
                {key :'SE', value :'SE04', name : '성동구'},
                {key :'SE', value :'SE05', name : '광진구'},
                {key :'SE', value :'SE06', name : '동대문구'},
                {key :'SE', value :'SE07', name : '중랑구'},
                {key :'SE', value :'SE08', name : '성북구'},
                {key :'SE', value :'SE09', name : '강북구'},
                {key :'SE', value :'SE10', name : '도봉구'},
                {key :'SE', value :'SE11', name : '노원구'},
                {key :'SE', value :'SE12', name : '은평구'},
                {key :'SE', value :'SE13', name : '서대문구'},
                {key :'SE', value :'SE14', name : '마포구'},
                {key :'SE', value :'SE15', name : '양천구'},
                {key :'SE', value :'SE16', name : '강서구'},
                {key :'SE', value :'SE17', name : '구로구'},
                {key :'SE', value :'SE18', name : '금천구'},
                {key :'SE', value :'SE19', name : '영등포구'},
                {key :'SE', value :'SE20', name : '동작구'},
                {key :'SE', value :'SE21', name : '관악구'},
                {key :'SE', value :'SE22', name : '서초구'},
                {key :'SE', value :'SE23', name : '강남구'},
                {key :'SE', value :'SE24', name : '송파구'},
                {key :'SE', value :'SE25', name : '강동구'},
                {key :'BU', value :'BU01', name : '중구'},
                {key :'BU', value :'BU02', name : '서구'},
                {key :'BU', value :'BU03', name : '동구'},
                {key :'BU', value :'BU04', name : '영도구'},
                {key :'BU', value :'BU05', name : '부산진구'},
                {key :'BU', value :'BU06', name : '동래구'},
                {key :'BU', value :'BU07', name : '남구'},
                {key :'BU', value :'BU08', name : '북구'},
                {key :'BU', value :'BU09', name : '강서구'},
                {key :'BU', value :'BU10', name : '해운대구'},
                {key :'BU', value :'BU11', name : '사하구'},
                {key :'BU', value :'BU12', name : '금정구'},
                {key :'BU', value :'BU13', name : '연제구'},
                {key :'BU', value :'BU14', name : '수영구'},
                {key :'BU', value :'BU15', name : '사상구'},
                {key :'BU', value :'BU16', name : '기장군'},
                {key :'DG', value :'DG01', name : '중구'},
                {key :'DG', value :'DG02', name : '동구'},
                {key :'DG', value :'DG03', name : '서구'},
                {key :'DG', value :'DG04', name : '남구'},
                {key :'DG', value :'DG05', name : '북구'},
                {key :'DG', value :'DG06', name : '수성구'},
                {key :'DG', value :'DG07', name : '달서구'},
                {key :'DG', value :'DG08', name : '달성군'},
                {key :'DG', value :'DG09', name : '군위군'},
                {key :'IC', value :'IC01', name : '중구'},
                {key :'IC', value :'IC02', name : '동구'},
                {key :'IC', value :'IC03', name : '미추홀구'},
                {key :'IC', value :'IC04', name : '연수구'},
                {key :'IC', value :'IC05', name : '남동구'},
                {key :'IC', value :'IC06', name : '부평구'},
                {key :'IC', value :'IC07', name : '계양구'},
                {key :'IC', value :'IC08', name : '서구'},
                {key :'IC', value :'IC09', name : '강화군'},
                {key :'IC', value :'IC10', name : '옹진군'},
                {key :'GJ', value :'GJ01', name : '동구'},
                {key :'GJ', value :'GJ02', name : '서구'},
                {key :'GJ', value :'GJ03', name : '남구'},
                {key :'GJ', value :'GJ04', name : '북구'},
                {key :'GJ', value :'GJ05', name : '광산구'},
                {key :'DJ', value :'DJ01', name : '동구'},
                {key :'DJ', value :'DJ02', name : '중구'},
                {key :'DJ', value :'DJ03', name : '서구'},
                {key :'DJ', value :'DJ04', name : '유성구'},
                {key :'DJ', value :'DJ05', name : '대덕구'},
                {key :'US', value :'US01', name : '중구'},
                {key :'US', value :'US02', name : '남구'},
                {key :'US', value :'US03', name : '동구'},
                {key :'US', value :'US04', name : '북구'},
                {key :'US', value :'US05', name : '울주군'},
                {key :'KG', value :'KG01', name : '수원시'},
                {key :'KG', value :'KG02', name : '용인시'},
                {key :'KG', value :'KG03', name : '고양시'},
                {key :'KG', value :'KG04', name : '성남시'},
                {key :'KG', value :'KG05', name : '화성시'},
                {key :'KG', value :'KG06', name : '부천시'},
                {key :'KG', value :'KG07', name : '남양주시'},
                {key :'KG', value :'KG08', name : '안산시'},
                {key :'KG', value :'KG09', name : '평택시'},
                {key :'KG', value :'KG10', name : '안양시'},
                {key :'KG', value :'KG11', name : '시흥시'},
                {key :'KG', value :'KG12', name : '파주시'},
                {key :'KG', value :'KG13', name : '김포시'},
                {key :'KG', value :'KG14', name : '의정부시'},
                {key :'KG', value :'KG15', name : '광주시'},
                {key :'KG', value :'KG16', name : '하남시'},
                {key :'KG', value :'KG17', name : '광명시'},
                {key :'KG', value :'KG18', name : '군포시'},
                {key :'KG', value :'KG19', name : '양주시'},
                {key :'KG', value :'KG20', name : '오산시'},
                {key :'KG', value :'KG21', name : '이천시'},
                {key :'KG', value :'KG22', name : '안성시'},
                {key :'KG', value :'KG23', name : '구리시'},
                {key :'KG', value :'KG24', name : '의왕시'},
                {key :'KG', value :'KG25', name : '포천시'},
                {key :'KG', value :'KG26', name : '여주시'},
                {key :'KG', value :'KG27', name : '동두천시'},
                {key :'KG', value :'KG28', name : '과천시'},
                {key :'KG', value :'KG29', name : '양평군'},
                {key :'KG', value :'KG30', name : '가평군'},
                {key :'KG', value :'KG31', name : '연천군'},
                {key :'GW', value :'GW01', name : '춘천시'},
                {key :'GW', value :'GW02', name : '원주시'},
                {key :'GW', value :'GW03', name : '강릉시'},
                {key :'GW', value :'GW04', name : '동해시'},
                {key :'GW', value :'GW05', name : '태백시'},
                {key :'GW', value :'GW06', name : '속초시'},
                {key :'GW', value :'GW07', name : '삼척시'},
                {key :'GW', value :'GW08', name : '홍천군'},
                {key :'GW', value :'GW09', name : '횡성군'},
                {key :'GW', value :'GW10', name : '영월군'},
                {key :'GW', value :'GW11', name : '평창군'},
                {key :'GW', value :'GW12', name : '정선군'},
                {key :'GW', value :'GW13', name : '철원군'},
                {key :'GW', value :'GW14', name : '화천군'},
                {key :'GW', value :'GW15', name : '양구군'},
                {key :'GW', value :'GW16', name : '인제군'},
                {key :'GW', value :'GW17', name : '고성군'},
                {key :'GW', value :'GW18', name : '양양군'},
                {key :'CN', value :'CN01', name : '청주시'},
                {key :'CN', value :'CN02', name : '충주시'},
                {key :'CN', value :'CN03', name : '제천시'},
                {key :'CN', value :'CN04', name : '보은군'},
                {key :'CN', value :'CN05', name : '옥천군'},
                {key :'CN', value :'CN06', name : '영동군'},
                {key :'CN', value :'CN07', name : '증평군'},
                {key :'CN', value :'CN08', name : '진천군'},
                {key :'CN', value :'CN09', name : '괴산군'},
                {key :'CN', value :'CN10', name : '음성군'},
                {key :'CN', value :'CN11', name : '단양군'},
                {key :'CS', value :'CS01', name : '천안시'},
                {key :'CS', value :'CS02', name : '공주시'},
                {key :'CS', value :'CS03', name : '보령시'},
                {key :'CS', value :'CS04', name : '아산시'},
                {key :'CS', value :'CS05', name : '서산시'},
                {key :'CS', value :'CS06', name : '논산시'},
                {key :'CS', value :'CS07', name : '계룡시'},
                {key :'CS', value :'CS08', name : '당진시'},
                {key :'CS', value :'CS09', name : '금산군'},
                {key :'CS', value :'CS10', name : '부여군'},
                {key :'CS', value :'CS11', name : '서천군'},
                {key :'CS', value :'CS12', name : '청양군'},
                {key :'CS', value :'CS13', name : '홍성군'},
                {key :'CS', value :'CS14', name : '예산군'},
                {key :'CS', value :'CS15', name : '태안군'},
                {key :'JN', value :'JN01', name : '전주시'},
                {key :'JN', value :'JN02', name : '익산시'},
                {key :'JN', value :'JN03', name : '군산시'},
                {key :'JN', value :'JN04', name : '정읍시'},
                {key :'JN', value :'JN05', name : '남원시'},
                {key :'JN', value :'JN06', name : '김제시'},
                {key :'JN', value :'JN07', name : '무주군'},
                {key :'JN', value :'JN08', name : '완주군'},
                {key :'JN', value :'JN09', name : '부안군'},
                {key :'JN', value :'JN10', name : '고창군'},
                {key :'JN', value :'JN11', name : '임실군'},
                {key :'JN', value :'JN12', name : '순창군'},
                {key :'JN', value :'JN13', name : '진안군'},
                {key :'JN', value :'JN14', name : '장수군'},
                {key :'JS', value :'JS01', name : '목포시'},
                {key :'JS', value :'JS02', name : '여수시'},
                {key :'JS', value :'JS03', name : '순천시'},
                {key :'JS', value :'JS04', name : '나주시'},
                {key :'JS', value :'JS05', name : '광양시'},
                {key :'JS', value :'JS06', name : '담양군'},
                {key :'JS', value :'JS07', name : '곡성군'},
                {key :'JS', value :'JS08', name : '구례군'},
                {key :'JS', value :'JS09', name : '고흥군'},
                {key :'JS', value :'JS10', name : '보성군'},
                {key :'JS', value :'JS11', name : '화순군'},
                {key :'JS', value :'JS12', name : '장흥군'},
                {key :'JS', value :'JS13', name : '강진군'},
                {key :'JS', value :'JS14', name : '해남군'},
                {key :'JS', value :'JS15', name : '영암군'},
                {key :'JS', value :'JS16', name : '무안군'},
                {key :'JS', value :'JS17', name : '함평군'},
                {key :'JS', value :'JS18', name : '영광군'},
                {key :'JS', value :'JS19', name : '장성군'},
                {key :'JS', value :'JS20', name : '완도군'},
                {key :'JS', value :'JS21', name : '진도군'},
                {key :'JS', value :'JS22', name : '신안군'},
                {key :'KN', value :'KN01', name : '포항시'},
                {key :'KN', value :'KN02', name : '경주시'},
                {key :'KN', value :'KN03', name : '김천시'},
                {key :'KN', value :'KN04', name : '안동시'},
                {key :'KN', value :'KN05', name : '구미시'},
                {key :'KN', value :'KN06', name : '영주시'},
                {key :'KN', value :'KN07', name : '영천시'},
                {key :'KN', value :'KN08', name : '상주시'},
                {key :'KN', value :'KN09', name : '문경시'},
                {key :'KN', value :'KN10', name : '경산시'},
                {key :'KN', value :'KN11', name : '의성군'},
                {key :'KN', value :'KN12', name : '청송군'},
                {key :'KN', value :'KN13', name : '영양군'},
                {key :'KN', value :'KN14', name : '영덕군'},
                {key :'KN', value :'KN15', name : '청도군'},
                {key :'KN', value :'KN16', name : '고령군'},
                {key :'KN', value :'KN17', name : '성주군'},
                {key :'KN', value :'KN18', name : '칠곡군'},
                {key :'KN', value :'KN19', name : '예천군'},
                {key :'KN', value :'KN20', name : '봉화군'},
                {key :'KN', value :'KN21', name : '울진군'},
                {key :'KN', value :'KN22', name : '울릉군'},
                {key :'KS', value :'KS01', name : '창원시'},
                {key :'KS', value :'KS02', name : '김해시'},
                {key :'KS', value :'KS03', name : '양산시'},
                {key :'KS', value :'KS04', name : '진주시'},
                {key :'KS', value :'KS05', name : '거제시'},
                {key :'KS', value :'KS06', name : '통영시'},
                {key :'KS', value :'KS07', name : '사천시'},
                {key :'KS', value :'KS08', name : '밀양시'},
                {key :'KS', value :'KS09', name : '함안군'},
                {key :'KS', value :'KS10', name : '거창군'},
                {key :'KS', value :'KS11', name : '창녕군'},
                {key :'KS', value :'KS12', name : '고성군'},
                {key :'KS', value :'KS13', name : '하동군'},
                {key :'KS', value :'KS14', name : '합천군'},
                {key :'KS', value :'KS15', name : '남해군'},
                {key :'KS', value :'KS16', name : '함양군'},
                {key :'KS', value :'KS17', name : '산청군'},
                {key :'KS', value :'KS18', name : '의령군'},
                {key :'JJ', value :'JJ01', name : '제주시'},
                {key :'JJ', value :'JJ02', name : '서귀포시'}
            ],
            /** 학력 */
            edu  : [
                { value : '001', name : '학력무관'},
                { value : '002', name : '고등학교 졸업'},
                { value : '003', name : '대학 졸업 (2,3년)'},
                { value : '004', name : '대학교 졸업 (4년)'},
                { value : '005', name : '석사 졸업'},
                { value : '006', name : '박사 졸업'}
            ],
            /** 강의 분야(메인) */
            mainLecture : [
                {value: '011', name: '심리/상담'},
                {value: '012', name: '요리'},
                {value: '013', name: '연기'},
                {value: '014', name: '생활/취미'},
                {value: '015', name: '컴퓨터/IT'},
                {value: '016', name: '강의/강연'},
                {value: '017', name: '학습/지도'},
                {value: '018', name: '음악'},
                {value: '019', name: '댄스'},
                {value: '020', name: '운동/레저'},
                {value: '021', name: '미술'},
                {value: '022', name: '공예'}
            ],
            /** 강의 분야 */
            lecture : [
                {key : '011', value: '001', name: '미술치료'},
                {key : '011', value: '002', name: '음악치료'},
                {key : '011', value: '003', name: '놀이치료'},
                {key : '011', value: '004', name: '상담'},
                {key : '011', value: '005', name: '연극치료'},
                {key : '011', value: '006', name: '언어치료'},
                {key : '011', value: '007', name: '심리검사'},
                {key : '011', value: '008', name: '집단치료/상담'},
                {key : '011', value: '009', name: '스누젤렌'},
                {key : '011', value: '010', name: '보이타'},
                {key : '011', value: '011', name: '기타 심리/상담'},
                {key : '012', value: '012', name: '한식'},
                {key : '012', value: '013', name: '일식'},
                {key : '012', value: '014', name: '중식'},
                {key : '012', value: '015', name: '아시아요리'},
                {key : '012', value: '016', name: '양식'},
                {key : '012', value: '017', name: '펫푸드'},
                {key : '012', value: '018', name: '키즈쿠킹'},
                {key : '012', value: '019', name: '커피/음료/차'},
                {key : '012', value: '020', name: '베이킹'},
                {key : '012', value: '021', name: '술/전통주'},
                {key : '012', value: '022', name: '자격증취득'},
                {key : '012', value: '023', name: '기타'},
                {key : '013', value: '024', name: '방송연기'},
                {key : '013', value: '025', name: '연극'},
                {key : '013', value: '026', name: '성우'},
                {key : '013', value: '027', name: '뮤지컬'},
                {key : '013', value: '028', name: '인형극'},
                {key : '013', value: '029', name: '동화구연'},
                {key : '014', value: '030', name: '응급/구조'},
                {key : '014', value: '031', name: '마술'},
                {key : '014', value: '032', name: '드론'},
                {key : '014', value: '033', name: '꽃꽂이'},
                {key : '014', value: '034', name: '글쓰기/독서'},
                {key : '014', value: '035', name: '정리/수납'},
                {key : '014', value: '036', name: '경제/재테크'},
                {key : '014', value: '037', name: '체험'},
                {key : '014', value: '038', name: '사진'},
                {key : '014', value: '039', name: '다도'},
                {key : '014', value: '040', name: '보드게임'},
                {key : '014', value: '041', name: '역학'},
                {key : '014', value: '042', name: '기타'},
                {key : '015', value: '043', name: '영상편집'},
                {key : '015', value: '044', name: '포토샵/일러스트'},
                {key : '015', value: '045', name: '코딩'},
                {key : '015', value: '046', name: '게임'},
                {key : '015', value: '047', name: '컴퓨터 실용(한글,엑셀,PPT)'},
                {key : '015', value: '048', name: '컴퓨터 기초'},
                {key : '015', value: '049', name: '스마트폰 기초'},
                {key : '015', value: '050', name: 'SNS'},
                {key : '015', value: '051', name: '자격증취득'},
                {key : '015', value: '052', name: '기타 컴퓨터/IT'},
                {key : '016', value: '053', name: '워크샵'},
                {key : '016', value: '054', name: '기업연수'},
                {key : '016', value: '055', name: '면접컨설팅'},
                {key : '016', value: '056', name: '직업훈련'},
                {key : '016', value: '057', name: '인적성'},
                {key : '016', value: '058', name: '기타 강의/강연'},
                {key : '017', value: '059', name: '수어/점자'},
                {key : '017', value: '060', name: '한글기초'},
                {key : '017', value: '061', name: '검정고시'},
                {key : '017', value: '062', name: '전문자격증'},
                {key : '017', value: '063', name: '외국어'},
                {key : '017', value: '064', name: '회화'},
                {key : '017', value: '065', name: '교과목'},
                {key : '017', value: '066', name: '역사'},
                {key : '017', value: '067', name: '토론/스피치'},
                {key : '017', value: '068', name: '기타 학습/지도'},
                {key : '018', value: '069', name: '실용음악'},
                {key : '018', value: '070', name: '트로트'},
                {key : '018', value: '071', name: '악기연주'},
                {key : '018', value: '072', name: '합창'},
                {key : '018', value: '073', name: '국악'},
                {key : '018', value: '074', name: '전통음악'},
                {key : '018', value: '075', name: '비트박스'},
                {key : '018', value: '076', name: '성악'},
                {key : '018', value: '077', name: '작사/작곡'},
                {key : '018', value: '078', name: '랩'},
                {key : '018', value: '079', name: '기타 음악'},
                {key : '019', value: '080', name: '민속무용'},
                {key : '019', value: '081', name: '현대무용'},
                {key : '019', value: '082', name: '발레'},
                {key : '019', value: '083', name: '방송댄스'},
                {key : '019', value: '084', name: '비보이'},
                {key : '019', value: '085', name: '기타 댄스'},
                {key : '020', value: '086', name: '유아체육'},
                {key : '020', value: '087', name: '재활체육'},
                {key : '020', value: '088', name: '생활체육'},
                {key : '020', value: '089', name: '요가/필라테스'},
                {key : '020', value: '090', name: '수영'},
                {key : '020', value: '091', name: '헬스'},
                {key : '020', value: '092', name: '구기종목'},
                {key : '020', value: '093', name: '마라톤'},
                {key : '020', value: '094', name: '자전거'},
                {key : '020', value: '095', name: '실버체조'},
                {key : '020', value: '096', name: '기타 운동/레저'},
                {key : '021', value: '097', name: '동양화'},
                {key : '021', value: '098', name: '서양화'},
                {key : '021', value: '099', name: '캘리그래피'},
                {key : '021', value: '100', name: '드로잉'},
                {key : '021', value: '101', name: '만화/애니/웹툰'},
                {key : '021', value: '102', name: '아동미술'},
                {key : '021', value: '103', name: '퍼포먼스미술'},
                {key : '021', value: '104', name: '디자인'},
                {key : '021', value: '105', name: '서예'},
                {key : '021', value: '106', name: '기타 미술'},
                {key : '022', value: '107', name: '종이공예'},
                {key : '022', value: '108', name: '도자공예'},
                {key : '022', value: '109', name: '목공예'},
                {key : '022', value: '110', name: '유리공예'},
                {key : '022', value: '111', name: '가죽공예'},
                {key : '022', value: '112', name: '비즈공예'},
                {key : '022', value: '113', name: '라탄공예'},
                {key : '022', value: '114', name: '섬유공예'},
                {key : '022', value: '115', name: '기타 공예'},
            ],
            /** 경력 */
            career : [
                { value : '001', name : '신입'},
                { value : '002', name : '1년이상'},
                { value : '003', name : '2년이상'},
                { value : '004', name : '3년이상'},
                { value : '005', name : '4년이상'},
                { value : '006', name : '5년이상'},
                { value : '007', name : '10년이상'},
                { value : '008', name : '경력무관'}
            ],
            /** 나라 */
            country : [
                { value : 'KR', name : '대한민국' }
            ],
            /** 은행 */
            bank    : [
                { value: '001', name: '한국은행' },
                { value: '002', name: '산업은행' },
                { value: '003', name: '기업은행' },
                { value: '004', name: '국민은행' },
                { value: '005', name: '하나은행' },
                { value: '006', name: '수협은행' },
                { value: '007', name: '수출입은행' },
                { value: '008', name: '농협은행' },
                { value: '009', name: '지역농축협' },
                { value: '010', name: '우리은행' },
                { value: '011', name: '신한은행' },
                { value: '012', name: 'SC제일은행' },
                { value: '013', name: '한국씨티은행' },
                { value: '014', name: '대구은행' },
                { value: '015', name: '부산은행' },
                { value: '016', name: '광주은행' },
                { value: '017', name: '제주은행' },
                { value: '018', name: '전북은행' },
                { value: '019', name: '경남은행' },
                { value: '020', name: '새마을금고중앙회' },
                { value: '021', name: '신협' },
                { value: '022', name: '저축은행' },
                { value: '023', name: '우체국' },
                { value: '024', name: '케이뱅크' },
                { value: '025', name: '카카오뱅크' }
            ]
        }
    }
};



export default dataModule;
