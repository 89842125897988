<template>
    <div class="contact-box">
        <div class="contact-input">
            <label for="contact">문의하실 유형</label>
            <input type="text" id="gb" name="gb" :value="gb" placeholder="예. 이용문의, 강의문의, 사업제안 등" @input="getInputValue"/>
        </div>
        <div class="contact-input">
            <label for="detail">문의 세부내용</label>
            <input type="text" id="contents" name="contents" :value="contents" placeholder="예. 이력서 등록에 대해 궁금해요!" @input="getInputValue"/>
        </div>
        <div class="contact-input">
            <label for="tel">담당자 이메일 또는 전화번호</label>
            <input type="text" id="tel" name="tel" :value="tel" placeholder="연락 받으실 연락처를 입력해주세요." @input="getInputValue"/>
        </div>

        <div class="contact-input">
            <div class="flex">
                <input type="checkbox" name="privacy" id="privacy" checked class="mr-8">
                <label for="privacy" class="mb-0">개인정보 처리방침에 동의합니다
                </label>
            </div>
            <p>
                (목적) 상담 | (항목) 이메일, 전화번호 | (보유 및 이용기간) 최초 동의 시로부터 6개월
            </p>
        </div>

        <button type="button" @click="getContact">문의 보내기</button>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import Confirm from "@/components/alert/Confirm.vue";
import axios from "axios";

export default {
    name: "ContactBox",
    components: { Confirm },
    data() {
        return {
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            gb                  : '',
            contents            : '',
            tel                 : ''
        }
    },
    methods : {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.approval === 'Y')
            {
                this.getSend();
            }

            if(this.resultYn === 'Y')
            {
                location.reload();
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
            this.approval    = 'N';
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getInputValue() {
            const id    = event.target.id;
            const value = event.target.value;

            this[id] = value;
        },
        getValCheck() {
            if(this.gb === '')
            {
                this.getConfirmSet('alert', '확인', '문의하실 유형을 입력하세요.');
                return false;
            }

            if(this.contents === '')
            {
                this.getConfirmSet('alert', '확인', '문의하실 세부내용을 입력하세요.');
                return false;
            }

            if(this.tel === '')
            {
                this.getConfirmSet('alert', '확인', '연락처를 입력하세요.');
                return false;
            }

            const checkbox  = document.getElementById('privacy');

            if(!checkbox.checked)
            {
                this.getConfirmSet('alert', '확인', '개인정보 처리방침에 동의해주세요.');
                return false;
            }

            return true;
        },
        getContact() {
            if(!this.getValCheck())
            {
                return false;
            }

            this.getConfirmSet('confirm', '문의하기', '해당 내용을 문의하시겠습니까?');
            this.approval = 'Y';
        },
        async getSend() {
            try 
            {
                const instance = axios.create({
                    withCredentials : true,
                    headers : {
                        'X-Subdomain'  : window.location.hostname
                    }
                });

                const params = {
                    gb        : this.gb,
                    tel       : this.tel,
                    contents  : this.contents
                };

                console.log(params);
                
                const res = await instance.post('https://data.wecls.com/admin/faq/faqProc', params);

                console.log(res);

                if(res.status === 200)
                {
                    this.resultYn = 'Y';
                    this.approval = 'N';
                    this.getConfirmSet('alert', '문의완료', '문의하기가 완료되었습니다.');

                    this.gb         = '';
                    this.contents   = '';
                    this.tel        = '';
                }
            } 
            catch (error) 
            {
                console.log(error);
                this.approval = 'N';
                // this.getConfirmSet('alert', '에러', error.response.data.data);

                this.gb         = '';
                this.contents   = '';
                this.tel        = '';
            }
        },
        
    }
}
</script>

<style lang="scss" scoped>
.contact-box {
    width: 640px;
    padding: 60px 40px;
    background-color: $bk-color-lv-3;
    border-radius: 6px;
    @include shadow8;
    margin: 0 auto;

    .contact-input {
        text-align: left;

        .flex{
            align-items: center;
        }

        &:not(:last-child) {
            margin-bottom: 39px;
        }

        label {
            font-size: $font20;
            font-weight: 700;
            display: block;
            margin-bottom: 23px;
        }

        p{
            display: block;
            color: $text-color-lv-3;
            font-size: $font14;
            font-weight: 400;
            margin-left: 25px;
            margin-top: 5px;
        }

        input {
            display: block;
            width: 100%;
            background-color: $bk-color-lv-3;
            border: 1px solid $text-color-lv-3;
            border-radius: 6px;
            padding: 16px 18px;

            &[type="checkbox"]{
                max-width: 16px;
                max-height: 16px;
                width: 100%;
                height: 100%;
                color: white;
                accent-color: black;
            }
        }
    }

    button{
        background-color: $text-color-lv-2;
        color: $bk-color-lv-3;
        border-radius: 8px;
        text-align: center;
        font-size: $font20;
        font-weight: 700;
        width: 100%;
        padding: 17px;
    }
}
</style>