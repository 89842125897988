<template>
    <LabelTable title="이력서" class="mt-50">
        <tr>
            <th>강사 어필 문구</th>
            <td>
                <InputBox :inputType="'text'" :inputId="'appeal'" :inputValue="info['appeal']" 
                    maxLength="40" class="medium" placeholder="최대 30자 / 예: 유아와 함께 놀며 성장하는 특별한 교육을 제공합니다!" @input="getInputVal"/>
            </td>
        </tr>
        <tr>
            <th>증명사진</th>
            <td class="thumb-td">
                <div class="flex">
                    <RepImage imgTitle="대표사진" :inputId="'proofImg'" :imageView="info['proofImg']['file']" @file-selected="getFileSelect" @file-cleared="getFileDelete"/>
                    <ul>
                        <li>・파일의 확장자는 jpg, jpeg, png, bmp만 업로드 가능합니다.</li>
                        <li>・3:4비율의 이미지를 업로드하시길 권장드립니다.</li>
                    </ul>
                </div>
            </td>
        </tr>
        <tr>
            <th>이름</th>
            <td>
                <InputBox :inputType="'text'" :inputValue="info['name']" class="small" readonly/>
            </td>
        </tr>
        <tr>
            <th>전화번호</th>
            <td>
                <InputBox :inputType="'text'" :inputValue="info['tel']" class="small" readonly />
            </td>
        </tr>
        <tr>
            <th>생년월일</th>
            <td>
                <InputBox :inputType="'text'" :inputValue="info['birthday']" class="small" readonly />
            </td>
        </tr>
        <tr>
            <th>성별</th>
            <td>
                <InputBox :inputType="'text'" :inputValue="info['gender']" class="small" readonly />
            </td>
        </tr>
        <tr>
            <th>긴급 연락처</th>
            <td>
                <InputBox :inputType="'number'" :inputId="'emerTel'" :inputValue="info['emerTel']" class="small" @input="getInputVal" />
            </td>
        </tr>
        <tr>
            <th>희망 지역*</th>
            <td>
                <select v-model="info['area1']" id="area1" @change="getSelect">
                    <option v-for="(item, index) in area1" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </td>
        </tr>
        <tr>
            <th>선호 강의 요일</th>
            <td>
                <ul class="choose">
                    <li v-for="(day, index) in days" :key="index">
                        <input type="checkbox" :id="day" :value="day" :checked="getDaysState(day)" @change="getDays"/>
                        <label :for="day">{{ day }}</label>
                    </li>
                </ul>
            </td>
        </tr>
    </LabelTable>

    <LabelTable title="학력사항">
        <tr>
            <th>최종 학력</th>
            <td>
                <select v-model="info['acadAbil']" id="acadAbil" @change="getSelect">
                    <template v-for="(item, index) in edu">
                        <option v-if="item.value !== '001'" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </template>
                </select>
            </td>
        </tr>
        <tr>
            <th>최종 학교</th>
            <td>
                <InputBox :inputType="'text'" :inputId="'school'" :inputValue="info['school']" 
                    class="medium" placeholder="예: 부산대학교" @input="getInputVal"/>
            </td>
        </tr>
        <tr>
            <th>학과</th>
            <td>
                <InputBox :inputType="'text'" :inputId="'major'" :inputValue="info['major']" 
                    class="medium" placeholder="예: 경영학과" @input="getInputVal"/>
            </td>
        </tr>
    </LabelTable>

    <LabelTable title="경력">
        <tr>
            <th>강의 분야*</th>
            <td>
                <select v-model="getMainFieldCd" id="mainFieldCd" @change="getSelect">
                    <option v-for="(item, index) in mainLecture" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
                &nbsp;
                <select v-model="info['fieldCd']" id="fieldCd" @change="getSelect">
                    <option v-for="(item, index) in getFieldCd" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </td>
        </tr>
        <tr>
            <th>강사 경력</th>
            <td>
                <select v-model="info['career']" id="career" @change="getSelect">
                    <template v-for="(item, index) in career">
                        <option v-if="item.value !== '008'" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </template>
                </select>
            </td>
        </tr>
        <tr>
            <th>대표경력 3개<span>(수상, 경력 등)</span></th>
            <td>
                <div v-for="(rep, index) in info['repCareer']" :key="index">
                    <p>{{ index + 1 }}</p>
                    <InputBox :inputType="'text'" :inputValue="info['repCareer'][index]" 
                        placeholder="최대 20자" class="medium" @input="getInputRepVal(index)"/>
                </div>
            </td>
        </tr>
    </LabelTable>

    <div class="editor-box">
        <div>
            <NoticeBox simpleTitle="간단 소개글" showSimpleTitle class="blue3" :lists="simpleLists" showNotice/>
            <TextEditor v-model="info.instMemo"/>
        </div>

        <div class="mt-50">
            <NoticeBox simpleTitle="경력, 수상이력" showSimpleTitle class="blue3" :lists="historyLists" showNotice/>
            <TextEditor v-model="info.careerMemo"/>
        </div>
    </div>

    <LabelTable title="첨부파일" class="mt-10">
        <tr>
            <th>최종학력 증명 파일</th>
            <td>
                <FileBox fileBtn="파일첨부" :inputId="'finalEduImg'" :fileNm="info.finalEduImg ? info.finalEduImg.text : ''" greyButton="삭제하기"
                    @file-selected="getFileSelect" @file-cleared="getFileDelete"/>
            </td>
        </tr>
        <tr>
            <th>경력증명서 파일</th>
            <td>
                <FileBox fileBtn="파일첨부" :inputId="'credentialsImg'" :fileNm="info.credentialsImg ? info.credentialsImg.text : ''" greyButton="삭제하기"
                    @file-selected="getFileSelect" @file-cleared="getFileDelete"/>
            </td>
        </tr>
        <tr>
        <th>기타 증빙자료</th>
        <td>
            <div v-for="(file, index) in info.dataFile" :key="index">
                <FileBox :fileBtn="'파일첨부'" :inputId="'file' + index" :fileNm="file.text ? file.text : ''"
                        :greyButton="'삭제하기'" @file-selected="getDataFileSelect" @file-cleared="getDataFile('D', index)" />
            </div>
            <button type="button" @click="getDataFile('A')" class="blue-button">파일 추가 생성</button>
        </td>
    </tr>
    </LabelTable>

    <LabelTable title="이력서 공개 여부">
        <tr>
            <th>기관 이력서 공개 여부</th>
            <td>
                <select v-model="info['pubYn']" id="pubYn" style="width:140px; height:30px; ">
                    <option value = "Y">공개</option>
                    <option value = "N">거부</option>
                </select>
            </td>
        </tr>
    </LabelTable>
</template>

<script>
import InputBox from "@/components/input/InputBox.vue";
import NoticeBox from "@/components/common/NoticeBox.vue";
import TextEditor from "@/components/common/TextEditor.vue";
import LabelTable from "@/components/table/LabelTable.vue";
import FileBox from "@/components/input/FileBox.vue";
import RepImage from "@/components/input/RepImage.vue";

import { mapState } from 'vuex';
import { getProfileUser } from "@/assets/js/user/profile"

export default {
    name      : "ProfileTable",
    components: {RepImage, FileBox, LabelTable, TextEditor, NoticeBox,  InputBox},
    computed: {
        ...mapState('login', {
            cnt   : state => state.login.itCnt
        }),
        ...mapState('data', {
            area1       : state => state.data.area1,
            edu         : state => state.data.edu,
            mainLecture : state => state.data.mainLecture,
            lecture     : state => state.data.lecture,
            career      : state => state.data.career,
        }),
        ...mapState('profile', {
            info   : state => state.profile.info
        }),
        getMainFieldCd() {
            if(this.info['mainFieldCd'] === '')
            {
                this.$store.commit('profile/setMainFieldCd', this.mainLecture[0]['value']);
                return this.mainLecture[0]['value'];
            }
            else
            {
                return this.info['mainFieldCd'];
            }
        },
        getFieldCd() {
            const mainFieldCd = this.info['mainFieldCd'] === '' ? this.mainLecture[0]['value'] : this.info['mainFieldCd'];
            const fieldCd     = this.lecture.filter(item => item.key === mainFieldCd);

            if(fieldCd.length && !this.$route.params.lpCd)
            {
                this.$store.commit('profile/setFieldCd', fieldCd[0]['value']);
            }

            return fieldCd;
        },
    },
    data() {
        return {
            simpleLists : [
                {content: "자기소개서를 작성할 때에는 본인의 핵심 능력과 업무 스킬을 간략하게 표현하는 것이 중요합니다. 경험과 능력에 초점을 맞추어 3~5줄로 요약하는 것을 추천합니다."},
                {content: "자기소개서는 자신을 강조하는 핵심 요소들을 나열하는데 중점을 두어 작성합니다. 업무 경험과 스킬을 포함하여 간결하게 정리해주세요."},
                {content: "업무 경험을 간략하게 소개하는 것이 중요합니다. 핵심 능력과 스킬을 강조하여 업무에 적합한 자신을 어필하세요."},
                {content: "업무 스킬을 효과적으로 전달하기 위해 간략한 문구로 정리해주세요. 업무에 필요한 핵심 스킬을 강조하여 작성해보세요."},
                {content: "자기소개서를 작성할 때에는 핵심 내용을 간결하게 담아야 합니다. 본인의 업무 스킬과 능력을 정확하게 전달해주세요."},
            ],
            historyLists: [
                {content: "우선순위가 높은 업무부터 최신순으로 경력을 작성해주세요. 중요한 역할과 성과를 강조해보세요."},
                {content: "신입의 경우 직무와 관련된 대외활동, 인턴 경험 등이 있다면 작성하여 자신의 역량을 강조해주세요."},
                {content: "경력 작성 시 자신의 능력과 기술을 강조해주세요. 어떤 기술을 사용하고 어떤 분야에 능숙한지 설명해보세요."},
                {content: "업무 스킬을 효과적으로 전달하기 위해 간략한 문구로 정리해주세요. 업무에 필요한 핵심 스킬을 강조하여 작성해보세요."},
                {content: "경력을 작성할 때 전체적인 흐름과 일관성을 유지하는 것이 중요합니다. 자신의 전문성과 경력을 증명하기 위해 일관성을 유지해주세요.", class: "mb"},
                {content: "수상한 명칭과 수상을 받은 기관 또는 단체의 이름을 명확하게 기재해주세요."},
                {content: "수상한 날짜와 장소를 기재하여 어떤 상을 받았는지 정확히 표시해주세요."},
                {content: "기타 어필 사항이 있으면 기재해주세요."},
            ],
            days : ['월', '화', '수', '목', '금', '토', '일'],
            uploadFileGb : '',
            deleteFileGb : ''
        }
    },
    created() {
        this.getStatCheck();
    },
    methods : {
        async getStatCheck(){
            this.info['proofImg']['file'] = null;

            await getProfileUser();

            if(!this.info['itCd'])
            {
                if(this.cnt === 0)
                {
                    this.cnt++;
                    this.$emit('get-check');
                }
            }
        },
        getDataFile(gb, index = 0) {
            this.$store.commit('profile/getDataFile', { gb : gb, index : index });
        },
        getInputVal(event) {
            const id    = event.target.id;
            const value = event.target.value;

            this.info[id] = value;
        },
        getInputRepVal(index) {
            this.info['repCareer'][index] = event.target.value;
        },
        getDays() {
            const value     = event.target.value;
            const checkStat = event.target.checked;

            if (checkStat) 
            {
                this.info['prefDay'].push(value);
            } 
            else 
            {
                const dayList = this.info['prefDay'];
                const removeDay = dayList.indexOf(value);

                if (removeDay !== -1) 
                {
                    dayList.splice(removeDay, 1);
                }
            }
        },
        getDaysState(day) {
            const daysOfWeek = this.info && this.info['prefDay'];

            if (daysOfWeek && daysOfWeek.indexOf(day) !== -1) 
            {
                return true;
            } 
            else 
            {
                return false;
            }
        },
        getSelect(){
            const id    = event.target.id;
            const value = event.target.value;

            this.info[id] = value;
        },
        getFileSelect({inputId, file}) {
            switch(inputId)
            {
                case 'proofImg':
                    this.uploadFileGb = 'P';
                break;
                case 'finalEduImg':
                    this.uploadFileGb = 'E';
                break;
                case 'credentialsImg':
                    this.uploadFileGb = 'C';
                break;
            }

            const fileCheck = this.info['fileGb'].indexOf(item => item.fileGb === this.uploadFileGb);

            if(fileCheck !== -1)
            {
                this.info['fileGb'].splice(fileCheck, 1);
            }

            if(this.info['fileGb'].length === 0)
            {
                this.info['delYn'] = 'N';
            }

            this.$store.commit('profile/updateFile', { fileType: inputId, file: file });
        },
        getFileDelete(inputId) {
            if(this.info[inputId]['file'] !== null )
            {
                if(this.info[inputId]['gb'] === 'Y')
                {
                    this.info['delYn'] = 'Y';
                }

                switch(inputId)
                {
                    case 'proofImg':
                        this.deleteFileGb = 'P';
                    break;
                    case 'finalEduImg':
                        this.deleteFileGb = 'E';
                    break;
                    case 'credentialsImg':
                        this.deleteFileGb = 'C';
                    break;
                }

                let gbCheck = this.info['fileGb'].some(item => item.fileGb === this.deleteFileGb);

                if (!gbCheck) 
                {
                    this.info['fileGb'].push({fileGb : this.deleteFileGb, fileNo : 1});
                }
            }

            this.$store.commit('profile/clearFile', inputId);
        },
        getDataFileSelect({inputId, file}) {
            const index = Number(inputId.replace('file', ''));

            this.$store.commit('profile/getDataFileSelect', { index: index, file: file });
        }
    },
    watch   : {
        'info.instMemo'(newVal) {
            this.$emit('update:instMemo', newVal);
        },
        'info.careerMemo'(newVal) {
            this.$emit('update:careerMemo', newVal);
        }
    }
}
</script>

<style lang="scss" scoped>
.editor-box {
    border-radius: 4px;
    background-color: $bk-color-lv-3;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.20);
    padding: 12px 20px 100px 20px;
    margin-top: 10px;

    .editor {
        margin-top: 46px;
    }
}

.mt-50 {
    margin-top: 50px;
}

.choose {
    padding: 10px 0;

    li {
        margin-right: 8.5px;
        display: inline-block;

        input {
            display: none;

            &:checked {
                & + label {
                    background-color: $blue-color-lv-4;
                    color: $bk-color-lv-3;
                    border: 1px solid $blue-color-lv-4;
                }
            }
        }

        label {
            padding: 12px 8px;
            border-radius: 20px;
            border: 1px solid $line-color-lv-1;
            cursor: pointer;
            font-size: $font12;
            font-weight: 700;
        }
    }
}

table {
    td {
        position: relative;
        .blue-button { 
            padding: 8px 20px;
            border-radius: 2px;
            height: 40px;
            background-color: $bk-color-lv-3;
            font-size: $font14;
            color: $blue-color-lv-4;
            border: 1px solid $blue-color-lv-4;
            cursor: pointer;
            min-width: 80px;
            margin-left: 20px;
            display: flex;
            align-items: center;
            font-weight: 500;
            position: absolute;
            top: 10px;
            right: 10px;
        }

        &.thumb-td{
            .flex{
                align-items: end;

                ul{
                    margin-left: 15px;
                    li{
                        color: black;
                    }
                }
            }
        }
    }
}

select {
    width: 140px;
    height: 38px;
    border: 1px solid #E5E5EC;
    font-size: 0.875rem;
    font-weight: 400;
    border-radius: 4px;
}
</style>