<template>
    <div class="grade">
        <MypageHeader />
        <div class="inner section">
            <NoticeBox :lists="noticeLists" showTitle title="리뷰 관리" />
            <SortListCard :sorts="info" class="margin" />
            <GreySelectBox class="margin">
                <Select :selectId="'type'" :selectOptions="dataType" :selectVal="search['type']" :selectChange="getSelectSet" class="small" :labelText="'기간 구분'" />
                &nbsp;
                <DateBox class="regular" :inputId="'startDt'" :inputValue="search['startDt']" :selectFunction="getSelectDate" :timePicker="false" />
                &nbsp;&nbsp;
                <DateBox class="regular" :inputId="'endDt'" :inputValue="search['endDt']" :selectFunction="getSelectDate" :timePicker="false" />
            </GreySelectBox>
            <BasicTable :columns="columns" :rows="list" class="margin" @row-click="getListClick"/>
        </div>
    </div>

    <WhiteAlert v-if="showAsk" buttonText="작성완료" title="답글" @close-popup="showAsk = false" @confirm-review="getReviewResult" class="blue">
        <textarea v-model="reviewText" placeholder="내용" @input="getReviewText"></textarea>
    </WhiteAlert>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmResult"/>
</template>

<script>
import SortListCard from "@/components/card/SortListCard.vue";
import Select from "@/components/input/Select.vue";
import GreySelectBox from "@/components/common/GreySelectBox.vue";
import BasicTable from "@/components/table/BasicTable.vue";
import MypageHeader from "@/views/user/mypage/MypageHeader.vue";
import NoticeBox from "@/components/common/NoticeBox.vue";
import DateBox from "@/components/input/DateBox.vue";
import WhiteAlert from "@/components/alert/WhiteAlert.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from "vuex";
import { getGradeInfo } from "@/assets/js/user/grade";
import { getAxiosData, getDate, getConvertDate } from "@/assets/js/function";

export default {
    name: "GrageMng",
    components: { Select, DateBox, WhiteAlert, NoticeBox, MypageHeader, BasicTable, GreySelectBox, SortListCard, Confirm },
    data() {
        return {
            noticeLists : [
                { content: "기관에서 리뷰를 남기면 그에 대한 답글을 달 수 있으며, 빠른 시일 내에 답변을 작성해주시길 바랍니다." },
                { content: "비난, 비방하는 글이 리뷰로 작성된 경우, 위클즈 고객센터로 문의해주시길 바랍니다." },
                { content: "좋았던 점과 개선할 점을 적극적으로 반영하여, 더 좋은 강의로 발전하는 계기가 되길 바랍니다." },
            ],
            columns     : [
                { key: "regDt", header: "등록 일시", class: "w12" },
                { key: "writer", header: "작성자", class: "w7" },
                { key: "rate", header: "평점", class: "w6" },
                { key: "status", header: "답글", class: "w6" },
                { key: "subject", header: "강의명", class: "w28 blue" },
                { key: "memo", header: "평점 내용", class: "w41" }
            ],
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            irCd                : '',
            lcCd                : '',
            showAsk             : false,
            reviewText          : ''
        }
    },
    created() {
        this.getCheck()
    },
    computed: {
        ...mapState('data', {
            dataType : state => state.data.dateType
        }),
        ...mapState('grade', {
            search  : state => state.grade.search,
            info    : state => state.grade.info,
            list    : state => state.grade.list
        })
    },
    methods: {
        getConfirmResult() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getCheck() {
            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt']
            };

            getGradeInfo(params);
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            const value = event['value'];
            const date  = getDate(value);

            this.search['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
            this.search['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');

            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
            };

            getGradeInfo(params);
        },
        getSelectDate(date, id) {
            this.search[id] = date;

            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
            };

            getGradeInfo(params);
        },
        getListClick(data) {
            this.reviewText = data['reviewText'];
            this.irCd       = data['irCd'];
            this.lcCd       = data['lcCd'];
            this.showAsk    = true;
        },
        getReviewText() {
            this.reviewText = event.target.value;
        },
        async getReviewResult() {
            try 
            {
                const params = {
                    irCd    : this.irCd,
                    lcCd    : this.lcCd,
                    memo    : this.reviewText
                };

                console.log(params);

                const res = await getAxiosData('https://data.wecls.com/inst/dashboard/rateProc', params);

                this.showAsk    = false;
                this.reviewText = '';

                if (res['status'] === 200) 
                {
                    this.getConfirmSet('alert', '완료', '답변이 등록되었습니다.');
                    const params = {
                        startDt : this.search['startDt'],
                        endDt   : this.search['endDt'],
                    };

                    getGradeInfo(params);
                }
                else 
                {
                    this.getConfirmSet('alert', '에러', '에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }

            } 
            catch (error) 
            {
                console.log(error);
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'inst');
                    this.$router.push('/login');
                }
                else
                {
                    this.getConfirmSet('alert', '에러', '에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.grade {
    padding: 90px 0;

    .margin {
        margin-top: 46px;
    }
}

.alert {
    .alert-content {
        text-align: center;
        font-size: $font12;
        font-weight: 400;
        color: $text-color-lv-1;
    }

    textarea {
        padding: 14px;
        border-radius: 4px;
        border: 1px solid $line-color-lv-2;
        background-color: $bk-color-lv-3;
        width: 300px;
        height: 120px;
        resize: none;
        margin-bottom: 25px;
        font-size: $font14;
        font-weight: 400;
        color: $text-color-lv-1;
    }
}
</style>