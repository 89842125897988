<template>
    <div class="join intro">
        <MobileBox title="회원가입 2/2" showPrev>
            <PreviewImg labelText="프로필 사진" class="mb-14" />

            <InputBox labelText="강사명" :inputId="'instrNm'" :inputType="'text'" 
                :inputValue="instrInfo['instrNm']['value']" :helperText="instrInfo['instrNm']['text']" :class="instrInfo['instrNm']['class']"
                placeholder="강사 회사명." important class="mt-30" @input="getInputValSet"/>

            <InputBox labelText="주소" :inputId="'addr'" inputType="text"
                :inputValue="instrInfo['addr']['value']" :helperText="instrInfo['addr']['text']" :class="instrInfo['addr']['class']"
                placeholder="주소를 검색하세요." important readonly class="white" @click="getAddr"/>

            <InputBox labelText="매장 상세주소" :inputId="'addrDetail'" inputType="text" 
                :inputValue="instrInfo['addrDetail']['value']" :helperText="instrInfo['addrDetail']['text']" :class="instrInfo['addrDetail']['class']"
                placeholder="상세주소를 입력하세요." important @input="getInputValSet" />

            <InputBox labelText="대표 전화번호" :inputId="'tel'" inputType="text"
                :inputValue="instrInfo['tel']['value']" :helperText="instrInfo['tel']['text']" :class="instrInfo['tel']['class']"
                placeholder="대표 전화번호를 입력하세요." important @input="getInputValSet"/>

            <SelectBox :getOptions="''" labelId="" labelNm="" labelText="서비스 분야" important />
            <div>
                <label for="days">
                    매장운영일
                </label>
                <ul class="input-wrapper">
                    <li>
                        <input type="checkbox" id="mon" name="days" value="월" @change="getDay" :checked="getDayStat('월')"/>
                        <label for="mon">월</label>
                    </li>
                    <li>
                        <input type="checkbox" id="tue" name="days" value="화" @change="getDay" :checked="getDayStat('화')"/>
                        <label for="tue">화</label>
                    </li>
                    <li>
                        <input type="checkbox" id="wen" name="days" value="수" @change="getDay" :checked="getDayStat('수')"/>
                        <label for="wen">수</label>
                    </li>
                    <li>
                        <input type="checkbox" id="thur" name="days" value="목" @change="getDay" :checked="getDayStat('목')"/>
                        <label for="thur">목</label>
                    </li>
                    <li>
                        <input type="checkbox" id="fri" name="days" value="금" @change="getDay" :checked="getDayStat('금')"/>
                        <label for="fri">금</label>
                    </li>
                    <li>
                        <input type="checkbox" id="sat" name="days" value="토" @change="getDay" :checked="getDayStat('토')"/>
                        <label for="sat">토</label>
                    </li>
                    <li class="mr-0">
                        <input type="checkbox" id="sun" name="days" value="일" @change="getDay" :checked="getDayStat('일')"/>
                        <label for="sun">일</label>
                    </li>
                </ul>
            </div>
            <div>
                <label for="times">
                    강의 가능 시간
                </label>
                <vue-timepicker />
            </div>

            <TextareaBox labelText="강사 설명" inputId="" inputNm="" placeholder="강사 설명" />

            <div class="bottom">
                <BaseButton buttonText="동의하고 계속하기" class="grey" @click="getAgreeNext" />
            </div>
        </MobileBox>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import MobileBox from "@/components/common/MobileBox.vue";
import PreviewImg from "@/components/input/PreviewImg.vue";
import InputBox from "@/components/input/InputBox.vue";
import SelectBox from "@/components/input/SelectBox.vue";
import Confirm from "@/components/alert/Confirm.vue";

import axios from "axios";
import { mapState } from 'vuex';

import VueTimepicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'
import BaseButton from "@/components/button/BaseButton.vue";
import TextareaBox from "@/components/input/TextareaBox.vue";

export default {
    name: "JoinSecond",
    components: {TextareaBox, BaseButton, SelectBox, InputBox, PreviewImg, MobileBox, Confirm, VueTimepicker},
    computed: {
        ...mapState('join', {
            certified   : state => state.joinInfo.certified,
            instrInfo   : state => state.joinInfo.instrInfo
        })
    },
    data() {
        return {
            worktime : [
                { value : '09:00 ~ 18:00', name : '09:00 ~ 18:00' },
            ],
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            joinStat            : 'N'
        }
    },
    created() {
        this.getCertifiedCheck();
    },
    methods: {
        getCertifiedCheck() {
            if(this.certified === 'N')
            {
                console.log('back');
                // this.$router.go(-1);
            }
        },
        getConfirmResult() {
            this.confirmStat = false;

            if(this.confirmType === 'confirm')
            {
                this.getJoinSave();
            }

            /** 회원가입 완료 시 로그인 페이지 이동 */
            if(this.joinStat === 'Y')
            {
                this.$router.push({ path : `/login` });
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getFile(file) {
            this.$store.commit('join/setFile', { gb : 'instrInfo', file : file });
        },
        getAddr() {
            new window.daum.Postcode({
                oncomplete: (data) => {
                    this.instrInfo['zip']             = data.zonecode;
                    this.instrInfo['addr']['value']   = data.address;

                    const inputElement = document.getElementById('addrDetail');
                    inputElement.focus();
                }
            }).open();
        },
        getSelected(id){
            let data;

            if(id === 'service')
            {
                /** 서비스 분야 */
                data = this.indst;
            }

            return data[0];
        },
        getSelectSet(id, event) {
            this.instrInfo[id] = event;
        },
        getDay() {
            const value     = event.target.value;
            const checkStat = event.target.checked;

            if(checkStat)
            {
                this.instrInfo['day'].push(value);
            }
            else
            {
                const dayList   = this.instrInfo['day'];
                const removeDay = dayList.indexOf(value);

                if (removeDay !== -1) 
                {
                    dayList.splice(removeDay, 1);
                }
            }
        },
        getDayStat(day) {
            const daysOfWeek = this.instrInfo['day'];

            if (daysOfWeek.indexOf(day) !== -1) 
            {
                return true;
            }
            else 
            {
                return false;
            }
        },
        getInputValSet() {
            const id        = event.target.id;
            const value     = event.target.value;

            if(id === 'instrNm' || id === 'addr' || id === 'addrDetail' || id === 'tel')
            {
                this.instrInfo[id]['value'] = value;
            }
            else
            {
                this.instrInfo[id] = value;
            }
        },
        getInputCheck() {
            let stat = true;

            stat = stat && this.getValueCheck('instrNm');
            stat = stat && this.getValueCheck('addr');
            stat = stat && this.getValueCheck('addrDetail');
            stat = stat && this.getValueCheck('tel');
            stat = stat && this.getDayCheck();

            return stat;
        },
        getValueCheck(id){
            let text = '필수 정보가 입력되지 않았습니다.';

            if(this.instrInfo[id]['value'] === '')
            {
                this.getErrorText(false, text, id);
                this.getInputFocus(id);
                return false;
            }
            else
            {
                this.getErrorText(true, text, id);
                return true;
            }
        },
        getDayCheck(){
            this.getConfirmSet('alert', '선택 필요', '매장 운영일을 선택해주세요.');

            if(Number(this.instrInfo['day'].length) > 0)
            {
                return true;
            }
            else
            {
                return false;
            }
        },
        getErrorText(gb, text, id){
            if(!gb)
            {
                this.instrInfo[id]['text']  = text;
                this.instrInfo[id]['class'] = 'error';
            }
            else
            {
                this.instrInfo[id]['text']  = this.instrInfo[id]['class'] === 'error' ?  '' : this.instrInfo[id]['text'];
                this.instrInfo[id]['class'] = '';
            }
        },
        getInputFocus(id){
            const inputElement = document.getElementById(id);
            if (inputElement) 
            {
                inputElement.focus();
            }
        },
        getJoinResult() {
            const inputCheck = this.getInputCheck();

            if(!inputCheck)
            {
                return false;
            }

            this.getConfirmSet('confirm', '회원가입', '회원가입을 완료하시겠습니까?');
        },
        async getJoinSave() {
            const instance = axios.create({
                withCredentials : true,
                headers : {
                    'X-Subdomain'  : window.location.hostname
                }
            });

            const params = {
                type    : '',
                email   : '',
                pw      : '',
                tel     : '',
                name    : '',
                channel : ''
            };

            // console.log(JSON.stringify(params, null, 2));

            const res    = await instance.post('https://data.wecls.com/join/joinCheck', JSON.stringify(params));

            if(res.data['status'] === 200)
            {
                this.joinStat = 'Y';
                this.getConfirmSet('alert', '완료', '회원가입이 완료되었습니다.');
            }
            else
            {
                this.getConfirmSet('alert', '에러', '회원가입 중 에러가 발생했습니다. 지속될 경우 관리자에게 문의바랍니다.');
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.join{
    .mobile-content{
        div + div, div + button {
            margin-top: 24px;
        }

        .input-wrapper{
            width: 100%;
            position: relative;
            border-radius: 4px;
            background-color: $bk-color-lv-3;
            border: 1px solid $line-color-lv-3;
            padding: 26px;
            text-align: center;

            li{
                margin-right: 8.5px;
                display: inline-block;
                input{
                    display: none;

                    &:checked{
                        & + label{
                            background-color: $blue-color-lv-4;
                            color: $bk-color-lv-3;
                            border: 1px solid $blue-color-lv-4;
                        }
                    }
                }

                label{
                    padding: 12px 8px;
                    border-radius: 20px;
                    border: 1px solid $line-color-lv-1;
                    cursor: pointer;
                    font-size: $font12;
                    font-weight: 700;
                }
            }
        }
    }

    .bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 8px 16px 14px 16px;
        @include shadow2;
        width: 100%;
        background-color: $bk-color-lv-3;
        z-index: 2;
    }
}
</style>