<template>
    <div class="calender">
        <MypageHeader />
        <p class="section-border" />
        <div class="inner section">
            <div class="calender-wrapper margin">
                <FullCalendar :options="calendarOptions" />
                <!-- https://fullcalendar.io/docs/vue -->
            </div>
        </div>
    </div>
</template>

<script>
import MypageHeader from "@/views/user/mypage/MypageHeader.vue";
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import { getDateInfo } from "@/assets/js/user/date";

export default {
    name: "CalenderList",
    components: { MypageHeader, FullCalendar },
    created() {
        this.getStatCheck()
    },
    computed: {
        calendarOptions() {
            return {
                plugins     : [dayGridPlugin],
                initialView : 'dayGridMonth',
                events      : this.$store.state.date.dateInfo.info,
                locale      : 'kr'
            }
        },
    },
    methods : {
        getStatCheck() {
            getDateInfo();
        }
    }
}
</script>

<style lang="scss" scoped>
.calender {
    padding: 90px 0;

    .margin {
        margin-top: 46px;
    }
}

.calender{
    .fc{
        .fc-button-group {
            position: initial;
        }
        .fc-header-toolbar{
            position: relative;
            display: block;

            .fc-toolbar-chunk {
                text-align: center;

                .fc-toolbar-title{
                    width: 100%;
                    text-align: center;
                }

                .fc-today-button{
                    display: none;
                }

                .fc-button-group{
                    position: initial;

                    .fc-button{
                        border: none;
                        border-radius: 0;
                    }

                    .fc-button-primary{
                        background-color: white;
                        border: none;

                        &:focus{
                            box-shadow: none;
                        }

                        &.fc-prev-button{
                            position: absolute;
                            top: 0;
                            left: 35%;

                            .fc-icon-chevron-left::before{
                                content: "◀";
                                color: #FF5000;
                            }
                        }

                        &.fc-next-button{
                            position: absolute;
                            top: 0;
                            right: 35%;

                            .fc-icon-chevron-right::before{
                                content: "▶";
                                color: #FF5000;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>