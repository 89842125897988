import { getAxiosData } from "@/assets/js/function";
import store from '@/store';
import { useRouter } from "vue-router";

/**
 *  @description 기관(자사) 정보 
 */
export async function getProfileAdmInfo() 
{
    const router = useRouter();

    try 
    {
        const res = await getAxiosData('https://data.wecls.com/organ/mainList/getMyProfile');

        console.log(res.data);

        const check = res.data['ogCd'] ? true : false;
        const data  = store.state.data.data;

        const fileOther = [];

        if(check)
        {
            if(res.data['etcFile'] === null)
            {
                fileOther.push({
                    text : '',
                    file : null,
                    gb   : 'N'
                });
            }
            else
            {
                res.data['etcFile'].map((item) => {
                    fileOther.push({
                        fileNo  : item.fileNo,
                        text    : item.fileNm,
                        file    : item.filePath,
                        gb      : 'Y'
                    });
                })
            }
        }
        else
        {
            fileOther.push({
                text : '',
                file : null,
                gb   : 'N'
            });
        }

        const info = {
            ogCd        : check ? res.data['ogCd'] : '',
            oaCd        : res.data['oaCd'],
            img1        : { // 대표이미지
                text    : res.data['idPic'] ? res.data['idPic']['fileNm'] : '',
                file    : res.data['idPic'] ? res.data['idPic']['filePath'] : null,
                gb      : res.data['idPic'] ? 'Y' : 'N'
            },
            img2        : { // 전문성이미지1
                text    : res.data['expPic'] ? getFileCheck(res.data['expPic'], '', '2', 'fileNm') : '',
                file    : res.data['expPic'] ? getFileCheck(res.data['expPic'], null, '2', 'filePath') : null,
                gb      : res.data['expPic'] ? 'Y' : 'N'
            },
            img3        : { // 전문성이미지2
                text    : res.data['expPic'] ? getFileCheck(res.data['expPic'], '', '3', 'fileNm') : '',
                file    : res.data['expPic'] ? getFileCheck(res.data['expPic'], null, '3', 'filePath') : null,
                gb      : res.data['expPic'] ? 'Y' : 'N'
            },
            img4        : { // 전문성이미지3
                text    : res.data['expPic'] ? getFileCheck(res.data['expPic'], '', '4', 'fileNm') : '',
                file    : res.data['expPic'] ? getFileCheck(res.data['expPic'], null, '4', 'filePath') : null,
                gb      : res.data['expPic'] ? 'Y' : 'N'
            },
            img5        : { // 전문성이미지4
                text    : res.data['expPic'] ? getFileCheck(res.data['expPic'], '', '5', 'fileNm') : '',
                file    : res.data['expPic'] ? getFileCheck(res.data['expPic'], null, '5', 'filePath') : null,
                gb      : res.data['expPic'] ? 'Y' : 'N'
            },
            cntry       : check ? res.data['cntry'] : data['country'][0]['value'],
            ogNm        : check ? res.data['ogNm'] : '',
            zip         : check ? res.data['zip'] : '',
            addr        : check ? res.data['addr'] : '',
            addrDetail  : check ? res.data['addrDetail'] : '',
            charPerNm   : check ? res.data['charPerNm'] : '',
            charPerTel  : check ? res.data['charPerTel'] : '',
            tel         : res.data['tel'],
            fax         : check ? res.data['fax'] : '',
            memo        : check ? res.data['memo'] : '',
            bankCd      : check ? res.data['bankCd'] : data['bank'][0]['value'],
            bankNm      : check ? res.data['bankNm'] : '',
            bankNum     : check ? res.data['bankNum'] : '',
            einNum      : check ? res.data['einNum'] : '',
            ceoNm       : check ? res.data['ceoNm'] : '',
            einNm       : check ? res.data['einNm'] : '',
            einType     : check ? res.data['einType'] : '',
            einItem     : check ? res.data['einItem'] : '',
            einEmail    : check ? res.data['einEmail'] : '',
            img6        : { // 사업자이미지
                text    : res.data['einPic'] ? res.data['einPic']['fileNm'] : '',
                file    : res.data['einPic'] ? res.data['einPic']['filePath'] : null,
                gb      : res.data['einPic'] ? 'Y' : 'N'
            },
            fileOther   : fileOther,
            id          : check ? res.data['id'] : '',
            pw          : '',
            pwCheck     : '',
            delYn       : 'N',
            fileGb      : []
        };
      
        // Vuex store에 필요한 데이터만 저장
        store.commit('profileAdm/setInfo', info);
    } 
    catch (error) 
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'organ');
            router.push('/organLogin');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}

function getFileCheck(data, val, seq, nm)
{
    const check = data.find(item => item.fileNo === seq);

    if(check)
    {
        return check[nm];
    }
    else
    {
        return val;
    }
}