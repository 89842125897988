import { getAxiosData } from "@/assets/js/function";
import store from "@/store";
import { useRouter } from "vue-router";

/**
 *  @description 기관 유저 목록 조회
 */
export async function getUserFindAdmList(params) 
{
    const router = useRouter();

    try 
    {
        const res = await getAxiosData("https://data.wecls.com/inst/mainList/getList", params);
        
        const list = res["data"].map((item) => {
            return {
                itCd        : item.itCd,
                iaCd        : item.iaCd,
                name        : item.name,
                career      : item.career,
                appeal      : item.appeal,
                fieldNm     : item.fieldNm,
                repCareer   : item.repCareer.join(', '),
                school      : item.school,
                major       : item.major,
                fileCnt     : item.fileCnt,
                matchCnt    : item.matchCnt,
                rate        : item.rate
            };
        });

        store.commit("userFindAdm/setList", list);
    } 
    catch (error) 
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'organ');
            router.push('/organLogin');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}

/**
 *  @description 기관 유저 정보 조회
 */
export async function getUserFindAdmInfo(params) 
{
    const router = useRouter();
    
    try 
    {
        const res = await getAxiosData("https://data.wecls.com/inst/mainList/getInfo", params);

        console.log(res);

        const dayOrder = {
            '월': 1,
            '화': 2,
            '수': 3,
            '목': 4,
            '금': 5,
            '토': 6,
            '일': 7,
        };
        
        const dayList = res.data['prefDay'] !== "" ? res.data['prefDay'].sort((a, b) => dayOrder[a] - dayOrder[b]) : res.data['prefDay'];
        
        const info = {
            itCd         : res.data['itCd'],
            idPic        : res.data['idPic'][0],
            thumbIdPic   : res.data['thumbIdPic'][0],
            name         : res.data['name'],
            birthday     : res.data['birthday'],
            tel          : res.data['tel'],
            email        : res.data['email'],
            fieldNm      : res.data['fieldNm'],
            careerNm     : res.data['careerNm'],
            addr         : res.data['addr'],
            acadAbilNm   : res.data['acadAbilNm'],
            school       : res.data['school'],
            major        : res.data['major'],
            instMemo     : res.data['instMemo'],
            repCareer    : res.data['repCareer'],
            careerMemo   : res.data['careerMemo'],
            fileList     : res.data['fileList'],
            prefDay      : dayList !== "" ? dayList.join(', ') : "",
            desArea1     : res.data['desArea1'],
        }

        store.commit("userFindAdm/setInfo", info);
    } 
    catch (error) 
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'organ');
            router.push('/organLogin');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}