<template>
    <div>
        <h4 v-if="showTitle" class="sort-title">{{ title }}</h4>
        <div class="sort">
            <div class="sort-list" v-for="(sort, index) in sorts" :key="index">
                <h5>
                    {{ sort.title }}
                </h5>
                <p :class="sort.color" @click="getGoUrl(sort.url)">
                    {{ sort.count }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "SortListCard",
    props: {
        sorts    : Array,
        showTitle: Boolean,
        title    : String,
    },
    methods: {
        getGoUrl(dataUrl) {
            this.$router.push(dataUrl);
        }
    }
}
</script>

<style lang="scss" scoped>
.sort-title{
    font-size: $font18;
    font-weight: 900;
    padding-bottom: 16px;
    border-bottom: 1px solid $line-color-lv-4;
}

.sort {
    width: 100%;
    display: flex;
    text-align: center;
    border-bottom: 1px solid $line-color-lv-4;
    padding: 20px 0;

    &-list {
        width: 100%;
    }

    h5 {
        font-size: $font18;
        color: $text-color-lv-5;
        font-weight: 400;
        margin-bottom: 24px;
    }

    p {
        font-size: $font28;
        color: $text-color-lv-0;
        font-weight: 700;

        &.blue {
            color: $blue-color-lv-4;
        }
    }
}
</style>