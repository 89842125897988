<template>
    <div class="popup">
        <div class="popup-box">
            <h2>{{ title }}</h2>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "DefaultPopup",
    props: {
        title: String,
    }
}
</script>

<style lang="scss" scoped>
.popup{
    width: 100%;
    height: 100vh;
    z-index: 10;
    position: fixed;
    background-color: rgba(0,0,0,.6);
    display: flex;
    align-items: center;
    top: 0;
    left: 0;

    &-box{
        width: 1000px;
        padding: 42px 80px;
        background-color: $bk-color-lv-3;
        border-radius: 8px;
        box-shadow: 6px 16px 40px 0px rgba(0, 0, 0, .4);
        margin: 0 auto;

        h2{
            color: $brand-color-lv-1;
            text-align: center;
            font-size: $font28;
            font-weight: 700;
        }

        div{
            margin-top: 40px;
        }
    }
}
</style>