<template>
    <ul class="card" data-aos="fade-up">
        <li>
            <img src="../../assets/images/image_1.jpg" alt="" title=""/>
            <div>
                <span class="brand big">"</span><br>
                <h2>
                    <p>강의 1건당 관리시간</p>
                    평균 3일 절약
                </h2>
                강사로 등록한 후, 발로 뛰어 다니지 않아도 많은 강의가 매칭돼요. <span class="brand">강의에만 집중 하며 전문성을 키울 수 있어서</span>기뻐요.
                <p>홍*나 강사</p>
            </div>
        </li>
        <li>
            <img src="../../assets/images/image_2.jpg" alt="" title=""/>
            <div>
                <span class="brand big">"</span><br>
                <h2>
                    <p>강의 전·후로 체계적인 관리</p>
                    위클즈 소속강사
                </h2>
                평가를 통해 위클즈 소속강사로 인증받았더니, <span class="brand">체계적인 관리로 강의의 질이 높아짐과 더불어 더 많은 기회</span>들을 얻게 되었습니다.
                <p>김*은 강사</p>
            </div>
        </li>
        <li>
            <img src="../../assets/images/image_3.jpg" alt="" title=""/>
            <div>
                <span class="brand big">"</span><br>
                <h2>
                    <p>낮은 진입장벽</p>
                    다양한 공고 열람
                </h2>
                위클즈 플랫폼 덕분에<span class="brand"> 경력을 쌓을 수 있는 발판</span>이 생겼어요. 다양한 사용자들과 교류하며 더욱 전문성 있는 강사로 성장할 수 있었습니다.
                <p>박*진 강사</p>
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    name: "ImageCard"
};
</script>

<style lang="scss" scoped>
.card {
    width: 100%;
    display: flex;

    li {
        width: 100%;
        height: 630px;
        background-color: $bk-color-lv-3;
        border-radius: 6px;
        @include shadow8;
        overflow: hidden;
        text-align: justify;
        transition: all .3s ease;
        position: relative;

        &:hover{
            transition: all .3s ease;
            img{
                filter: brightness(100%);
            }
        }

        &:nth-child(2) {
            margin: 0 20px;
        }

        img{
            filter: brightness(50%);
            transition: all .3s ease;
            width: 100%;
            height: 265px;
        }

        & > div{
            padding: 16px 20px 50px 20px;
            font-size: $font20;
            font-weight: 500;

            .big{
                font-size: $font72;
                line-height: $font72;
                display: block;
                height: 10px;
            }

            & > p{
                font-size: $font16;
                color: $text-color-lv-3;
                font-weight: 400;
                position: absolute;
                left: 20px;
                bottom: 30px;
            }

            h2{
                font-size: $font30;
                font-weight: 700;
                transition: all .3s ease;
                margin-bottom: 20px;

                p{
                    font-size: $font20;
                    font-weight: 400;
                    color: $text-color-lv-5;

                    span{
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
</style>