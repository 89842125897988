<script>
import { getAxiosData } from "@/assets/js/function";

export default {
    name: 'AdmLogout',
    async mounted() {
        try 
        {
            const res = await getAxiosData("https://data.wecls.com/login/logout");
            if (res.status === 200) 
            {
                this.$store.dispatch('login/logout', 'organ');
                this.$router.push('/');
            }
        } 
        catch (error) 
        {
            console.error('로그아웃 실패:', error);
        }
    }
};
</script>