import { getDate, getConvertDate } from "@/assets/js/function";

const date = getDate('mon');

/**
 * @description  기관 리뷰관리 vuex 모듈 관련
 * @author 정해원, @date 2023-12-04 / (수정) @author 김원명, @date 2023-12-19
 */
const gradeMngModule = {
    namespaced  : true,
    state       : {
        gradeMng : {
            search  : {
                type    : { name : '이달', value : 'mon' },
                startDt : getConvertDate(date['st'], 'yyyy-mm-dd'),
                endDt   : getConvertDate(date['et'], 'yyyy-mm-dd')
            },
            info     : [
                { title : '전체 리뷰수', count : 0 },
                { title : '평균 평점', count : 0 },
                { title : '최고 평점', count : 0 },
                { title : '최소 평점', count : 0 },
                { title : '미평가', count : 0 }
            ],
            cnt     : [
                { title : '대기중인 평가', count : 0 },
                { title : '평가 완료', count : 0 },
                { title : '전체', count : 0 }
            ],
            allList : [],
            list    : [],
            eval    : getEvalInfo()
        }
    },
    mutations : {
        setInfo(state, newInfo)
        {
            state.gradeMng.info = newInfo
        },
        setCnt(state, newCnt)
        {
            state.gradeMng.cnt = newCnt
        },
        setAllList(state, newAllList)
        {
            state.gradeMng.allList = newAllList;
            state.gradeMng.list    = newAllList;
        },
        setList(state, newList)
        {
            state.gradeMng.list = newList;
        },
        setEvalReset(state)
        {
            state.gradeMng.eval = getEvalInfo();
        }
    }
};

function getEvalInfo()
{
    return {
        score : '',
        memo  : ''
    }
}
  
export default gradeMngModule;