/**
 * @description 기관 유저 목록  vuex 모듈 관련
 * @author 정해원, @date 2023-11-29
 */

const userFindAdmModule = {
    namespaced: true,
    state: {
        userFindAdm: {
            search : {
                area    : { name : '전체', value : ''},
                career  : { name : '전체', value : ''},
                lecture : { name : '전체', value : ''}
            },
            list   : [],
            info   : getUserFindAdmInfo(),
            lect   : []
        }
    }, 
    mutations: {
        setList(state, newList){
            state.userFindAdm.list = newList;
        },
        setInfo(state, newInfo) {
            state.userFindAdm.info = newInfo;
        },
        setLect(state, newLect) {
            state.userFindAdm.lect = newLect;
        }
    }
};

function getUserFindAdmInfo() { 
    return {
        idPic        : null,
        thumbIdPic   : null,
        name         : '',
        birthday     : '',
        tel          : '',
        email        : '',
        fieldNm      : '',
        careerNm     : '',
        addr         : '',
        acadAbilNm   : '',
        school       : '',
        major        : '',
        instMemo     : '',
        repCareer    : [],
        careerMemo   : '',
        fileList     : [],
        prefDay      : '',
        desArea1     : '',
    };
}



export default userFindAdmModule;
