<template>
    <div class="input date" :class="inputClass">
        <label :for="inputId">{{ labelText }}</label>
        <div class="input-wrapper">
            <VueDatePicker v-model="selectedDate" :format="timePicker ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'" :locale="'ko'" :show-buttons="false" :auto-apply="true"
                :show-timepicker="timePicker" :clearable="false" :min-date="disableDate ? minDate : false" @update:model-value="updateDate"/>
        </div>
        <p :class="{ 'helper': helperVisible }">
            <span>!</span> {{ helperText }}
        </p>
    </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import '@vuepic/vue-datepicker/dist/main.css';

import { ref, watch } from 'vue';

export default {
    name: "DateBox",
    components: { VueDatePicker },
    props: {
        style           : String,
        value           : String,
        format          : {
            type        : String,
            default     : 'yyyy-MM-dd'
        },
        locale          : {
            type        : String,
            default     : 'ko'
        },
        state           : String,
        inputId         : String,
        inputValue      : String,
        emptyType       : String,
        labelText       : String,
        important       : Boolean,
        useTooltip      : Boolean,
        tooltipContent  : String,
        helperText      : String,
        selectFunction  : Function,
        timePicker      : Boolean,
        disableDate     : Boolean
    },
    data() {
        return {
            helperVisible: false,
            selecteDt: this.inputValue,
            minDate : null
        }
    },
    created() {
        var today       = new Date();
        var yesterday   = new Date(today);
        
        yesterday.setDate(today.getDate() - 1);
        this.minDate    = yesterday;
    },
    setup(props) {
        const selectedDate  = ref(props.inputValue === '' ? (props.emptyType === 'Y' ? '' : new Date()) : props.inputValue);

        const updateDate = (value) => {
            selectedDate.value = value;

            const dateObj = new Date(value);

            const year  = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const day   = String(dateObj.getDate()).padStart(2, '0');
            const hour  = String(dateObj.getHours()).padStart(2, '0'); // 시 수정
            const min   = String(dateObj.getMinutes()).padStart(2, '0'); // 분 수정

            let date;

            if(props.timePicker)
            {
                date  = `${year}-${month}-${day} ${hour}:${min}`;
            }
            else
            {
                date  = `${year}-${month}-${day}`;
            }

            props.selectFunction(date, props.inputId);
        };

        watch(() => props.inputValue, (newValue) => {
            selectedDate.value = newValue;
        });

        return {
            selectedDate,
            updateDate, 
        };
    },
    computed: {
        inputClass() {
            return {
                'error-state': this.state === 'error',
                'disabled-state': this.state === 'disabled',
                'readonly-state': this.state === 'readOnly',
                'small': this.style === 'small',
                'regular': this.style === 'regular',
                'medium': this.style === 'medium',
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.date {
    display: block;
    position: relative;
    width: 100%;

    &.small {
        width: 140px;
    }

    &.regular {
        width: 200px;
    }

    &.rmdeium {
        width: 240px;
    }

    &.medium {
        width: 460px;
    }

    label {
        position: absolute;
        font-size: $font11;
        font-weight: 400;
        color: $text-color-lv-3;
        z-index: 1;
        background-color: $bk-color-lv-3;
        left: 14px;
        top: -8px;

        span {
            color: $red-color-lv-2;
        }
    }

    .input-wrapper {
        position: relative;
        width: 100%;

        .dp__input_icon {
            @include my-auto;
            right: 10px;
            width: 20px;
            height: 20px;
        }

        input {
            border-radius: 4px;
            width: 100%;
            height: 38px;
            font-size: $font14;
            font-weight: 400;
            background-color: $bk-color-lv-3;
            border: 1px solid $line-color-lv-2;

            &:read-only {
                background-color: $table-color-lv-1;
            }
        }
    }

    p {
        display: none;

        span {
            display: none;
        }

        &.helper {
            color: $text-color-lv-5;
            font-size: $font12;
            font-weight: 400;
            display: block;
        }
    }

    &.error {
        input {
            border: 1px solid $red-color-lv-2;
        }

        p {
            span {
                display: inline;
            }

            &.helper {
                color: $red-color-lv-2;
            }
        }
    }
}
</style>