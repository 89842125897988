<template>
    <span class="tag" :class="tagClass">
        {{ content }}
    </span>
</template>

<script>
export default {
    name: "TagSpan",
    props: {
        content: String,
        style: String,
    },
    computed: {
        tagClass() {
            return{
                'gray'  : this.style === 'gray',
                'green' : this.style === 'green',
                'purple' : this.style === 'purple',
                'blue' : this.style === 'blue',
                'red' : this.style === 'red',
                'yellow' : this.style === 'yellow',
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.tag{
    border-radius: 40px;
    padding: 8px 14px;
    font-size: $font12;

    &.gray{
        border: 1px solid #333333;
        color: #333333;
    }

    &.blue{
        border: 1px solid #6BB8FF;
        color: #6BB8FF;
    }

    &.red{
        border: 1px solid #FF5000;
        color: #FF5000;
    }
}

span + span {
    margin-right: 8px;
}
</style>