/**
 * @description 강의 상세 정보 vuex 모듈 관련
 * @author 정해원, @date 2023-11-27
 */
const detailModule = {
    namespaced  : true,
    state       : {
            info : getDetailInfo()   /* 강의 상세 정보 */
    },
    mutations   : {
        setDetailInfo(state, detailInfo) {
            state.info = detailInfo;
          },
    },
   
};

function getDetailInfo()
{
    return {
      lcCd          : '',
      fileNm        : '',
      subject       : '',
      regDt         : '',
      fieldCd       : '',
      lectPeriod    : '', 
      lectTime      : '',
      totalTime     : '',
      amt           : '',
      estAmt        : '',
      area1         : '',
      area2         : '',
      target        : '',
      addr          : '',
      addrDetail    : '',
      lectEq        : '',
      regPeriod     : '',
      lectMemo      : '',
      reqDoc        : '',
      etcDoc        : '',
      ogNm          : '',
      ogAddr        : '',
      chEmail       : '',
      chPerTel      : '',
      chPerNm       : '',
      FileList      : {
        fileNm      : '',
        fileGb      : '',
        filePath    : '',
        fileNo      : ''
      },
      likeCheck     : "N"
    }
}
  
export default detailModule;