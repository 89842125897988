<template>
    <header class="header">
        <div class="header-wrapper">
            <h1 class="logo">
                <router-link to="/">
                    <img src="@/assets/images/logo.png" alt="로고" title="위클즈 로고" />
                </router-link>
            </h1>
            <ul>
                <li>
                    <router-link to="/brandstory" :class="{ 'active': $route.path === '/brandstory' }">
                        브랜드스토리
                    </router-link>
                </li>
                <li>
                    <router-link to="/wecls" :class="{ 'active': $route.path === '/wecls' }">
                        위클즈 소개
                    </router-link>
                </li>
                <li>
                    <router-link to="/privacy" :class="{ 'active': $route.path === '/privacy' }">
                        개인정보처리방침
                    </router-link>
                </li>
            </ul>
            <div>
                <router-link to="" class="blue3 flex" @click="getMove">
                    톡 문의하기
                    <img src="@/assets/images/icon-talk.svg" alt="contact" title="contact" />
                </router-link>
                <router-link to="/organLogin" class="blue2">기관 로그인</router-link>
                <router-link to="/login" class="blue">강사 로그인</router-link>
            </div>
        </div>
    </header>
</template>

<script setup>
    const getMove = () => {
        window.open('https://channel.io/ko/guides/installation/plug-in', '_blank');
    }
</script>

<style lang="scss" scoped>
.header {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    border-bottom: 1px solid $line-color-lv-4;
    background-color: $bk-color-lv-3;
    z-index: 9;

    &-wrapper {
        width: 1420px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            font-size: $font24;
            margin-right: 104px;
            cursor: pointer;

            a{
                display: flex;
                align-items: center;

                img{
                    width: 70px;
                }
            }
        }

        ul {
            width: 100%;
            height: 77px;
            display: flex;

            li {
                font-size: $font18;
                font-weight: 400;
                line-height: 80px;
                height: 100%;

                &:not(:last-child) {
                    margin-right: 50px;
                }

                a {
                    display: block;
                    position: relative;

                    &::after {
                        display: block;
                        content: '';
                        width: 0;
                        height: 6px;
                        background-color: $brand-color-lv-1;
                        left: 0;
                        bottom: 0;
                        position: absolute;
                        transition: width .2s ease;
                    }

                    &:hover, &.active {
                        &::after {
                            width: 100%;
                        }
                    }
                }
            }
        }

        div {
            width: 1500px;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;

            button, a {
                padding: 15px 25px 13px 25px;
                border-radius: 4px;
                border: 1px solid $bk-color-lv-3;
                color: $bk-color-lv-3;
                font-size: $font16;
                font-weight: 700;
                display: block;

                &.white {
                    color: $text-color-lv-1;
                    border: 1px solid $line-color-lv-2;
                }

                &.blue {
                    background-color: $brand-color-lv-1;
                }

                &.blue2 {
                    background-color: $brand-color-lv-2;
                }

                &.blue3 {
                    background-color: #F8F7F5;
                    border: 1px solid #BDB4B1;
                    color: black;
                    display: flex;
                    align-items: center;

                    img{
                        width: 20px;
                        margin-left: 5px;
                    }
                }
            }

            a {
                margin-left: 14px;
            }
        }
    }
}
</style>