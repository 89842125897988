<template>
    <div class="payment">
        <div class="section inner">
            <h2 class="notice-title">
                정산신청
            </h2>
            <LabelTable title="상품정보" class="mt-50 no-shadow">
                <tr v-for="(item, index) in info['lcInfo']" :key="index">
                    <th>{{ index + 1 }}</th>
                    <td>
                        <img :src="`${downloadPath}/${item['filePath']}`"/>
                        <p>{{ item['subject'] }}</p>
                    </td>
                </tr>
            </LabelTable>

            <LabelTable title="입금 정보" class="no-shadow">
                <tr>
                    <th>정산방법</th>
                    <td>
                        <div>
                            <input type="radio" id="calcGbN" name="calcGb" :checked="info['calcGb'] === 'N' ? true : false" @click="getCheckbox('calcGb', 'N')"/>
                            <label for="calcGbN">일반정산</label>
<!--                            <input type="radio" id="calcGbD" name="calcGb" :checked="info['calcGb'] === 'D' ? true : false" @click="getCheckbox('calcGb', 'D')"/>
                            <label for="calcGbD">데이페이</label>-->
                        </div>
                    </td>
                </tr>

                <tr>
                    <th>상품총액</th>
                    <td><b>{{ getCommas(info['totalAmt']) }}원</b></td>
                </tr>

                <tr>
                    <th>매칭 수수료</th>
                    <td>{{ getCommas(info['chargeAmt']) }}원 ({{ info['charge'] }}%)</td>
                </tr>

                <tr>
                    <th>정산금액</th>
                    <td><b>{{ getCommas(info['calcAmt']) }}원</b></td>
                </tr>

                <tr>
                    <th>정산방법</th>
                    <td>
                        <div>
                            <input type="radio" id="calcMean" name="calcMean" :checked="true"/>
                            <label for="calcMean">계좌이체</label>
                        </div>
                        <div>
                            <label class="w100">입금 은행명</label>
                            <input type="text" id="bankNm" :value="info['bankNm']" placeholder="입금하실 은행명" @input="getInputValue"/>
                        </div>
                        <div>
                            <label class="w100">입금자명</label>
                            <input type="text" id="bankAcc" :value="info['bankAcc']" placeholder="입금하실 예금주명 입력" @input="getInputValue"/>
                        </div>
                        <div>
                            <label class="w100">입금 계좌번호</label>
                            <input type="text" id="bankNum" :value="info['bankNum']" placeholder="숫자만 입력해주세요." @input="getInputValue"/>
                        </div>
                        <div>
                            <ul>
                                <li>정산 신청 이후 영업일 2일 이내에 정산이 완료됩니다.</li>
                                <li>입금 받으실 계좌가 정확하지 않으면 정산이 어려울 수 있으니 정확히 기재해주세요.</li>
                                <li>통장사본을 첨부해주시면 더욱 빠른 확인이 가능합니다.</li>
                            </ul>
                        </div>
                    </td>
                </tr>
            </LabelTable>

            <LabelTable title="서류신청" class="no-shadow">
                <tr>
                    <th>서류 발행 정보</th>
                    <td>
                        <div>
                            <input type="radio" id="docEnumA" name="docEnum" :checked="info['docEnum'] === 'T' ? true : false" @click="getCheckbox('docEnum', 'T')"/>
                            <label for="docEnumA">세금계산서</label>
                            <input type="radio" id="docEnumB" name="docEnum" :checked="info['docEnum'] === 'C' ? true : false" @click="getCheckbox('docEnum', 'C')"/>
                            <label for="docEnumB">사업소득</label>
                        </div>
                        <template v-if="info['docEnum'] === 'T'">
                            <div>
                                <a href="https://data.wecls.com/file/business_wecls.pdf" download class="orange">(주) 위클즈 사업자등록증.pdf (다운)</a>
                                <p>서류 발급 받으실 방법을 선택하시고, 하단의 내용을 입력해주세요.</p>
                            </div>
                            <div>
                                <ul>
                                    <li>계산서는 역발행으로 진행되며, 금액 확인하신 후 승인하시면 입금처리 됩니다.</li>
                                    <li>서류 전달이 원활할 수 있도록 이메일을 정확하게 입력해주세요.</li>
                                </ul>
                            </div>
                            <div>
                                <label class="w100">사업자명</label>
                                <input type="text" id="einNm" :value="info['einNm']" placeholder="사업자명" @input="getInputValue"/>
                            </div>
                            <div>
                                <label class="w100">사업자등록번호</label>
                                <input type="text" id="einNum" :value="info['einNum']" placeholder="사업자등록번호" @input="getInputValue"/>
                            </div>
                            <div>
                                <label class="w100">업태</label>
                                <input type="text" id="einType" :value="info['einType']" placeholder="업태" @input="getInputValue"/>
                            </div>
                            <div>
                                <label class="w100">종목</label>
                                <input type="text" id="einItem" :value="info['einItem']" placeholder="종목" @input="getInputValue"/>
                            </div>

                            <div>
                                <label class="w100">대표자명</label>
                                <input type="text" id="ceoNm" :value="info['ceoNm']" placeholder="대표자명" @input="getInputValue"/>
                            </div>
                            <div>
                                <label class="w100">주소</label>
                                <input type="text" id="addr" :value="info['addr']" placeholder="주소" @input="getInputValue"/>
                            </div>
                            <!-- <div>
                                <label class="w100" for="">상세주소</label>
                                <input type="text" id="" name="" placeholder="주소" />
                            </div> -->
                            <div>
                                <label class="w100">이메일주소</label>
                                <input type="text" id="email" :value="info['email']" placeholder="이메일주소" @input="getInputValue"/>
                            </div>
                        </template>
                        <template  v-if="info['docEnum'] === 'C'">
                            <div>
                                <ul>
                                    <li>사업소득세는 원천징수영수증 발급이 가능하며, 발급받을 이메일 주소를 기재해야 합니다.</li>
                                    <li>위클즈는 강사 개인의 종합소득세 신고는 대신하지 않으니, 발생 소득에 대한 신고는 개인적으로 진행하셔야 합니다.</li>
                                </ul>
                            </div>
                            <div>
                                <label class="w100">이메일주소</label>
                                <input type="text" id="email" :value="info['email']" placeholder="이메일주소" @input="getInputValue"/>
                            </div>
                        </template>
                    </td>
                </tr>
            </LabelTable>

            <BaseButton v-if="!info['adminYn']" buttonText="정산신청" class="w240" @click="getResultSet"/>
        </div>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import LabelTable from "@/components/table/LabelTable.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from "vuex";
import { getCalcInfo } from "@/assets/js/user/calc";
import { getAxiosData, getCommas } from "@/assets/js/function";

export default {
    name: "PaymentSet",
    components: {BaseButton, LabelTable, Confirm},
    data() {
        return {
            resultYn            : 'N',
            compYn              : 'N',
            mtCd                : this.$route.params.mtCd,
            downloadPath        : 'https://elasticbeanstalk-ap-northeast-2-627549176645.s3.ap-northeast-2.amazonaws.com',
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : ''
        }
    },
    created() {
        this.getInfo();
    },
    computed: {
        ...mapState('calc', {
            info  : state => state.calc.info
        })
    },
    methods : {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.compYn === 'N')
            {
                if(this.resultYn === 'Y')
                {
                    this.getResult();
                }
            }

            if(this.compYn === 'Y')
            {
                this.compYn = 'N';
                this.$router.go(-1);
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getInfo() {
            const params = {
                mtCd : this.mtCd
            };

            getCalcInfo(params);
        },
        getCommas(value) {
            return getCommas(Number(value));
        },
        getCheckbox(id, value) {
            this.info[id] = value;
        },
        getInputValue() {
            const id    = event.target.id;
            const value = event.target.value;

            this.info[id] = value;
        },
        getInputCheck() {
            if(this.info['bankNm'] === '')
            {
                this.getConfirmSet('alert', '확인', '입금 은행명을 입력하세요.');
                return false;
            }

            if(this.info['bankAcc'] === '')
            {
                this.getConfirmSet('alert', '확인', '입금자명을 입력하세요.');
                return false;
            }

            if(this.info['bankNum'] === '')
            {
                this.getConfirmSet('alert', '확인', '입금 계좌번호를 입력하세요.');
                return false;
            }

            if(this.info['docEnum'] === 'T')
            {
                if(this.info['einNm'] === '')
                {
                    this.getConfirmSet('alert', '확인', '사업자명을 입력하세요.');
                    return false;
                }

                if(this.info['einNum'] === '')
                {
                    this.getConfirmSet('alert', '확인', '사업자등록번호를 입력하세요.');
                    return false;
                }   
            }

            return true;
        },
        getResultSet() {
            if(!this.getInputCheck())
            {
                return false;
            }

            this.getConfirmSet('confirm', '신청', '정산신청을 요청하시겠습니까? 신청 후 수정 불가능합니다.');
            this.resultYn = 'Y';
        },
        async getResult() {
            try
            {
                const params = {
                    mtCd        : this.mtCd,
                    calcGb      : this.info['calcGb'],
                    price       : this.info['totalAmt'],
                    matchFee    : this.info['charge'],
                    calcPrice   : this.info['calcAmt'],
                    calcMean    : 'A',
                    bankNm      : this.info['bankNm'],
                    bankAcc     : this.info['bankAcc'],
                    bankNum     : this.info['bankNum'],
                    docEnum     : this.info['docEnum'],
                    einNm       : this.info['einNm'],
                    einNum      : this.info['einNum'],
                    einType     : this.info['einType'],
                    einItem     : this.info['einItem'],
                    ceoNm       : this.info['ceoNm'],
                    addr        : this.info['addr'],
                    email       : this.info['email']
                };

                console.log(params);

                const res = await getAxiosData('https://data.wecls.com/inst/dashboard/calcProc', params);

                console.log(res);
                if(res['status'] === 200)
                {
                    this.compYn = 'Y';
                    this.getConfirmSet('alert', '완료', '신청되었습니다.');
                }
            }
            catch(error)
            {
                console.error(error);
                if(error.response.data.status === 401)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'inst');
                    this.$router.push('/login');
                }
                else
                {
                    this.getConfirmSet('alert', '에러', '에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.payment {
    padding: 90px 0;

    .margin {
        margin-top: 46px;
    }

    .notice-title {
        font-size: $font28;
        font-weight: 700;
        margin-bottom: 46px;
    }

    table{
        tr{
            td{
                img{
                    width: 78px;
                    height: 87px;
                    border-radius: 6px;
                    margin: 9px 20px 9px 16px;
                    vertical-align: middle;
                    display: inline-block;
                }
                p{
                    display: inline-block;
                    vertical-align: middle;
                }

                label{
                    margin-right: 12px;
                    &.w100{
                        width: 100px;
                        margin-right: 10px;
                    }
                }

                input[type="radio"], input[type="checkbox"]{
                    margin: 0 8px;
                }

                input[type="text"]{
                    border-radius: 4px;
                    border: 1px solid $line-color-lv-2;
                    width: 200px;
                    height: 40px;
                    padding: 10px;
                }

                div + div {
                    margin-top: 10px;
                }

                ul{
                    li{
                        color: $text-color-lv-5;
                        list-style: disc;
                        margin-left: 25px;
                    }
                }
            }
        }
    }

    button.w240{
        margin: 40px auto 0 auto;
        padding: 12px;
        width: 240px;
        text-align: center;
        display: block;
        font-size: $font20;
    }
}
</style>