<template>
    <header class="header">
        <div class="inner">
            <h1 class="logo">
                <router-link to="/admin/main">
                    <img src="@/assets/images/logo2.png" alt="위클즈" title="위클즈" />
                </router-link>
            </h1>
        </div>
    </header>
</template>

<script>
export default {
    name: "MainHeader"
}
</script>

<style lang="scss" scoped>
.header {
    padding: 15px 0;
    background-color: $bk-color-lv-3;
    position: fixed;
    left: 240px;
    top: 0;
    width: calc(100% - 240px);
    z-index: 9;

    .logo {
        display: inline-block;
        font-size: $font24;
        font-weight: 700;
        vertical-align: middle;

        a{
            display: flex;
            align-items: center;

            img{
                width: 120px;
            }
        }
    }
}
</style>