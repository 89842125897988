<template>
    <ReviewTemplate :reviews="reviews" />
</template>

<script>
import ReviewTemplate from "@/views/static/ReviewTemplate.vue";

export default {
    name: "ReviewCard",
    components: {ReviewTemplate},
    data() {
        return {
            reviews: [
                {
                    title      : "✂️ 업무 부담이 줄었어요!",
                    description: "조건에 맞는 강사 구하는 것이 너무 어려웠는데, 이제는 강사 구하는 걱정이 없어요. 업무 부담이 줄어서 너무 좋습니다.",
                    location   : "부산 B 사회 복지관",
                    person     : "담당자님"
                },
                {
                    title      : "🗓️ 한눈에 볼 수 있는 스케줄",
                    description: "위클즈 내 캘린더를 활용해 전반적인 일정을 함께 관리할 수 있어서 더 좋습니다. 비서가 생긴 기분이에요.",
                    location   : "미술·캘리그라피",
                    person     : "이*미 강사님"
                },
                {
                    title      : "🔍 용이한 인재검색",
                    description: "다양한 강사님들을 찾아볼 수 있고, 컨텍할 수 있다는 점이 매력적입니다. 더 전문적이고 다양한 프로그램을 진행할 수 있을 것 같아요.",
                    location   : "마산 B 행정복지센터",
                    person     : "담당자님"
                },
                {
                    title      : "📚 사수 같은 위클즈!",
                    description: "강사 일을 어떻게 시작해야할지 막막했었는데, 위클즈 덕분에 다양한 기회를 얻을 수 있었습니다.",
                    location   : "요리·키즈쿠킹",
                    person     : "최*혜 강사님"
                },
                {
                    title      : "📢 다양한 기관 공고!",
                    description: "위클즈 덕분에 공고를 한 눈에 볼 수 있어 탐색하는 시간을 줄었습니다. 남은 시간은 강의 준비에 더 집중해서 열심히 진행해보려구요.",
                    location   : "생활취미·꽃꽂이",
                    person     : "조*진 강사님"
                },
                {
                    title      : "👥 소통으로 궁금증 해결!",
                    description: "커뮤니티 기능 덕분에 다양한 강사님, 담당자 분들과 소통할 수 있어서 좋아요. 많은 정보를 얻을 수 있었습니다.",
                    location   : "공예·라탄공예",
                    person     : "이*정 강사님"
                },
            ]
        }
    }
}
</script>

<style scoped>

</style>