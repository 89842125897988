<template>
    <span class="tag" :class="tagClass">
        {{ content }}
    </span>
</template>

<script>
export default {
    name: "TagSpan",
    props: {
        content: String,
        style: String,
    },
    computed: {
        tagClass() {
            return{
                'gray'  : this.style === 'gray',
                'green' : this.style === 'green',
                'purple' : this.style === 'purple',
                'blue' : this.style === 'blue',
                'red' : this.style === 'red',
                'yellow' : this.style === 'yellow',
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.tag{
    border-radius: 40px;
    padding: 8px 14px;
    font-size: $font12;
    color: $text-color-lv-2;

    &.gray{
        background-color: #F0F0F0;
    }

    &.green{
        background-color: #F2FBF5;
    }

    &.purple{
        background-color: #F3F2FB;
    }

    &.blue{
        background-color: #F3F9FE;
    }

    &.red{
        background-color: #FFDFE1;
    }

    &.yellow{
        background-color: #F6F8EE;
    }
}

span + span {
    margin-right: 8px;
}
</style>