<template>
    <DefaultPopup title="매칭 신청하시겠습니까?">
        <div class="main-detail">
            <dl>
                <dt>강의명</dt>
                <dd>{{ info['subject'] }}</dd>
            </dl>
            <dl>
                <dt>기관명</dt>
                <dd>{{ info['ogNm'] }}</dd>
            </dl>
            <dl>
                <dt>위치</dt>
                <dd>{{ info['ogAddr'] }}</dd>
            </dl>
            <dl>
                <dt>대상인원</dt>
                <dd>{{ info['target'] }} / {{ info['cnt'] }}명</dd>
            </dl>
            <dl>
                <dt>모집상세</dt>
                <dd v-html="info['lectMemo']"></dd>
            </dl>
        </div>

        <div class="main-input flex">
            <ul>
                <li>
                    <CheckInlineBox labelText="전체동의" inputId="all" inputNm="all" v-model:checked="allChecked" />
                </li>
                <li>
                    <CheckInlineBox labelText="공고문 내용을 모두 숙지하였습니다." inputId="agree1" inputNm="agree1"
                        v-model:checked="agree1" />
                </li>
                <li>
                    <CheckInlineBox labelText="수업 진행을 위한 결격사유에 해당되지 않습니다." inputId="agree2" inputNm="agree2"
                        v-model:checked="agree2" />
                </li>
                <li>
                    <CheckInlineBox labelText="이후 결격사유 등이 발견될 경우 생기는 불이익 등에 책임지겠습니다." inputId="agree3" inputNm="agree3"
                        v-model:checked="agree3" />
                </li>
            </ul>

            <div class="button-wrapper">
                <BaseButton buttonText="매칭 신청" class="orange"  @click="getResultSet" />
                <BaseButton buttonText="이력서 관리" class="deep" @click="link" />
                <BaseButton buttonText="취소" class="grey" @click="closePopup" />
            </div>
        </div>
    </DefaultPopup>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import DefaultPopup from "@/components/popup/DefaultPopup.vue";
import CheckInlineBox from "@/components/input/CheckInlineBox.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from "vuex";
import { getAxiosData } from "@/assets/js/function";

export default {
    name: "MainDetailPopup",
    components: { BaseButton, CheckInlineBox, DefaultPopup, Confirm },
    data() {
        return {
            allChecked          : false,
            agree1              : false,
            agree2              : false,
            agree3              : false,
            resultYn            : 'N',
            compYn              : 'N',
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
        }
    },
    watch: {
        allChecked(newValue) {
            this.agree1 = newValue;
            this.agree2 = newValue;
            this.agree3 = newValue;
        },
        agree1() {
            this.updateAllChecked();
        },
        agree2() {
            this.updateAllChecked();
        },
        agree3() {
            this.updateAllChecked();
        },
    },
    computed: {
        ...mapState('main', {
            info: state => state.main.info
        }),
        isAllChecked(){
            return this.agree1 && this.agree2 && this.agree3;
        }
    },
    methods: {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.resultYn === 'Y')
            {
                this.getResult();
            }

            if(this.compYn === 'Y')
            {
                this.compYn = 'N';
                history.back();
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        closePopup() {
            this.$emit("close-popup");
        },
        updateAllChecked() {
            this.allChecked = this.agree1 && this.agree2 && this.agree3;
        },
        link(){
            this.$router.push('/user/mypage/profileMng');
        },
        getResultSet() {
            if (!this.isAllChecked) 
            {
                this.getConfirmSet('alert', '확인', '모든 항목을 동의해야 합니다.');
                return false;
            }

            this.getConfirmSet('confirm', '매칭', '매칭하시겠습니까?');
            this.resultYn = 'Y';
        },
        async getResult() {
            this.resultYn = 'N';

            try
            {
                const params = {
                    lcCd  : this.info['lcCd']
                };

                const res = await getAxiosData('https://data.wecls.com/organ/mainList/volState', params);

                if(res['status'] === 200)
                {
                    this.compYn = 'Y';
                    this.getConfirmSet('alert', '완료', '신청이 완료되었습니다.');
                }
            }
            catch(error)
            {
                console.log(error);
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'inst');
                    this.$router.push('/login');
                }
                else if(error.response.data.status === 400)
                {
                    this.getConfirmSet('alert', '확인', error.response.data.data);
                }
                else
                {
                    alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.main-detail {
    width: 100%;
    margin: 40px 0;

    dl {
        display: flex;
        font-size: $font16;

        dt {
            width: 75px;
            color: $text-color-lv-0;
            font-weight: 700;
            margin-right: 20px;
        }
    }

    dl+dl {
        margin-top: 20px;
    }
}

.main-input {
    ul {
        width: calc(100% - 220px);
    }

    .button-wrapper {
        width: 220px;

        .deep{
            padding: 10px;
        }

        .grey {
            padding: 10px;
        }
    }

    button+button {
        margin-top: 10px;
    }
}
</style>