import { createRouter, createWebHistory } from "vue-router";
import store from "@/store"; // Vuex store import

import AdminHeader from "@/layouts/AdminHeader.vue";
import UserHeader from "@/layouts/UserHeader.vue";

import indexVue from "@/views/index.vue"

import UserMain from "@/views/user/Main.vue";
import UserMainMore from "@/views/user/MainMore.vue";
import MainDetail from "@/views/user/MainDetail.vue";
import MatchMng from "@/views/user/mypage/MatchMng.vue";
import GradeMng from "@/views/user/mypage/GradeMng.vue";
import LectMng from "@/views/user/mypage/LectMng.vue";
import CalcMng from "@/views/user/mypage/CalcMng.vue";
import ProfileMng from "@/views/user/mypage/ProfileMng.vue";
import InfoMng from "@/views/user/mypage/InfoMng.vue";
import MainLogin from "@/views/login/MainLogin.vue";
import OrganLogin from "@/views/login/OrganLogin.vue";
import JoinAgree from "@/views/login/JoinAgree.vue";
import JoinFirst from "@/views/login/JoinFirst.vue";
import JoinSecond from "@/views/login/JoinSecond.vue";
import IdCheck from "@/views/help/IdCheck";
import PwFind from "@/views/help/PwFind";
import DashBoard from "@/views/user/mypage/DashBoard.vue";
import MainDetailClear from "@/views/user/MainDetailClear.vue";
import MessageChat from "@/views/user/mypage/MessageChat.vue";
import CalenderList from "@/views/user/mypage/CalenderList.vue";
import AppLogout from "@/views/user/mypage/AppLogout.vue";
import AdmLogout from "@/views/admin/AdmLogout";
import AdmDashBoard from "@/views/admin/AdmDashBoard.vue";
import AdmProfileMng from "@/views/admin/AdmProfileMng.vue";
import AdmCollectMng from "@/views/admin/AdmCollectMng.vue";
import AdmCollectInfo from "@/views/admin/AdmCollectInfo.vue";
import AdmUserFind from "@/views/admin/AdmUserFind.vue";
import AdmUserFindDetail from "@/views/admin/AdmUserFindDetail.vue";
import AdmSchedule from "@/views/admin/AdmSchedule.vue";
import AdmMessageChat from "@/views/admin/AdmMessageChat.vue";
import AdmGradeMng from "@/views/admin/AdmGradeMng.vue";
import AdmLectMng from "@/views/admin/AdmLectMng.vue";
import AdmPaymentMng from "@/views/admin/AdmPaymentMng.vue";
import AdmUserMng from "@/views/admin/AdmUserMng.vue";
import AdmPaymentSet from "@/views/admin/AdmPaymentSet.vue";
import PaymentSet from "@/views/user/mypage/PaymentSet.vue";
import AdmUserDetail from "@/views/admin/AdmUserDetail.vue";
import BrandStory from "@/views/static/BrandStory.vue";
import WclsInfo from "@/views/static/WclsInfo.vue";
import PrivacyInfo from "@/views/static/Privacy.vue";

const routes = [
    {
        path: '/',
        component: indexVue,
        meta : {
            gubun : ''
        }
    },
    {
        path: "/brandstory",
        component: BrandStory,
        meta : {
            gubun : ''
        }
    },
    {
        path: "/wecls",
        component: WclsInfo,
        meta : {
            gubun: ''
        }
    },
    {
        path: "/privacy",
        component: PrivacyInfo,
        meta : {
            gubun: ''
        }
    },
    {
        path: '/login',
        component: MainLogin,
        meta : { 
            gubun : '',
            type  : 'I'
        }
    },
    {
        path: '/organLogin',
        component: OrganLogin,
        meta : { 
            gubun : '',
            type  : 'O'
        }
    },
    {
        path: '/join/agree',
        component: JoinAgree,
        meta : { 
            gubun : ''
        }
    },
    {
        path: '/join',
        component: JoinFirst,
        meta : { 
            gubun : ''
        }
    },
    {
        path: '/join/setting',
        component: JoinSecond,
        meta : { 
            gubun : ''
        }
    },
    {
        path: '/help/idCheck',
        component: IdCheck,
        meta : { 
            gubun : ''
        }
    },
    {
        path: '/help/pwFind',
        component: PwFind,
        meta : { 
            gubun : ''
        }
    },
    {
        path : '/user/main',
        component: UserMain,
        meta: {
            gubun : 'user',
            layout: UserHeader
        }
    },
    {
        path : '/user/main/more/:type',
        component: UserMainMore,
        meta: {
            gubun : 'user',
            layout: UserHeader
        }
    },
    {
        path : '/user/main/detail',
        component: MainDetail,
        meta: {
            gubun : 'user',
            layout: UserHeader
        }
    },
    {
        path : '/user/main/clear',
        component: MainDetailClear,
        meta: {
            gubun : 'user',
            layout: UserHeader
        }
    },
    {
        path: '/user/mypage/logout',
        component: AppLogout,
        meta: {
            gubun : 'user',
            layout: UserHeader
        }
    },
    {
        path : '/user/mypage/dashboard',
        component: DashBoard,
        meta: {
            gubun : 'user',
            layout: UserHeader
        }
    },
    {
        path : '/user/mypage/calender',
        component: CalenderList,
        meta: {
            gubun : 'user',
            layout: UserHeader
        }
    },
    {
        path : '/user/mypage/matchMng',
        component: MatchMng,
        meta: {
            gubun : 'user',
            layout: UserHeader
        }
    },
    {
        path : '/user/mypage/gradeMng',
        component: GradeMng,
        meta: {
            gubun : 'user',
            layout: UserHeader
        }
    },
    {
        path : '/user/mypage/lectMng',
        component: LectMng,
        meta: {
            gubun : 'user',
            layout: UserHeader
        }
    },
    {
        path : '/user/mypage/calcMng',
        component: CalcMng,
        meta: {
            gubun : 'user',
            layout: UserHeader
        }
    },
    {
        path : '/user/mypage/calcSub:/:mtCd',
        component: PaymentSet,
        meta: {
            gubun : 'user',
            layout: UserHeader
        }
    },
    {
        path : '/user/mypage/message',
        component: MessageChat,
        meta: {
            gubun : 'user',
            layout: UserHeader
        }
    },
    {
        path : '/user/mypage/profileMng',
        component: ProfileMng,
        meta: {
            gubun : 'user',
            layout: UserHeader
        }
    },
    {
        path : '/user/mypage/infoMng',
        component: InfoMng,
        meta: {
            gubun : 'user',
            layout: UserHeader
        }
    },
    {
        path : '/admin/main',
        component: AdmDashBoard,
        meta: {
            gubun : 'admin',
            layout: AdminHeader
        }
    },
    {
        path: '/admin/logout',
        component: AdmLogout,
        meta: {
            gubun : 'admin',
            layout: AdminHeader
        }
    },
    {
        path : '/admin/schedule',
        component: AdmSchedule,
        meta: {
            gubun : 'admin',
            layout: AdminHeader
        }
    },
    {
        path : '/admin/message',
        component: AdmMessageChat,
        meta: {
            gubun : 'admin',
            layout: AdminHeader
        }
    },
    {
        path : '/admin/gradeMng',
        component: AdmGradeMng,
        meta: {
            gubun : 'admin',
            layout: AdminHeader
        }
    },
    {
        path : '/admin/lectMng',
        component: AdmLectMng,
        meta: {
            gubun : 'admin',
            layout: AdminHeader
        }
    },
    {
        path : '/admin/paymentMng',
        component: AdmPaymentMng,
        meta: {
            gubun : 'admin',
            layout: AdminHeader
        }
    },
    {
        path : '/admin/paymentDetail/:mtCd',
        component: AdmPaymentSet,
        meta: {
            gubun : 'admin',
            layout: AdminHeader
        }
    },
    {
        path : '/admin/profileMng',
        component: AdmProfileMng,
        meta: {
            gubun : 'admin',
            layout: AdminHeader
        }
    },
    {
        path : '/admin/collectMng/:lpCd?/:source?',
        component: AdmCollectMng,
        meta: {
            gubun : 'admin',
            layout: AdminHeader
        }
    },
    {
        path : '/admin/collectInfo',
        component: AdmCollectInfo,
        meta: {
            gubun : 'admin',
            layout: AdminHeader
        }
    },
    {
        path : '/admin/userMng',
        component: AdmUserMng,
        meta: {
            gubun : 'admin',
            layout: AdminHeader
        }
    },
    {
        path : '/admin/userDetail/:lcCd',
        component: AdmUserDetail,
        meta: {
            gubun : 'admin',
            layout: AdminHeader
        }
    },
    {
        path : '/admin/userFind',
        component: AdmUserFind,
        meta: {
            gubun : 'admin',
            layout: AdminHeader
        }
    },
    {
        path : '/admin/userFind/detail/:itCd',
        component: AdmUserFindDetail,
        meta: {
            gubun : 'admin',
            layout: AdminHeader
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(){
        return { top: 0 }
    },
});

router.beforeEach((to, from, next) => {
    if (to.meta.gubun === 'user') // 강사 화면일 때
    {
        // 강사 세션 처리
        if (store.state.login.login.inst === 'N') 
        {
            // 강사 세션이 유효하지 않을 경우 강사 로그인 페이지로 이동
            next('/login');
        } 
        else 
        {
            next();
        }
    } 
    else if(to.meta.gubun === 'admin') 
    {
        // 기관 세션 처리
        if (store.state.login.login.organ === 'N') 
        {
            // 기관 세션이 유효하지 않을 경우 기관 로그인 페이지로 이동
            next('/organLogin');
        } 
        else 
        {
            next();
        }
    } 
    else 
    {   
        /** 나머지 회원가입, 로그인 화면 등 */
        next();
    }
});

export default router