import { getAxiosData, getCommas } from "@/assets/js/function";
import store from '@/store';
import { useRouter } from "vue-router";

/**
 *  @description  강사 메인 공고 리스트
 */
export async function getMainList(params)
{
    const router = useRouter();

    try
    {
        const res = await getAxiosData('https://data.wecls.com/organ/mainList/getList', params);

        console.log(res);

        const list = {
            bestList : res.data['bestList'],
            mainList : res.data['mainList'],
            likeList : res.data['likeList']
        };

        store.commit("main/setList", list);
    }
    catch(error)
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'inst');
            router.push('/login');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}

/**
 * @description 더보기 공고 리스트
 */
export async function getMainMoreList(params, type)
{
    const router = useRouter();

    try 
    {
        const list = [];
        const res  = await getAxiosData('https://data.wecls.com/organ/mainList/getList', params);

        console.log(res);

        switch(type)
        {
            case 'BEST':
                res.data['bestList'].map(item => {
                    list.push(item);
                });
            break;
            case 'MAIN':
                res.data['mainList'].map(item => {
                    list.push(item);
                });
            break;
            case 'LIKE':
                res.data['likeList'].map(item => {
                    list.push(item);
                });
            break;
        }

        store.commit("main/setMoreList", list);
    }
    catch (error) 
    {
        console.error(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'inst');
            router.push('/login');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}

export async function getMainInfo(params) 
{
    const router = useRouter();

    try 
    {
        const res = await getAxiosData("https://data.wecls.com/organ/mainList/getInfo", params);
        console.log(res);

        const imgList = res.data['imgList'].map(item => ({
            fileNm      : item.fileNm,
            filePath    : item.filePath
        }));

        const fileList = res.data['fileList'].map(item => ({
            fileNm      : item.fileNm,
            filePath    : item.filePath
        }));

        const info = {
            lcCd        : res.data.lcCd,
            fileNm      : res.data.fileNm,
            subject     : res.data.subject,
            regDt       : res.data.regDt,
            mainFieldCd : res.data.mainFieldCd,
            fieldCd     : res.data.fieldCd,
            lectPeriod  : res.data.lectPeriod,
            lectTime    : res.data.lectTime,
            totalTime   : res.data.totalTime,
            amt         : getCommas(Number(res.data.amt)),
            likeCheck   : res.data.likeCheck,
            dday        : res.data.dday,
            keyword     : res.data.keyword,
            estAmt      : getCommas(Number(res.data.estAmt)),
            area1       : res.data.lectArea1,
            area2       : res.data.lectArea2,
            target      : res.data.target,
            addr        : res.data.addr,
            addrDetail  : res.data.addrDetail,
            lectEq      : res.data.lectEq,
            regPeriod   : res.data.regPeriod,
            lectMemo    : res.data.lectMemo,
            reqDoc      : res.data.reqDoc,
            etcDoc      : res.data.etcDoc,
            ogCd        : res.data.ogCd,
            ogNm        : res.data.ogNm,
            ogAddr      : res.data.ogAddr,
            chEmail     : res.data.chEmail,
            chPerTel    : res.data.chPerTel,
            chPerNm     : res.data.chPerNm,
            imgList     : imgList,
            fileList    : fileList
        };
        
        store.commit("main/setInfo", info);
    } 
    catch (error) 
    {
        console.error(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'inst');
            router.push('/login');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}