/**
 * @description 아이디 찾기 vuex 모듈 관련
 * @author 김원명, @date 2024-01-18
 */
const idFindModule = {
    namespaced  : true,
    state       : {
        idFind : {
            certified   : getCertifiedInfo(),
            list        : []
        }
    },
    mutations   : {
        setCertifed(state, newCertified)
        {
            state.idFind.certified = newCertified;
        },
        setList(state, newList)
        {
            state.idFind.list = newList;
        },
        setReset(state)
        {
            state.idFind.certified = getCertifiedInfo();
        }
    }
};


function getCertifiedInfo()
{
    return {
        yn          : 'N', /** 본인인증 완료 여부 (Y/N) */
        name        : '',
        tel         : '',
        birthday    : ''
    }
}
  
export default idFindModule;