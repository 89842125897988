<template>
    <div class="input flex" @click="toggleCheck">
        <input type="checkbox" :id="inputId" :checked="checked" @change="onCheckboxChange">
        <label :for="inputId">
            <img src="@/assets/images/img_check.png" class="check" />
            <img src="@/assets/images/img_check_on.png" class="check_on" />{{ labelText }}
        </label>
    </div>
</template>

<script>
export default {
    name: "CheckInlineBox",
    props: {
        inputId: String,
        inputNm: String,
        labelText: String,
        checked: Boolean
    },

    methods: {
        onCheckboxChange() {
            this.$emit('update:checked', !this.checked);
        }
    }
}
</script>

<style lang="scss" scoped>
.input {
    padding: 8px 0;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;

    input {
        display: none;

        &:checked {
            & + label{
                .check {
                    display: none;
                }

                .check_on {
                    display: block;
                }
            }
        }
    }

    label {
        position: relative;
        left: 0;
        top: 0;
        font-size: $font14;
        color: $text-color-lv-1;
        font-weight: 400;
        display: flex;

        img {
            display: block;
            width: 16px;
            height: 16px;
            margin-left: 8px;
            margin-right: 12px;

            &.check_on {
                display: none;
            }
        }
    }
}
</style>