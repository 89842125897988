import { getAxiosData, getCommas } from "@/assets/js/function";
import store from '@/store';
import { useRouter } from "vue-router";

/**
 * @description 강사 정산 관리
 */
export async function getCalcList(params)
{
    const router = useRouter(); 

    try
    {
        const res = await getAxiosData('https://data.wecls.com/inst/dashboard/calcList', params);

        console.log(res);

        /** 금액 부분 처리 */
        const amt = [
            { title : '정산대기 금액', count : getCommas(Number(res.data['cnt']['waitAmt'])) },
            { title : '정산신청 금액', count : getCommas(Number(res.data['cnt']['procAmt'])) },
            { title : '정산완료 금액', count : getCommas(Number(res.data['cnt']['compAmt'])) },
            { title : '총 금액', count : getCommas(Number(res.data['cnt']['total'])) }
        ];

        store.commit('calc/setAmt', amt);

        /** 리스트 및 카운트 데이터 처리 */
        const list = [];

        let wait   = 0;
        let proc   = 0;
        let comp   = 0;
        
        res.data['list'].map((item) => {
            switch(item.status)
            {
                case '정산 대기':
                    wait++;
                break;
                case '정산 신청':
                    proc++;
                break;
                case '정산 완료':
                    comp++;
                break;
            }

            const dataList = {
                mtCd        : item['mtCd'],
                status      : item['status'],
                lectPeriod  : item['lectPeriod'],
                subject     : item['subject'],
                amt         : getCommas(Number(item['amt'])),
                calcPrice   : getCommas(Number(item['calcPrice'])),
                regDt       : item['regDt'],
                calcGb      : item['calcGb'],
                calcMean    : item['calcMean'] === 'D' ? '사용' : '',
                docEunm     : item['docEunm'],
                modDt       : item['modDt']
            };

            list.push(dataList);
        });

        const cnt = [
            { title : '정산 대기', count : wait },
            { title : '정산 신청', count : proc },
            { title : '정산 완료', count : comp },
            { title : '전체', count : list.length }
        ];

        store.commit('calc/setCnt', cnt);
        store.commit('calc/setAllList', list);
    }
    catch(error)
    {
        console.error(error);
        if(error.response.data.status === 401)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'inst');
            router.push('/login');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}

export async function getCalcInfo(params)
{
    const router = useRouter(); 

    try
    {
        const res = await getAxiosData('https://data.wecls.com/inst/dashboard/calcInfo', params);

        console.log(res);

        const lcInfo  = res.data['lcInfo'];
        const payInfo = res.data['payInfo'];

        const info = {
            lcInfo      : lcInfo,
            calcGb      : payInfo['calcGb'] ? payInfo['calcGb'] : 'N',
            totalAmt    : Number(payInfo['totalAmt']),
            charge      : payInfo['charge'],
            chargeAmt   : Number(payInfo['totalAmt']) - Number(payInfo['calcAmt']),
            calcAmt     : Number(payInfo['calcAmt']),
            calcMean    : payInfo['calcMean'] ? payInfo['calcMean'] : 'A',
            bankNm      : payInfo['bankNm'],
            bankAcc     : payInfo['bankAcc'],
            bankNum     : payInfo['bankNum'],
            docEnum     : payInfo['docEnum'] ? payInfo['docEnum'] : 'T',
            einNm       : payInfo['contents'] ? payInfo['contents']['einNm'] : '',
            einNum      : payInfo['contents'] ? payInfo['contents']['einNm'] : '',
            einType     : payInfo['contents'] ? payInfo['contents']['einType'] : '',
            einItem     : payInfo['contents'] ? payInfo['contents']['einItem'] : '',
            ceoNm       : payInfo['contents'] ? payInfo['contents']['ceoNm'] : '',
            addr        : payInfo['contents'] ? payInfo['contents']['addr'] : '',
            email       : payInfo['contents'] ? payInfo['contents']['email'] : '',
            adminYn     : payInfo['adminYn']
        };

        store.commit('calc/setInfo', info);
    }
    catch(error)
    {
        console.error(error);
        if(error.response.data.status === 401)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'inst');
            router.push('/login');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}