import { getDate, getConvertDate } from "@/assets/js/function";

const date = getDate('mon');
/**
 * @description 리뷰관리 vuex 모듈 관련
 * @author 정해원, @date 2023-11-22 / @author 김원명, @date 2023-12-13
 */
const gradeModule = {
    namespaced  : true,
    state       : {
        grade : {
            search  : {
                type    : { name : '이달', value : 'mon' },
                startDt : getConvertDate(date['st'], 'yyyy-mm-dd'),
                endDt   : getConvertDate(date['et'], 'yyyy-mm-dd')
            },
            info    : [
                { title : '전체 리뷰수', count : 0 },
                { title : '평균 평점', count : 0 },
                { title : '최고 평점', count : 0 },
                { title : '최소 평점', count : 0 },
                { title : '미답글', count : 0 },
            ],
            list    : []
        }
    },
    mutations : {
        setInfo(state, newInfo)
        {
            state.grade.info = newInfo;
        },
        setList(state, newList)
        {
            state.grade.list = newList;
        }
    }
};
  
export default gradeModule;