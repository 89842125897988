<template>
    <LabelTable title="자사 정보" class="mt-50">
        <tr>
            <th>로고 또는 사진</th>
            <td>
                <RepImage imgTitle="대표로고" :inputId="'img1'" :imageView="info['img1']['file']" @file-selected="getFileSelect" @file-cleared="getFileDelete" />
            </td>
        </tr>
        <tr>
            <th>전문성 어필 이미지</th>
            <td>
                <RepImage imgTitle="대표사진" :inputId="'img2'" :imageView="info['img2']['file']" @file-selected="getFileSelect" @file-cleared="getFileDelete" />
                <RepImage imgTitle="2" :inputId="'img3'" :imageView="info['img3']['file']" @file-selected="getFileSelect" @file-cleared="getFileDelete" />
                <RepImage imgTitle="3" :inputId="'img4'" :imageView="info['img4']['file']" @file-selected="getFileSelect" @file-cleared="getFileDelete" />
                <RepImage imgTitle="4" :inputId="'img5'" :imageView="info['img5']['file']" @file-selected="getFileSelect" @file-cleared="getFileDelete" />
            </td>
        </tr>

        <tr>
            <th>기관코드</th>
            <td>{{ info['oaCd']}}</td>
        </tr>

        <tr>
            <th>국가</th>
            <td>
                <select v-model="info['cntry']" id="cntry" @change="getSelect">
                    <option v-for="(item, index) in country" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </td>
        </tr>

        <tr>
            <th>기관명<span class="important">*</span></th>
            <td>
                <InputBox :inputType="'text'" :inputId="'ogNm'" :inputValue="info['ogNm']" class="medium" placeholder="예: 위클즈" @input="getInputVal" />
            </td>
        </tr>

        <tr>
            <th>주소<span class="important">*</span></th>
            <td>
                <div class="input-button">
                    <InputBox :inputType="'text'" :inputId="'zip'" :inputValue="info['zip']" class="" labelText="우편번호" readonly />
                    <BaseButton buttonText="검색" @click="getAddr" class="small ml-10" />
                </div>
                <InputBox :inputType="'text'" :inputId="'addr'" :inputValue="info['addr']" class="medium" labelText="주소" readonly />
                <InputBox :inputType="'text'" :inputId="'addrDetail'" :inputValue="info['addrDetail']" class="medium" placeholder="상세주소를 입력하세요." labelText="상세주소" @input="getInputVal" />
            </td>
        </tr>

        <tr>
            <th>담당자 이름</th>
            <td>
                <InputBox :inputType="'text'" :inputId="'charPerNm'" :inputValue="info['charPerNm']" class="medium" placeholder="담당자 이름" @input="getInputVal" />
            </td>
        </tr>

        <tr>
            <th>대표전화</th>
            <td>
                <InputBox :inputType="'number'" :inputId="'tel'" :inputValue="info['tel']" class="medium" placeholder="대표전화" @input="getInputVal" />
            </td>
        </tr>

        <tr>
            <th>담당자 휴대폰 번호</th>
            <td>
                <InputBox :inputType="'number'" :inputId="'charPerTel'" :inputValue="info['charPerTel']" class="medium" placeholder="담당자 휴대폰 번호 " @input="getInputVal" />
            </td>
        </tr>

        <tr>
            <th>FAX 번호</th>
            <td>
                <InputBox :inputType="'number'" :inputId="'fax'" :inputValue="info['fax']" class="medium" placeholder="FAX 번호 " @input="getInputVal" />
            </td>
        </tr>

        <tr>
            <th>기관 소개</th>
            <td>
                <TextareaBox :inputId="'memo'" :inputValue="info['memo']" placeholder="소개 내용" @input="getInputVal" />
            </td>
        </tr>
    </LabelTable>

    <LabelTable title="입금 은행 정보" class="mt-10">
        <tr>
            <th>은행</th>
            <td>
                <select v-model="info['bankCd']">
                    <option v-for="(item, index) in bank" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </td>
        </tr>

        <tr>
            <th>예금주 명</th>
            <td>
                <InputBox :inputType="'text'" :inputId="'bankNm'" :inputValue="info['bankNm']" class="medium" placeholder="" @input="getInputVal" />
            </td>
        </tr>

        <tr>
            <th>계좌번호</th>
            <td>
                <InputBox :inputType="'text'" :inputId="'bankNum'" :inputValue="info['bankNum']" class="medium" placeholder="" @input="getInputVal" />
            </td>
        </tr>
    </LabelTable>

    <LabelTable title="사업자 등록증 정보" class="mt-10">
        <tr>
            <th>사업자등록증 사진</th>
            <td>
                <RepImage imgTitle="대표 사진" :inputId="'img6'" :imageView="info['img6']['file']" @file-selected="getFileSelect" @file-cleared="getFileDelete" />
            </td>
        </tr>

        <tr>
            <th>사업자명</th>
            <td>
                <InputBox :inputType="'text'" :inputId="'einNm'" :inputValue="info['einNm']" class="medium" placeholder="" @input="getInputVal" />
            </td>
        </tr>

        <tr>
            <th>대표자</th>
            <td>
                <InputBox :inputType="'text'" :inputId="'ceoNm'" :inputValue="info['ceoNm']" class="medium" placeholder="" @input="getInputVal" />
            </td>
        </tr>

        <tr>
            <th>사업자 등록번호</th>
            <td>
                <InputBox :inputType="'number'" :inputId="'einNum'" :inputValue="info['einNum']" class="medium" placeholder="" @input="getInputVal" />
            </td>
        </tr>

        <tr>
            <th>사업자 업태</th>
            <td>
                <InputBox :inputType="'text'" :inputId="'einType'" :inputValue="info['einType']" class="medium" placeholder="" @input="getInputVal" />
            </td>
        </tr>

        <tr>
            <th>사업자 종목</th>
            <td>
                <InputBox :inputType="'text'" :inputId="'einItem'" :inputValue="info['einItem']" class="medium" placeholder="" @input="getInputVal" />
            </td>
        </tr>

        <tr>
            <th>세금계산서 이메일</th>
            <td>
                <InputBox :inputType="'text'" :inputId="'einEmail'" :inputValue="info['einEmail']" class="medium" placeholder="" @input="getInputVal" />
            </td>
        </tr>
    </LabelTable>

    <LabelTable title="첨부파일" class="mt-10">
        <tr v-for="(file, index) in info['fileOther']" :key="index">
            <th>파일{{index + 1}}</th>
            <td>
                <div>
                    <FileBox :fileBtn="'파일첨부'" :inputId="'file' + index" :fileNm="file.text ? file.text : ''" 
                        :greyButton="'삭제하기'" @file-selected="getDataFileSelect" @file-cleared="getDataFile('D', index)" />
                </div>

                <button v-if="index === 0" type="button" @click="getDataFile('A')" class="blue-button">파일 추가 생성</button>
            </td>
        </tr>
    </LabelTable>

    <!-- <LabelTable title="계정 정보" class="mt-10">
        <tr>
            <th>아이디</th>
            <td></td>
        </tr>

        <tr>
            <th>변경 비밀번호</th>
            <td>
                <InputBox :inputType="'password'" :inputId="'pw'"  :inputValue="info['pw']" class="medium" placeholder="" />
            </td>
        </tr>

        <tr>
            <th>변경 비밀번호 확인</th>
            <td>
                <InputBox :inputType="'password'" :inputId="'pwCheck'" :inputValue="info['pwCheck']" class="medium" placeholder="" />
            </td>
        </tr>
    </LabelTable> -->
</template>

<script>
import LabelTable from "@/components/table/LabelTable.vue";
import RepImage from "@/components/input/RepImage.vue";
import InputBox from "@/components/input/InputBox.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import TextareaBox from "@/components/input/TextareaBox.vue";
import FileBox from "@/components/input/FileBox.vue";


import { mapState } from 'vuex';
import { getProfileAdmInfo } from "@/assets/js/admin/profileAdm";

export default {
    name: "admProfileTable",
    components: { FileBox, TextareaBox, BaseButton, InputBox, RepImage, LabelTable },
    data() {
        return {
            uploadFileGb : '',
            deleteFileGb : ''
        }
    },
    computed: {
        ...mapState('login', {
            cnt    : state => state.login.ogCnt
        }),
        ...mapState('data', {
            country   : state => state.data.country,
            bank      : state => state.data.bank
        }),
        ...mapState('profileAdm', {
            info    : state => state.profileAdm.info
        })
    },
    created() {
        this.getStatCheck()
    },
    methods: {
        async getStatCheck() {
            this.$store.commit('profileAdm/getFileReset');
            await getProfileAdmInfo();


            if(!this.info['ogCd'])
            {
                if(this.cnt === 0)
                {
                    this.cnt++;
                    this.$emit('get-check');
                }
            }
        },
        getDataFile(gb, index = 0) {
            this.$store.commit('profileAdm/getDataFile', { gb : gb, index : index });
        },
        getInputVal(event) {
            const id    = event.target.id;
            const value = event.target.value;

            this.info[id] = value;
        },
        getSelect(){
            const id    = event.target.id;
            const value = event.target.value;

            this.info[id] = value;
        },
        getFileSelect({inputId, file}) {
            let num = 1;

            switch(inputId)
            {
                case 'img1':
                    this.uploadFileGb = 'P';
                break;
                case 'img2':
                    this.uploadFileGb = 'N';
                break;
                case 'img3':
                    this.uploadFileGb = 'N';
                    num = 2;
                break;
                case 'img4':
                    this.uploadFileGb = 'N';
                    num = 3;
                break;
                case 'img5':
                    this.uploadFileGb = 'N';
                    num = 4;
                break;
                case 'img6':
                    this.uploadFileGb = 'E';
                break;
            }

            const fileCheck = this.info['fileGb'].indexOf(item => item.fileGb === this.uploadFileGb && item.fileNo === num);

            if(fileCheck !== -1)
            {
                this.info['fileGb'].splice(fileCheck, 1);
            }

            if(this.info['fileGb'].length === 0)
            {
                this.info['delYn'] = 'N';
            }

            this.$store.commit('profileAdm/updateFile', { fileType: inputId, file: file });
        },
        getFileDelete(inputId) {
            if(this.info[inputId]['file'] !== null )
            {
                if(this.info[inputId]['gb'] === 'Y')
                {
                    this.info['delYn'] = 'Y';
                }

                let num = 1;

                switch(inputId)
                {
                    case 'img1':
                        this.deleteFileGb = 'P';
                    break;
                    case 'img2':
                        num = 2;
                        this.deleteFileGb = 'N';
                    break;
                    case 'img3':
                        num = 3;
                        this.deleteFileGb = 'N';
                    break;
                    case 'img4':
                        this.deleteFileGb = 'N';
                        num = 4;
                    break;
                    case 'img5':
                        this.deleteFileGb = 'N';
                        num = 5;
                    break;
                    case 'img6':
                        this.deleteFileGb = 'E';
                    break;
                }

                let gbCheck = this.info['fileGb'].some(item => item.fileGb === this.deleteFileGb && item.no === num);

                if (!gbCheck) 
                {
                    this.info['fileGb'].push({fileGb : this.deleteFileGb, fileNo : num});
                }
            }

            this.$store.commit('profileAdm/clearFile', inputId);
        },
        getDataFileSelect({inputId, file}) {
            const index = Number(inputId.replace('file', ''));

            this.$store.commit('profileAdm/getDataFileSelect', { index: index, file: file });
        },
        getAddr() {
            new window.daum.Postcode({
                oncomplete: (data) => {
                    this.info['zip']  = data.zonecode;
                    this.info['addr'] = data.address;

                    const inputElement = document.getElementById('addrDetail');
                    inputElement.focus();
                }
            }).open();
        }
    }
}
</script>

<style lang="scss" scoped>
table {
    tr {
        td {
            position: relative;

            .input-button {
                .input {
                    width: 385px;
                }

                .small{
                    background-color: $button-color-lv-3;
                }
            }

            .blue-button {
                padding: 8px 20px;
                border-radius: 2px;
                height: 40px;
                background-color: $blue-color-lv-4;
                font-size: $font14;
                color: $bk-color-lv-3;
                cursor: pointer;
                min-width: 80px;
                margin-left: 20px;
                display: flex;
                align-items: center;
                font-weight: 500;
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }
    }
}

select {
    width: 140px;
    height: 38px;
    border: 1px solid #E5E5EC;
    font-size: 0.875rem;
    font-weight: 400;
    border-radius: 4px;
}
</style>