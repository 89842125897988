<template>
    <div class="count">
        <div class="count-list" v-for="(count, index) in counts" :key="index">
            <p>
                {{ count.count }}
            </p>
            <h5>
                {{ count.title }}
            </h5>
        </div>
    </div>
</template>

<script>
export default {
    name: "CountList",
    props: {
        counts: Array,
    }
}
</script>

<style lang="scss" scoped>
.count{
    width: 100%;
    display: flex;
    text-align: center;
    border: 1px solid $line-color-lv-4;
    background-color: $bk-color-lv-3;
    border-radius: 4px;
    padding: 20px 0;

    &-list{
        p{
            font-size: $font20;
            font-weight: 700;
            color: $blue-color-lv-8;
        }

        h5{
            font-size: $font12;
            color: $line-color-lv-0;
            font-weight: 500;
        }
    }

    &-list + &-list{
        border-left: 1px solid $line-color-lv-4;
    }
}
</style>