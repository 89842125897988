/**
 * @description 기관 설정  vuex 모듈 관련
 * @author 정해원, @date 2023-11-24 / (수정) @author 김원명, @date 2023-12-18
 */

const profileAdmModule = {
    namespaced: true,
    state: {
        profileAdm: {
            info    : getProfileInfo()
        }
    },
    mutations: {
        setInfo(state, profileData) {
            state.profileAdm.info = profileData;
        },
        updateFile(state, { fileType, file }) 
        {
            state.profileAdm.info[fileType] = {
                text: file.name,
                file: file
            };
        },
        clearFile(state, key) 
        {
            if (state.profileAdm.info[key]['file'] !== null) 
            {
                state.profileAdm.info[key] = {
                    text : '',
                    file : null
                };
            }
        },
        getDataFile(state, { gb, index }) 
        {
            if(gb === 'A')
            {
                state.profileAdm.info.fileOther.push({ text : '', file : null, gb : 'N' });

                const fileCheck = state.profileAdm.info['fileGb'].indexOf(item => item.fileGb === 'F' && item.fileNo === (index + 1));

                if(fileCheck !== -1)
                {
                    state.profileAdm.info['fileGb'].splice(fileCheck, 1);
                }
    
                if(state.profileAdm.info['fileGb'].length === 0)
                {
                    state.profileAdm.info['delYn'] = 'N';
                }
            }
            else
            {
                /** 파일 삭제 */
                const data = state.profileAdm.info['fileOther'][index];

                if(data['gb'] === 'Y')
                {
                    state.profileAdm.info['delYn'] = 'Y';
                }

                const check = state.profileAdm.info['fileGb'].some(item => item.fileGb === 'F' && item.fileNo === data['fileNo']);

                if (!check) 
                {
                    state.profileAdm.info['fileGb'].push({fileGb : 'F', fileNo : data['fileNo']});
                }

                if(index !== 0)
                {
                    state.profileAdm.info.fileOther.splice(index, 1);
                }
                else
                {
                    state.profileAdm.info.fileOther[0] = {
                        text : '',
                        file : null,
                        gb   : 'N'
                    };
                }
            }
        },
        getDataFileSelect(state, { index, file }) 
        {
            state.profileAdm.info.fileOther[index] = {
                text: file.name,
                file: file
            };
        },
        getFileReset(state)
        {
            state.profileAdm.info.img1.file = null;
            state.profileAdm.info.img2.file = null;
            state.profileAdm.info.img3.file = null;
            state.profileAdm.info.img4.file = null;
            state.profileAdm.info.img5.file = null;
        }
    },
};

function getProfileInfo() { 
    return {
        ogCd        : '',
        oaCd        : '',
        img1        : { // 대표이미지
            text    : '',
            file    : null,
            gb      : 'N'
        },
        img2        : { // 전문성이미지1
            num     : 2,
            text    : '',
            file    : null,
            gb      : 'N'
        },
        img3        : { // 전문성이미지2
            num     : 3,
            text    : '',
            file    : null,
            gb      : 'N'
        },
        img4        : { // 전문성이미지3
            num     : 4,
            text    : '',
            file    : null,
            gb      : 'N'
        },
        img5        : { // 전문성이미지4
            num     : 5,
            text    : '',
            file    : null,
            gb      : 'N'
        },
        cntry       : '',
        ogNm        : '',
        zip         : '',
        addr        : '',
        addrDetail  : '',
        charPerNm   : '',
        charPerTel  : '',
        tel         : '',
        fax         : '',
        memo        : '',
        bankCd      : '',
        bankNm      : '',
        bankNum     : '',
        einNum      : '',
        ceoNm       : '',
        einNm       : '',
        einType     : '',
        einItem     : '',
        einEmail    : '',
        img6        : { // 사업자이미지
            text    : '',
            file    : null,
            gb      : 'N'
        },
        fileOther   : [{
            text    : '',
            file    : null,
            gb      : 'N'
        }],
        id              : '',
        pw              : '',
        pwCheck         : '',
        delYn           : 'N',
        fileGb          : []
    };
}

export default profileAdmModule;
