<template>
    <div class="find admin-div">
        <div class="inner section">
            <div class="find-title blue">
                <h2>{{info.appeal}}</h2>
                <div class="button-wrapper">
                    <RoundButton buttonText="문의하기" class="grey" @click="getRoomSet"/>
                    <RoundButton buttonText="내 강의 지원 제안" @click="getSendNoti"/>
                </div>
            </div>

            <table class="find-info margin">
                <colgroup>
                    <col class="w20" />
                    <col class="w12" />
                    <col class="w28" />
                    <col class="w12" />
                    <col class="w28" />
                </colgroup>
                <tbody>
                <tr>
                    <td rowspan="4">
                        <img v-if="info['thumbIdPic']" :src="`${downloadPath}/${info['thumbIdPic']['filePath']}`" alt="" title="" />
                    </td>
                   
                    <th>이름</th>
                    <td>{{ info['name'] }}</td>
                    <th>생년월일</th>
                    <td>{{ info['birthday'] }}</td>
                </tr>

                <tr>
                    <th>전화번호</th>
                    <td>{{ info['tel'] }}</td>
                    <th>이메일</th>
                    <td>{{ info['email'] }}</td>
                </tr>

                <tr>
                    <th>분야</th>
                    <td>{{ info['fieldNm'] }}</td>
                    <th>경력</th>
                    <td>{{ info['careerNm'] }}</td>
                </tr>

                <!-- <tr>
                    <th>주소</th>
                    <td colspan="3">{{ info['addr'] }}</td>
                </tr> -->
                </tbody>
            </table>

            <div class="find-title mb-10">
                <h2>"학력사항"</h2>
            </div>

            <table>
                <colgroup>
                    <col class="w12" />
                    <col class="w88" />
                </colgroup>

                <tbody>
                <tr>
                    <th>최종학력</th>
                    <td>{{ info['acadAbilNm'] }}</td>
                </tr>
                <tr>
                    <th>학교명</th>
                    <td>{{ info['school'] }}</td>
                </tr>
                <tr>
                    <th>학과명</th>
                    <td>{{ info['major'] }}</td>
                </tr>
                </tbody>
            </table>
            <div class="find-title mb-10 margin">
                <h2>"자기소개서"</h2>
            </div>
            <table>
                <tbody>
                <tr class="find-padding">
                    <td>
                        <span v-html="info['instMemo']"></span>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="find-title mb-10 margin">
                <h2>"경력기술서"</h2>
            </div>
            <table>
                <colgroup>
                    <col class="w12" />
                    <col class="w88" />
                </colgroup>
                <tbody>
                <tr>
                    <th>대표경력 3개</th>
                    <td>
                        <ul v-for="(item, index) in info['repCareer']" :key="index">
                            <li>{{ item }}</li>
                        </ul>
                    </td>
                </tr>
                <tr class="find-padding">
                    <td colspan="2">
                        <span v-html="info['careerMemo']"></span>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="find-title mb-10 margin2">
                <h2>"첨부파일 양식"</h2>
            </div>
            <table>
                <colgroup>
                    <col class="w12" />
                    <col class="w88" />
                </colgroup>
                <tbody>
                    <tr>
                        <th>첨부파일 다운</th>
                        <td>
                            <ul v-for="(item, index) in info['fileList']" :key="index">
                                <li class="download">
                                    <a :href="`${downloadPath}/${item['filePath']}`">{{ item['fileNm'] }}</a>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="find-title mb-10 margin2">
                <h2>"선호사항"</h2>
            </div>

            <table>
                <colgroup>
                    <col class="w12" />
                    <col class="w88" />
                </colgroup>

                <tbody>
                <tr>
                    <th>선호요일</th>
                    <td>{{ info['prefDay'] }}</td>
                </tr>
                <tr>
                    <th>선호지역</th>
                    <td>{{ info['desArea1'] }}</td>
                </tr>
                </tbody>
            </table>

            <div class="find-title mb-10 margin2">
                <h2>"정보조회 동의 여부"</h2>
            </div>

            <table>
                <tbody>
                <tr>
                    <td colspan="2">
                        동의
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <WhiteAlert v-if="popStat" title="지원할 강의 선택" buttonText="완료" @close-popup="popStat = !popStat" @confirm-review="getResult">
        <template v-if="lect.length === 0">
            <p class="orange">
                등록된 공고가 없습니다.<br>
                모집 등록 페이지로 이동하시겠습니까?
            </p>
        </template>
        <template v-else>
            <label :for="item['lpCd']" v-for="(item, index) in lect" :key="index" >
                <input type="checkbox" :id="item['lpCd']" :value="item['lpCd']" @change="getLpCd" :disabled="item['volYn'] === 'Y'"/>
                {{ item['subject'] }}
            </label>
        </template>

        <p class="mt-25"></p>
    </WhiteAlert>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import RoundButton from "@/components/button/RoundButton.vue";
import WhiteAlert from "@/components/alert/WhiteAlert.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from 'vuex';
import { getUserFindAdmInfo } from "@/assets/js/admin/userfind";
import { getAxiosData } from "@/assets/js/function"

export default {
    name: "AdmUserFindDetail",
    components: {WhiteAlert, RoundButton, Confirm},
    data() {
        return {
            resultYn            : 'N',
            compYn              : 'N',
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            popStat             : false,
            downloadPath        : 'https://elasticbeanstalk-ap-northeast-2-627549176645.s3.ap-northeast-2.amazonaws.com',
            lpCd                : []
        }
    },
    computed: {
        ...mapState('userFindAdm', {
            info : state => state.userFindAdm.info,
            lect : state => state.userFindAdm.lect,
        })
    },
    created() {
        this.getStatCheck(); 
    },
    methods: {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.compYn === 'N')
            {
                if(this.resultYn === 'Y')
                {
                    this.getRoomResult();
                }
            }

            if(this.compYn === 'Y')
            {
                this.compYn = 'N';
                this.$router.push('/admin/message');
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getStatCheck(){
            const itCd = this.$route.params.itCd;

            const params = {
                itCd : itCd
            };

            getUserFindAdmInfo(params);
        },
        getLpCd() {
            const checkStat = event.target.checked;
            const value     = event.target.value;

            if (!this.lpCd.includes(value)) 
            {
                if(checkStat)
                {
                    this.lpCd.push(value);
                }
            }
            else 
            {
                if(!checkStat)
                {
                    let index = this.lpCd.indexOf('c');
                    this.lpCd.splice(index, 1);
                }
            }
        },
        getRoomSet() {
            this.getConfirmSet('confirm', '문의', '1:1 문의 채팅방을 생성하시겠습니까?');
            this.resultYn = 'Y';
        },
        async getRoomResult() {
            try
            {
                const params = {
                    accCd : this.info['itCd']
                }

                const res = await getAxiosData('https://data.wecls.com/chatMessage/createChat', params);

                console.log(res);

                if(res['status'] === 200)
                {
                    this.compYn = 'Y';
                    this.getConfirmSet('alert', '완료', '채팅방 생성이 완료되었습니다. 채팅 메세지 화면으로 이동합니다.');

                    const webSocket = new WebSocket('wss://vfqg19h2ja.execute-api.ap-northeast-2.amazonaws.com/production/');

                    webSocket.onopen = () => {
                        console.log('test');
                        const createRoom = {
                            action    : 'createRoom',
                            roomId    : res.data['roomId'],
                            instUser  : res.data['instUser'],
                            organUser : res.data['organUser']
                        };

                        console.log(createRoom);

                        webSocket.send(JSON.stringify(createRoom));
                    };

                    webSocket.onmessage = (result) => {
                        console.log(result);
                    };
                }
            }
            catch(error)
            {
                console.log(error);
                this.resultYn = 'N';

                if(error.response.data.status === 401)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'inst');
                    this.$router.push('/login');
                }
                else
                {
                    this.getConfirmSet('alert', '에러', '에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        },
        async getSendNoti() {
            const params = {
                instCd : this.$route.params.itCd
            };

            const res = await getAxiosData('https://data.wecls.com/organ/mainList/getMyLectList', params);

            const list = res.data.map((item) => {
                return {
                    lpCd    : item.lpCd,
                    subject : item.subject,
                    volYn   : item.volYn
                }
            });

            this.$store.commit('userFindAdm/setLect', list);

            this.lpCd    = [];
            this.popStat = !this.popStat;
        },
        async getResult() {
            if(this.lect.length === 0)
            {
                this.$router.push('/admin/collectMng');
            }
            else
            {
                if(this.lpCd.length === 0)
                {
                    this.getConfirmSet('alert', '확인', '강의를 선택해주세요.');
                    return false;
                }

                try
                {
                    const params = {
                        itCd    : this.$route.params.itCd,
                        lpCdArr : this.lpCd
                    };

                    const res = await getAxiosData('https://data.wecls.com/inst/mainList/sendNoti', params);

                    if(res.status === 200)
                    {
                        this.getConfirmSet('alert', '완료', '해당 강의를 지원 제안 완료하였습니다.');
                        this.popStat = !this.popStat;
                    }
                }
                catch(error)
                {
                    console.log(error);
                    const check = error.response.data.message.includes('NotAuthorizedException');

                    if(error.response.data.status === 401 || check)
                    {
                        alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                        this.$store.dispatch('login/logout', 'organ');
                        this.$router.push('/organLogin');
                    }
                    else
                    {
                        this.getConfirmSet('alert', '에러', '에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                    }
                }
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.find {
    padding: 90px 0;

    .find-title{
        position: relative;

        h2{
            font-size: $font28;
            font-weight: 600;
            &.blue{
                color: $blue-color-lv-4;
            }
        }

        div {
            position: absolute;
            text-align: right;
            right: 0;
            bottom: 0;

            button+button {
                margin-left: 14px;
            }
        }
    }

    .margin {
        margin-top: 46px;
    }

    .margin2{
        margin-top: 80px;
    }

    table{
        width: 100%;
        font-size: $font20;
        text-align: left;
        border-spacing: 0;
        border-collapse: collapse;
        table-layout: fixed;

        font-weight: 600;

        td{
            @include ellipsis;
        }

        th, td{
            padding: 16px 0;
            border-top: 1px solid $line-color-lv-3;
            border-bottom: 1px solid $line-color-lv-3;

            ul{
                margin-left: 18px;
                li{
                    list-style: disc;
                    font-size: $font16;
                    font-weight: 400;

                    &.download{
                        color: $red-color-lv-5;
                    }
                }
            }
        }

        tr{
            &.find-padding{
                th, td{
                    padding: 46px 0;
                    font-size: $font16;
                    font-weight: 400;
                    color: $text-color-lv-0;

                    span{
                        display: block;
                        font-weight: 700;
                    }
                }
            }
        }

        th{
            color: $text-color-lv-5;
        }

        &.find-info{
            margin-bottom: 72px;

            tr{
                th, td{
                    border: 0;
                }
                td{
                    img{
                        width: 172px;
                        height: 232px;
                        border-radius: 8px;
                    }
                }
            }
        }
    }
}

.alert{
    &-wrapper{
        label{
            padding: 10px;
            border-radius: 4px;
            border: 1px solid $line-color-lv-2;
            display: flex;
            text-align: left;
            font-size: $font14;
            cursor: pointer;

            input[type="checkbox"]{
                width: 16px;
                height: 16px;
                margin-top: 2px;
                margin-right: 10px;
            }
        }

        label + label{
            margin-top: 10px;
        }
    }
}
</style>