<template>
    <MainHeader/>
    <div class="inner section">
        <div class="info-content">
            <h2>개인정보처리방침</h2>
        </div>
        <div v-html="term" class="editor"></div>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import MainHeader from "@/views/static/MainHeader.vue";
import Confirm from "@/components/alert/Confirm.vue";
import axios from 'axios';

export default {
    name: "PrivacyInfo",
    components: {MainHeader, Confirm},
    data() {
        return {
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            term                : ''
        }
    },
    created() {
        this.getTermsInfo(1);
    },
    methods : {
        getConfirmResult() {
            this.confirmStat = false;
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        async getTermsInfo() {
            try
            {
                const res = await axios.post('https://data.wecls.com/terms/getInfo');

                this.term = res.data['data']['terms3'];
            }
            catch(error)
            {
                console.log(error);
                this.getConfirmSet('alert', '에러', '이용약관 내용 불러오기에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.');
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.info-content {
    padding: 100px 0 100px 0;
    text-align: center;

    h2{
        color: $blue-color-lv-4;
        font-size: $font30;
    }
}
</style>