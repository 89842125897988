<template>
    <div class="prev">
        <label :for="inputId">{{ labelText }}</label>
        <div class="preview">
            <img v-if="imagePreview" :src="imagePreview" alt="Preview"/>
            <input type="file" id="setImage" name="setImage" @change="previewImage" accept="image/*"/>
            <label for="setImage">
                <img src="@/assets/images/btn-plus.png" alt="사진 등록" title="사진 등록"/>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: "PreviewImg",
    props     : {
        inputId       : String,
        labelText     : String,
        important     : Boolean,
        useTooltip    : Boolean,
        tooltipContent: String,
    },
    data() {
        return {
            imagePreview: null,
        };
    },
    methods: {
        previewImage(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    this.imagePreview = reader.result;
                };
                reader.readAsDataURL(file);
            } else {
                this.imagePreview = null;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.prev {
    display: block;
    position: relative;
    width: 100%;

    & > label {
        font-size: $font11;
        font-weight: 400;
        color: $text-color-lv-3;
        z-index: 1;
        background-color: $bk-color-lv-3;
    }

    .preview {
        position: relative;
        width: 95px;
        height: 95px;
        border-radius: 2px;
        background-color: $bk-color-lv-2;

        & > img {
            width: 95px;
            height: 95px;
        }

        input {
            display: none;
        }

        & > label {
            width: 29px;
            height: 29px;
            text-align: center;
            border-radius: 10px;
            background: rgba(169, 173, 168, .3);
            backdrop-filter: blur(10px);
            display: block;
            position: absolute;
            align-items: center;
            line-height: 42px;
            cursor: pointer;
            right: -7px;
            bottom: -7px;
        }
    }
}
</style>