import { getAxiosData } from "@/assets/js/function";
import store from "@/store";
import { useRouter } from "vue-router";

/**
 * @description 기관 평점 리스트
 */
export async function getGradeMngList(params)
{
    const router = useRouter();

    try 
    {
        let wait = 0;
        let comp = 0;

        const list = [];
        const res  = await getAxiosData("https://data.wecls.com/organ/dashboard/rateList", params);

        console.log(res);

        /** 점수 및 카운트 헤더 */
        const info = [
            { title : '전체 리뷰수', count : res.data['cnt']['totalCnt'] },
            { title : '평균 평점', count : res.data['cnt']['rateAvg'] },
            { title : '최고 평점', count : res.data['cnt']['maxRate'] },
            { title : '최소 평점', count : res.data['cnt']['minRate'] },
            { title : '미평가', count : res.data['cnt']['unanswered'] }
        ];

        store.commit("gradeMng/setInfo", info);

        res.data['list'].map((item) => {
            if(item.status === '')
            {
                wait++;
            }
            else
            {
                comp++;
            }

            list.push({
                lcCd    : item.lcCd,
                regDt   : item.regDt ? item.regDt.slice(0, -3) : '',
                name    : item.name,
                rate    : item.rate,
                status  : item.status,
                subject : item.subject,
                memo    : item.memo
            })
        });

        const cnt = [
            { title : '대기중인 평가', count : wait },
            { title : '평가 완료', count : comp },
            { title : '전체', count : list.length }
        ];

        store.commit('gradeMng/setCnt', cnt);
        store.commit("gradeMng/setAllList", list);
    }
    catch (error) 
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'organ');
            router.push('/organLogin');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}