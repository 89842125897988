<template>
    <div class="dashboard admin-div">
        <div class="inner section">
            <h2 class="notice-title">
                <span>편리하고 똑똑한 강사모집 솔루션 위클즈</span>
                <p>
                    <span class="orange">{{ ogNm }}</span>님의 영업 비서가 되어드릴게요!
                </p>
            </h2>

            <div class="dashboard-mng">
                <div class="flex">
                    <SortListCard title="공고관리" showTitle :sorts="announceManagement" class="w-half" />
                    <SortListCard title="강의관리" showTitle :sorts="announceManagement2" class="w-half margin-left" />
                </div>
                <div class="flex margin">
                    <SortListCard title="결제관리" showTitle :sorts="announceManagement3" class="w-half" />
                    <SortListCard title="리뷰관리" showTitle :sorts="announceManagement4" class="w-half margin-left" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SortListCard from "@/components/card/SortListCard.vue";

import { getCommas } from "@/assets/js/function";
import { mapState } from "vuex";
import { getDashAdmInfo } from "@/assets/js/admin/dashAdm";

export default {
    name: "AdmDashBoard",
    components: {SortListCard},
    created() {
        this.getCheck();
    },
    computed : {
        ...mapState('login', {
            ogNm    : state => state.login.ogNm
        }),
        ...mapState('dashAdm',{
            info : state => state.dashAdm.info
        }),
        announceManagement() {
            return [
                { title: "매칭신청", count: this.info.matchReady || "0", color: "default-color", url : "/admin/userMng" },
                { title: "매칭성공", count: this.info.matchComp || "0", color: "success-color", url : "/admin/userMng" },
                { title: "매칭실패", count: this.info.matchFailed || "0", color: "error-color", url : "/admin/userMng" },
            ];
        },
        announceManagement2() {
            return [
                { title: "강의진행", count: this.info.instReady || "0", color: "default-color", url : "/admin/schedule" },
                { title: "이번 주 강의", count: this.info.instWeek || "0", color: "success-color", url : "/admin/schedule" },
                { title: "강의완료", count: this.info.instEnd || "0", color: "error-color", url : "/admin/lectMng" },
            ];
        },
        announceManagement3() {
            return [
                { title: "정산대기", count: this.info.payReady || "0", color: "default-color", url : "/admin/paymentMng" },
                { title: "정산완료", count: this.info.payComp || "0", color: "success-color", url : "/admin/paymentMng" },
                { title: "총 정산금액", count: getCommas(Number(this.info.payPrice)) || "0", color: "error-color", url : "/admin/paymentMng" },
            ];
        },
        announceManagement4() {
            return [
                { title: "답변 전", count: this.info.rateReady || "0", color: "default-color", url : "/admin/gradeMng" },
                { title: "답변완료", count: this.info.rateComp || "0", color: "success-color", url : "/admin/gradeMng" },
                { title: "총 리뷰", count: this.info.rateTotal || "0", color: "error-color", url : "/admin/gradeMng" },
            ];
        },
    },
    methods : {
        getCheck() {
            getDashAdmInfo();
        }
    }
}
</script>

<style lang="scss" scoped>
.dashboard {
    padding: 90px 0;
    min-height: 100vh;

    .margin {
        margin-top: 46px;
    }

    .margin-left{
        margin-left: 20px;
    }

    .notice-title {
        font-size: $font28;
        font-weight: 700;
        margin-bottom: 46px;

        & > span{
            color: #616161;
            font-size: $font20;
            margin-bottom: 5px;
            display: block;
            font-weight: 400;
        }

        p {
            span {
                color: $blue-color-lv-4;
            }
        }
    }
}
</style>