<template>
    <div class="collect admin-div">
        <div class="inner section">
            <AdminCollecTable />

            <div class="button-wrapper">
                <!-- <BaseButton class="grey profile-button" buttonText="임시저장" @click="getConfirmSet('alert', '임시', '준비 중인 메뉴입니다.')"/> -->
                <BaseButton class="profile-button" @click="getSaveCheck" buttonText="저장하기" />
            </div>
        </div>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import AdminCollecTable from "@/views/admin/template/AdminCollecTable.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { getCollectInfo, getCollectDetailInfo } from "@/assets/js/admin/collect";
import { mapState } from 'vuex';
import axios from "axios";

export default {
    name: "AdmCollectMng",
    components: { BaseButton, AdminCollecTable, Confirm },
    created() {
        this.getInfo();
    },
    data() {
        return {
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            resultYn            : 'N',
            approval            : 'N'
        }
    },
    computed: {
        ...mapState('collect', {
            info : state => state.collect.info
        })
    },
    methods: {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.approval === 'Y')
            {
                this.saveCollect('N');
            }

            if(this.resultYn === 'Y')
            {
                const lpCd = this.$route.params.lpCd;

                if(lpCd)
                {
                    this.$router.go(-1);
                }
                else
                {
                    location.reload();
                }
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getInfo() {
            const lpCd   = this.$route.params.lpCd;
            const source = this.$route.params.source;

            if(lpCd)
            {
                const params = {
                    lpCd   : lpCd,
                    source : source
                };

                getCollectDetailInfo(params);
            }
            else
            {
                this.$store.commit('collect/setReset');
                getCollectInfo();
            }
        },
        getInputCheck() {
            if(this.info['subject'] === '')
            {
                this.getConfirmSet('alert', '확인', '강의 제목을 입력해주세요.');
                return false;
            }

            if(this.info['startTime'] === '' || this.info['endTime'] === '')
            {
                this.getConfirmSet('alert', '확인', '강의 시간을 입력하세요.');
                return false;
            }

            if(this.info['target'] === '')
            {
                this.getConfirmSet('alert', '확인', '수강 대상을 입력해주세요.');
                return false;
            }

            if(this.info['addr'] === '')
            {
                this.getConfirmSet('alert', '확인', '강의 장소를 검색해주세요.');
                return false;
            }

            if(this.info['chPerNm'] === '')
            {
                this.getConfirmSet('alert', '확인', '담당자명을 입력해주세요.');
                return false;
            }

            return true;
        },
        getSaveCheck() {
            if(!this.getInputCheck())
            {
                return false;
            }
            
            this.getConfirmSet('confirm', '확인', '저장하시겠습니까?');
            this.approval = 'Y';
        },
        async saveCollect(tmpYn) {
            const formData = new FormData();

            if(this.$route.params.lpCd)
            {
                formData.append('lcCd', this.info['lcCd']);
                formData.append('lpCd', this.$route.params.lpCd);
            }

            // formData.append('dataType',     this.$route.params.lpCd ? 'M' : 'E');
            formData.append('subject',      this.info['subject']);
            formData.append('lectPeriod',   this.info['startDt'] + ' ~ ' + this.info['endDt']);
            formData.append('area1',        this.info['area1']);
            formData.append('area2',        this.info['area2']);
            formData.append('amt',          this.info['amt']);
            formData.append('mainFieldCd',  this.info['mainFieldCd']);
            formData.append('fieldCd',      this.info['fieldCd']);
            formData.append('lectGb',       this.info['lectGb']);
            formData.append('lectTime',     this.info['startTime'] + ' ~ ' + this.info['endTime']);
            formData.append('lectRealDt',   this.info['lectRealDt']);
            formData.append('lectRealTime', this.info['lectRealTime']);
            formData.append('totalTime',    this.info['totalTime']);
            formData.append('estAmt',       this.info['estAmt'].replaceAll(',', ''));
            formData.append('target',       this.info['target']);
            formData.append('cnt',          1);
            formData.append('zip',          this.info['zip']);
            formData.append('addr',         this.info['addr']);
            formData.append('addrDetail',   this.info['addrDetail']);
            formData.append('keyword',      this.info['keyword']);
            formData.append('lectEq',       this.info['lectEq']);
            formData.append('career',       this.info['career']);
            formData.append('acadAbil',     this.info['acadAbil']);
            formData.append('regPeriod',    this.info['startRegDt'] + ' ~ ' + this.info['endRegDt']);
            formData.append('reqDoc',       this.info['reqDoc']);
            formData.append('etcDoc',       this.info['etcDoc']);
            formData.append('lectMemo',     this.info['lectMemo']);
            formData.append('chEmail',      this.info['chEmail']);
            formData.append('chPerTel',     this.info['chPerTel']);
            formData.append('chPerNm',      this.info['chPerNm']);

            if(this.info['img1']['file'] !== null)
            {
                formData.append('img1', this.info['img1']['file']);
            }

            if(this.info['img2']['file'] !== null)
            {
                formData.append('img2', this.info['img2']['file']);
            }

            if(this.info['img3']['file'] !== null)
            {
                formData.append('img3', this.info['img3']['file']);
            }

            if(this.info['img4']['file'] !== null)
            {
                formData.append('img4', this.info['img4']['file']);
            }

            const fileOther = [];

            this.info['fileOther'].forEach((item) => {
                if(item.file !== null)
                {
                    fileOther.push(item.file);
                }
            });

            if(fileOther.length !== 0)
            {
                fileOther.forEach((data, index) => {
                    formData.append(`fileOther[${index}]`, data);
                });
            }

            formData.append('delYn',    this.info['delYn']);

            if(this.info['fileGb'].length !== 0)
            {
                this.info['fileGb'].forEach((item, index) => {
                    formData.append(`fileGb[${index}]`, JSON.stringify(item));
                });
            }

            const formDataJson = {};

            formData.forEach((value, key) => {
                formDataJson[key] = value;
            });

            console.log(formDataJson);

            try 
            {
                const instance = axios.create({
                    withCredentials : true,
                    headers : {
                        'X-Subdomain'  : window.location.hostname
                    }
                });

                const res = await instance.post('https://data.wecls.com/organ/lectInfo/newData', formData);

                console.log(res);

                if(res.status === 200)
                {
                    this.resultYn = 'Y';
                    this.approval = 'N';
                    this.getConfirmSet('alert', '완료', (tmpYn === 'Y' ? '임시 저장되었습니다.' : '승인 요청되었습니다.'));
                }
                else
                {
                    this.getConfirmSet('alert', '에러', res.data.data);
                }
            } 
            catch (error) 
            {
                console.log(error);
                this.approval = 'N';
                
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'organ');
                    this.$router.push('/organLogin');
                }
                else if(error.response.data.status === 400)
                {
                    this.getConfirmSet('alert', '에러', error.response.data.data);
                }
                else
                {
                    this.getConfirmSet('alert', '에러', '에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        }
    },
    watch: {
        '$route'(to) {
            if (to.path === '/admin/collectMng') 
            {
                this.getInfo();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.collect {
    padding: 90px 0;

    .margin {
        margin-top: 46px;
    }

    .notice-title {
        font-size: $font28;
        font-weight: 700;
        margin-bottom: 46px;

        span {
            font-weight: 500;
            display: block;
        }
    }

    .button-wrapper {
        text-align: center;
        margin-top: 46px;

        .profile-button {
            padding: 10px;
            border-radius: 4px;
            width: 146px;
            display: inline-block;

            &.grey {
                margin-right: 16px;
            }
        }
    }
}
</style>