<template>
    <div class="join intro">
        <MobileBox title="비밀번호 찾기" showPrev>
            <InputBox labelText="이메일" :inputId="'id'" 
                :inputValue="info['id']['value']" :helperText="info['id']['text']" :class="info['id']['class']"
                placeholder="이메일을 입력해주세요." @input="getInputValSet"/>

            <BaseButton class="grey" buttonText="비밀번호 찾기" @click="getPwFind" />
        </MobileBox>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmResult"/>
</template>

<script>
import MobileBox from "@/components/common/MobileBox.vue";
import InputBox from "@/components/input/InputBox.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from 'vuex';
import { getAxiosData } from "@/assets/js/function";
import { getCertified } from "@/assets/js/certified";

export default {
    name: "JoinFirst",
    components: {BaseButton, InputBox, MobileBox, Confirm},
    computed: {
        ...mapState('pwFind', {
            certified   : state => state.pwFind.certified,
            info        : state => state.pwFind.info
        })
    },
    data() {
        return {
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            compYn              : 'N'
        }
    },
    methods: {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.certified['yn'] === 'Y' && this.compYn === 'N')
            {
                this.getIdCheck();
                this.compYn = 'Y';
            }
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getInputValSet(event) {
            const id    = event.target.id;
            const value = event.target.value;

            this.info[id]['value'] = value;
        },
        getInputCheck() {
            if(this.info['id']['value'] === '')
            {
                this.getConfirmSet('alert', '확인', '이메일을 입력하세요.');
                return false;
            }
            else
            {
                return true;
            }
        },
        async getPwFind() {
            if(this.getInputCheck())
            {
                getCertified().then(result => {
                    if(result.status)
                    {
                        this.$store.commit('pwFind/setCertifed', {
                            yn          : 'Y',
                            name        : result.data['name'],
                            tel         : result.data['phone'],
                            birthday    : result.data['birthday']
                        });

                        this.getConfirmSet('alert', '성공', result.msg);
                    }
                    else
                    {
                        this.getConfirmSet('alert', '실패', result.msg);
                    }
                }).catch(error => {
                    console.log(error);
                    this.getConfirmSet('alert', '에러', error.msg);
                });
            }
        },
        async getIdCheck() {
            const params = {
                type    : this.$route.query.type,
                id      : this.info['id']['value'],
                name    : this.certified['name'],
                birth   : this.certified['birthday']
                // birth   : this.certified['birthday'].replace(/-/g, '')
            };

            console.log(params);
            
            try
            {
                const res = await getAxiosData('https://data.wecls.com/searchInfo/checkId', params);

                console.log(res);

                if(res['status'] === 200)
                {
                    this.$router.push({ path: '/help/pwFind', query: { type: this.$route.query.type } })
                }
            }
            catch(error)
            {
                console.log(error);
                if(error.response.data['status'] === 400)
                {
                    this.getConfirmSet('alert', '확인', error.response.data['data']);
                }
                else
                {
                    this.getConfirmSet('alert', '에러', '비밀번호 찾기 도중 에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.join{
    .mobile-content{
        div + div, div + button {
            margin-top: 24px;
        }
    }
}
</style>