/**
 * @description 기관 유저 상세  vuex 모듈 관련
 * @author 정해원, @date 2023-11-29
 */

const userFindDetailAdmModule = {
    namespaced: true,
    state: {
        userFindDetailAdm: {
            info: []
        }
    },
    mutations: {
        setUserFindDetailAdmInfo(state, newUserFindDetailAdmInfo) {
            state.userFindDetailAdm.info = newUserFindDetailAdmInfo;
        },
    },
};


export default userFindDetailAdmModule;
