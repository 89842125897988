/**
 * @description 대쉬보드(기관) vuex 모듈 관련
 * @author 정해원, @date 2023-11-30
 */
const dashAdmModule = {
    namespaced: true,
    state: {
        dashAdm : {
            info : getDashAdmInfo() 
        }
    },
    mutations: {
        setInfo(state, newInfo) {
            state.dashAdm.info = newInfo;
        }
    }
};

function getDashAdmInfo() {
    return {
        matchReady  : 0, 
        matchComp   : 0,
        matchFailed : 0,
        instReady   : 0,
        instWeek    : 0,
        instEnd     : 0,
        payReady    : 0,
        payComp     : 0,
        payPrice    : 0,
        rateReady   : 0,
        rateComp    : 0,
        rateTotal   : 0
    };
}

export default dashAdmModule;
