<template>
    <div class="select" :class="inputClass">
        <label :for="selectId">
            {{ labelText }}
            <span v-if="important">*</span>
        </label>
        <v-select class="select-box" :id="selectId" :name="selectNm" @update:modelValue="onChange" :options="getOptions"  :value ="inputValue" @input = "onInput" label="name"/>
    </div>
</template>

<script>
import vSelect from "vue-select";

export default {
    name      : "SelectBox",
    components: {vSelect},
    props     : {
        getOptions: Array,
        inputValue : [String, Number, Object], 
        labelText : String,
        selectId  : String,
        selectNm  : String,
        important : Boolean,
    },
    computed  : {
        inputClass() {
            return {
                'small'  : this.style === 'small',
                'regular': this.style === 'regular',
                'medium' : this.style === 'medium',
            }
        }
    },

    methods : {
        onInput(value) {
            this.$emit('input', value);
        },

        onChange(newValue) {
            this.$emit('change', newValue);
        }

    }
}
</script>

<style lang="scss">
.select {
    display: block;
    color: $text-color-lv-1;
    position: relative;
    width: 100%;

    &.small {
        width: 140px;
    }

    &.regular {
        width: 200px;
    }

    &.medium {
        width: 460px;
    }

    label {
        position: absolute;
        font-size: $font11;
        font-weight: 400;
        color: $text-color-lv-3;
        z-index: 1;
        background-color: $bk-color-lv-3;
        left: 14px;
        top: -8px;
        padding: 1px 2px;

        span{
            color: $red-color-lv-2;
        }
    }

    .select-box {
        position: relative;
        border-radius: 4px;
        background-color: white;
        border: 1px solid #D1D1D1;
        font-size: 14px;
        height: 38px;

        .vs__dropdown-toggle {
            position: relative;
            width: 100%;
            padding: 0 12px;
            display: flex;
            align-items: center;
            height: 38px;
            cursor: pointer;
        }

        .vs__search {
            display: none;
        }

        .vs__clear {
            display: none;
        }

        .vs__dropdown-menu {
            position: absolute;
            top: calc(100% - 2px);
            left: -1px;
            cursor: pointer;
            background-color: #ffffff;
            z-index: 3;
            width: calc(100% + 2px);
            border-radius: 0 0 4px 4px;
            border: 1px solid #D1D1D1;

            .vs__dropdown-option {
                padding: 8px 16px;

                &:hover {
                    background-color: $bk-color-lv-2;
                }
            }
        }

        .vs__actions {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 12px;
            width: 16px;
        }
    }
}
</style>