<template>
    <div v-for="(item, index) in rows" :key="index" class="card">
        <div class="card-left">
            <TagSpan2 :class="item['color']" :content="item['stNm']" />
        </div>

        <div class="card-center" @click="getDetail(item['lcCd'], item['source'])">
            <h2>{{ item['subject'] }}</h2>
            <ul>
                <li>등록자 : {{ item['name'] }}</li>
                <li>강의 기간 : {{ item['lectPeriod'] }}</li>
                <li>장소 : {{ item['mainNm'] }} - {{ item['subNm'] }} </li>
                <li>총 강의시간 : {{ item['totalTime'] }} </li>
                <li>강의금액 : {{ getCommas(item['amt']) }}</li>
            </ul>
        </div>

        <div class="card-right">
            <div class="button-wrapper" v-if="item['stNm'] !== '마감완료'">
                <button :type="'button'" v-if="item['stNm'] !== '대기'" @click="getCollectInfo(item['lpCd'])">공고 보기</button>
                <button :type="'button'" @click="getCollect(item['lpCd'], item['source'])">공고 수정</button>
                <button :type="'button'" v-if="item['stNm'] !== '반려'" @click="getLectDown(item['lsCd'])">공고 마감</button>
            </div>
            <p>🔍 조회수 {{ item['viewCnt'] }}</p>
            <p>모집기간: {{ item['regPeriod'] }}</p>
            <CountList :counts="item['cnt']" />
        </div>
    </div>
</template>

<script>
import TagSpan2 from "@/components/common/TagSpan2.vue";
import CountList from "@/components/card/CountList.vue";
import { getCommas } from "@/assets/js/function"

export default {
    name: "UserMngCard",
    components: {CountList, TagSpan2},
    props: {
        title : String,
        rows  : Array
    },
    methods : {
        getDetail(lcCd, gubun){
            if(gubun !== 'lectListTmp')
            {
                this.$router.push({ path : `/admin/userDetail/${lcCd}`});
            }
        },
        getCommas(val) {
            return getCommas(Number(val));
        },
        getLectDown(lsCd) {
            this.$emit('get-lect', lsCd);
        },
        getCollectInfo(lpCd) {
            this.$router.push({ path : `/admin/collectInfo`, query: { lpCd: lpCd } });
        },
        getCollect(lpCd, source) {
            this.$emit('get-update', lpCd, source);
        }
    }
}
</script>
<style lang="scss" scoped>
.card {
    background-color: $bk-color-lv-3;
    padding: 34px;
    display: flex;
    justify-content: space-between;
    font-size: $font14;
    border-bottom: 1px solid $line-color-lv-4;
    cursor: pointer;

    &-left{
        width: 75px;
    }

    &-center{
        width: 800px;

        h2{
            font-size: $font20;
            margin-bottom: 15px;
            margin-top: -5px;
        }

        ul {
            li{
                color: $line-color-lv-0;
            }
            li + li{
                margin-top: 8px;
            }
        }
    }

    &-right{
        width: 330px;
        text-align: right;
        font-size: $font14;
        font-weight: 400;

        p{
            color: $text-color-lv-6;
            margin-bottom: 12px;
        }

        button{
            color: $text-color-lv-0;
        }

        .button-wrapper{
            margin-bottom: 12px;
        }

        button + button{
            margin-left: 16px;
        }
    }
}
</style>