/**
 * @description 일정 vuex 모듈 관련
 * @author 정해원, @date 2023-11-22
 */
const dateUserModule = {
    namespaced  : true,
    state       : {
        dateInfo : {
            info : getDateInfo()   /* 일정 정보 */
        }
    },
    mutations   : {
        setDateInfo(state, newDateInfo) {
            state.dateInfo.info = newDateInfo ? newDateInfo : getDateInfo;
        }
    },
   
};

function getDateInfo()
{
    return {
        value : '',
        text  : '',
        class : ''
    }
}
  
export default dateUserModule;