<template>
    <div class="top-slider" data-aos="fade-left">
        <div class="slide-wrapper">
            <div class="slide">
                <img src="../../assets/images/portfolio_1.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_2.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_3.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_4.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_5.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_6.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_7.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_8.jpg" alt="위클즈 고객사 이미지">
            </div>
        </div>
        <div class="slide-wrapper">
            <div class="slide">
                <img src="../../assets/images/portfolio_1.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_2.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_3.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_4.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_5.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_6.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_7.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_8.jpg" alt="위클즈 고객사 이미지">
            </div>
        </div>
        <div class="slide-wrapper">
            <div class="slide">
                <img src="../../assets/images/portfolio_1.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_2.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_3.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_4.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_5.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_6.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_7.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_8.jpg" alt="위클즈 고객사 이미지">
            </div>
        </div>
    </div>

    <div class="bottom-slider" data-aos="fade-right">
        <div class="slide-wrapper">
            <div class="slide">
                <img src="../../assets/images/portfolio_9.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_10.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_11.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_12.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_13.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_14.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_15.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_16.jpg" alt="위클즈 고객사 이미지">
            </div>
        </div>
        <div class="slide-wrapper">
            <div class="slide">
                <img src="../../assets/images/portfolio_9.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_10.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_11.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_12.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_13.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_14.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_15.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_16.jpg" alt="위클즈 고객사 이미지">
            </div>
        </div>
        <div class="slide-wrapper">
            <div class="slide">
                <img src="../../assets/images/portfolio_9.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_10.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_11.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_12.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_13.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_14.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_15.jpg" alt="위클즈 고객사 이미지">
            </div>
            <div class="slide">
                <img src="../../assets/images/portfolio_16.jpg" alt="위클즈 고객사 이미지">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MainSlide"
}
</script>

<style lang="scss" scoped>
.top-slider, .bottom-slider {
    width: 100%;
    display: flex;
    overflow-x: hidden;

    & > .slide-wrapper {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;

        .slide {
            margin-right: 15px;

            img {
                width: 400px;
                height: 225px;
                object-fit: cover;
                border-radius: 8px;
            }
        }
    }
}

.top-slider {
    margin-top: 80px;
    justify-content: flex-start;


    & > .slide-wrapper {
        animation: move-rtl 40s linear infinite;
    }
}

.bottom-slider {
    justify-content: flex-end;
    margin-top: 10px;

    & > .slide-wrapper {
        animation: move-ltr 40s linear infinite;
    }
}

@keyframes move-rtl {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

@keyframes move-ltr {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}
</style>