<template>
    <div className="mobile">
        <div className="mobile-header">
            <img src="@/assets/images/btn-prev.png" alt="" title="" v-if="showPrev" @click="goToPreviousPage"/>
            <h2>
                {{ title }}
            </h2>
        </div>
        <div className="mobile-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name   : "MobileBox",
    props  : {
        title   : String,
        showPrev: Boolean,
    },
    methods: {
        goToPreviousPage() {
            const url = window.location.pathname;

            if(url === '/login' || url === '/organLogin')
            {
                this.$router.push({ path : '/' });
            }
            else
            {
                window.history.back();
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.mobile {
    width: 360px;
    height: 736px;
    padding: 14px 16px;
    border-radius: 30px;
    @include shadow16;
    background-color: $bk-color-lv-3;
    position: relative;
    margin: 0 auto;
    overflow: hidden;

    &-header {
        padding-bottom: 14px;
    }

    &-content {
        padding-top: 20px;
        @include hiddenScroll;
        height: calc(100% - 40px);
        padding-bottom: 100px;
    }

    img {
        position: absolute;
        left: 16px;
        cursor: pointer;
        width: 20px;
    }

    h2 {
        text-align: center;
        font-size: $font14;
        font-weight: 700;
        color: $text-color-lv-1;
    }
}
</style>