import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

import router from "@/router";

const data = store.state.login.login;

if(data['inst'] === 'N' || data['organ'] === 'N')
{
    store.dispatch('login/initializeState');
}

createApp(App).use(router).use(store).mount('#app')
