import { getAxiosData, getCommas } from "@/assets/js/function";
import store from "@/store";
import { useRouter } from "vue-router";

/**
 * @description 기관 강의 관리 리스트
 */
export async function getLectMngList(params)
{
    const router = useRouter();

    try 
    {
        let ing    = 0;
        let comp   = 0;
        // let cancle = 0;

        const res  = await getAxiosData("https://data.wecls.com/organ/dashboard/lectManage", params);

        console.log(res);

        /** 점수 및 카운트 헤더 */
        const headers = [
            { title : '진행 강의', count : res.data['cnt']['ing'] },
            { title : '완료 강의', count : res.data['cnt']['comp'] },
            { title : '취소 강의', count : res.data['cnt']['cancle'] },
            { title : '전체', count : res.data['cnt']['total'] }
        ];

        store.commit("admLectMng/setHeaders", headers);

        const list = [];

        res.data['list'].map((item) => {
            if(item.compYn === 'E')
            {
                ing++;
            }
            else if(item.compYn === 'Y')
            {
                comp++;
            }
            const date          = item.lectPeriod;
            const lectPeriod    = date.split('~');
            
            const startDate = lectPeriod[0];
            const endDate   = lectPeriod[1];

            list.push({
                lcCd        : item.lcCd,
                mtCd        : item.mtCd,
                regDt       : item.regDt,
                lectStart   : startDate,
                lectEnd     : endDate,
                name        : item.name,
                subject     : item.subject,
                amt         : getCommas(Number(item.amt)),
                compYn      : item.compYn,
                state       : item.compYn === 'E' ? 'Y' : '강의완료'
            })
        });

        console.log(list);

        const filters = [
            { title : '진행 강의', count : ing },
            { title : '완료 강의', count : comp },
            { title : '전체', count : list.length }
        ];

        store.commit('admLectMng/setFilters', filters);
        store.commit("admLectMng/setAllList", list);
    }
    catch (error) 
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'organ');
            router.push('/organLogin');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}