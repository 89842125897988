import { getDate, getConvertDate } from "@/assets/js/function";

const date = getDate('mon');
/**
 * @description 정산내역 vuex 모듈 관련
 * @author 정해원, @date 2023-11-22 작성 / @author 김원명, @date 2023-12-13 수정
 */
const calcModule = {
    namespaced  : true,
    state       : {
        calc : {
            search : {
                type    : { name : '이달', value : 'mon' },
                startDt : getConvertDate(date['st'], 'yyyy-mm-dd'),
                endDt   : getConvertDate(date['et'], 'yyyy-mm-dd')
            },
            amt    : [
                { title : '정산대기 금액', count : 0 },
                { title : '정산신청 금액', count : 0 },
                { title : '정산완료 금액', count : 0 },
                { title : '총 금액', count : 0 },
            ],
            cnt    : [
                { title : '정산 대기', count : 0 },
                { title : '정산 신청', count : 0 },
                { title : '정산 완료', count : 0 },
                { title : '전체', count : 0 }
            ],
            allList : [],
            list    : [],
            info    : getCalcInfo()
        }
    },
    mutations : {
        setAmt(state, newAmt)
        {
            state.calc.amt = newAmt
        },
        setCnt(state, newCnt)
        {
            state.calc.cnt = newCnt
        },
        setAllList(state, newAllList)
        {
            state.calc.allList = newAllList;
            state.calc.list    = newAllList;
        },
        setList(state, newList)
        {
            state.calc.list = newList;
        },
        setInfo(state, newInfo)
        {
            state.calc.info = newInfo;
        }
    }
};

function getCalcInfo()
{
    return {
        lcInfo      : [],
        calcGb      : 'N',
        totalAmt    : '',
        charge      : '',
        chargeAmt   : '',
        calcAmt     : '',
        calcMean    : 'A',
        bankNm      : '',
        bankAcc     : '',
        bankNum     : '',
        docEnum     : 'T',
        einNm       : '',
        einNum      : '',
        einType     : '',
        einItem     : '',
        ceoNm       : '',
        addr        : '',
        email       : '',
        stat        : 'N'
    }
}
  
export default calcModule;