import { getDate, getConvertDate } from "@/assets/js/function";

const date = getDate('mon');

/**
 * @description 매칭관리 vuex 모듈 관련
 * @author 정해원, @date 2023-11-22 / @author 김원명, @date 2023-12-14
 */
const matchingModule = {
    namespaced  : true,
    state       : {
        matching : {
            search  : {
                type    : { name : '이달', value : 'mon' },
                startDt : getConvertDate(date['st'], 'yyyy-mm-dd'),
                endDt   : getConvertDate(date['et'], 'yyyy-mm-dd')
            },
            info    : [
                { title : '매칭 신청', count : 0 },
                { title : '매칭 평가중', count : 0 },
                { title : '매칭 성공', count : 0 },
                { title : '매칭 실패', count : 0 },
                { title : '매칭 성공율', count : 0 }
            ],
            cnt    : [
                { title : '매칭 신청', count : 0, state : 'E' },
                { title : '매칭 평가중', count : 0, state : 'I' },
                { title : '매칭 성공', count : 0, state : 'Y' },
                { title : '매칭 실패', count : 0, state : 'N' },
                { title : '전체', count : 0, state : '' }
            ],
            allList : [],
            list    : []
        }
    },
    mutations : {
        setInfo(state, newInfo)
        {
            state.matching.info = newInfo;
        },
        setCnt(state, newCnt)
        {
            state.matching.cnt = newCnt
        },
        setAllList(state, newAllList)
        {
            state.matching.allList = newAllList;
            state.matching.list    = newAllList;
        },
        setList(state, newList)
        {
            state.matching.list = newList;
        }
    }
};
  
export default matchingModule;