<template>
    <LabelTable title="모집 공고" class="mt-50">
        <tr>
            <th>강의 관련 사진</th>
            <td>
                <RepImage imgTitle="대표사진" :inputId="'img1'" :imageView="info['img1']['file']" @file-selected="getFileSelect" @file-cleared="getFileDelete" />
                <RepImage imgTitle="2" :inputId="'img2'" :imageView="info['img2']['file']" @file-selected="getFileSelect" @file-cleared="getFileDelete" />
                <RepImage imgTitle="3" :inputId="'img3'" :imageView="info['img3']['file']" @file-selected="getFileSelect" @file-cleared="getFileDelete" />
                <RepImage imgTitle="4" :inputId="'img4'" :imageView="info['img4']['file']" @file-selected="getFileSelect" @file-cleared="getFileDelete" />
            </td>
        </tr>
        <tr>
            <th>강의 제목<span class="important">*</span></th>
            <td>
                <InputBox :inputType="'text'" :inputId="'subject'" :inputValue="info['subject']" class="medium" placeholder="예: 놀이로 배우는 유아교육의 비밀" @input="getInputVal" />
            </td>
        </tr>
        <tr>
            <th>강의 분야<span class="important">*</span></th>
            <td>
                <select v-model="getMainFieldCd" id="mainFieldCd" @change="getSelect">
                    <option v-for="(item, index) in mainLecture" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
                &nbsp;
                <select v-model="info['fieldCd']" id="fieldCd" @change="getSelect">
                    <option v-for="(item, index) in getFieldCd" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </td>
        </tr>
        <tr>
            <th>강의 구분</th>
            <td>
                <select v-model="info['lectGb']" id="lectGb" @change="getSelect">
                    <option :value="'O'">온라인</option>
                    <option :value="'L'">오프라인</option>
                </select>
            </td>
        </tr>
        <tr>
            <th>강의 기간<span class="important">*</span></th>
            <td>
                <DateBox class="regular" :inputId="'startDt'" :inputValue="info['startDt']" :selectFunction="getSelectDate" :timePicker="false" :disableDate="true"/>
                <DateBox class="regular" :inputId="'endDt'" :inputValue="info['endDt']" :selectFunction="getSelectDate" :timePicker="false" :disableDate="true"/>
            </td>
        </tr>
        <tr>
            <th>강의 시간<span class="important">*</span></th>
            <td>
                <InputBox :inputId="'startTime'" inputType="time" :inputValue="info['startTime']" class="small inline-block" @input="getInputVal" />
                <!-- <vue-timepicker v-model="info['startTime']" format="HH:mm" @input="getTimeVal('startTime')" /> -->
                &nbsp;
                <InputBox :inputId="'endTime'" inputType="time" :inputValue="info['endTime']" class="small inline-block" @input="getInputVal" />
                <!-- <vue-timepicker v-model="info['endTime']" format="HH:mm" @input="getTimeVal('endTime')" /> -->
            </td>
        </tr>
        <tr>
            <th>강의 총 금액</th>
            <td>
                <InputBox :inputId="'amt'" inputType="number" :inputValue="info['amt']" class="small inline-block" placeholder="예: 1,000,000" @input="getInputVal" />
                <span class="ml-10">원</span>
            </td>
        </tr>
        <tr>
            <th>실제 강의 일 수</th>
            <td>
                <InputBox :inputId="'lectRealDt'" inputType="number" :inputValue="info['lectRealDt']" class="small inline-block" placeholder="" @input="getInputVal" />
                <span class="ml-10">일</span>
            </td>
        </tr>

        <tr>
            <th>실제 강의 시간</th>
            <td>
                <InputBox :inputId="'lectRealTime'" inputType="number" :inputValue="info['lectRealTime']" class="small inline-block" placeholder="" @input="getInputVal" />
                <span class="ml-10">시간</span>
            </td>
        </tr>

        <tr>
            <th>총 강의 시간</th>
            <td>
                <InputBox :inputId="'totalTime'" inputType="text" :inputValue="info['totalTime']" class="small inline-block" readonly />
                <span class="ml-10">시간</span>
            </td>
        </tr>

        <tr>
            <th>예상 시급 (자동계산)</th>
            <td>
                <InputBox :inputId="'estAmt'" inputType="text" :inputValue="info['estAmt']" class="small inline-block" readonly />
                <span class="ml-10">원</span>
            </td>
        </tr>

        <tr>
            <th>수강 대상<span class="important">*</span></th>
            <td>
                <InputBox :inputId="'target'" inputType="text" :inputValue="info['target']" class="medium" placeholder="예: 유아" @input="getInputVal" />
            </td>
        </tr>

        <tr>
            <th>지역 키워드</th>
            <td>
                <div class="flex">
                    <select v-model="getArea1" id="area1" @change="getSelect">
                        <template v-for="(item, index) in area1">
                            <option v-if="item.value !== ''" :key="index" :value="item.value">
                                {{ item.name }}
                            </option>
                        </template>
                        <!-- <option v-for="(item, index) in area1" :key="index" :value="item['value']">
                            {{ item['name'] }}
                        </option> -->
                    </select>
                    &nbsp;
                    <select v-model="info['area2']" id="area2" @change="getSelect">
                        <option v-for="(item, index) in getArea2" :key="index" :value="item['value']">
                            {{ item['name'] }}
                        </option>
                    </select>
                </div>
            </td>
        </tr>
        <tr>
            <th>강의 장소<span class="important">*</span></th>
            <td>
                <div class="input-button">
                    <InputBox :inputId="'zip'" inputType="text" :inputValue="info['zip']" class="" readonly />
                    <BaseButton buttonText="검색" @click="getAddr" class="small ml-10" />
                </div>
                <InputBox :inputId="'addr'" inputType="text" :inputValue="info['addr']" class="medium" readonly />
                <InputBox :inputId="'addrDetail'" inputType="text" :inputValue="info['addrDetail']" class="medium" placeholder="상세주소를 입력하세요. " @input="getInputVal" />
            </td>
        </tr>
        <tr>
            <th>제공장비</th>
            <td>
                <InputBox :inputId="'lectEq'" inputType="text" :inputValue="info['lectEq']" class="medium" placeholder="예: 노트북, 빔 프로젝터" @input="getInputVal" />
            </td>
        </tr>
        <tr>
            <th>키워드</th>
            <td>
                <InputBox :inputId="'keyword'" inputType="text" :inputValue="info['keyword']" class="medium" placeholder="예: 키워드" @input="getInputVal" />
            </td>
        </tr>

        <tr>
            <th>경력 구분<span class="important">*</span></th>
            <td>
                <select v-model="getCareer" id="career" @change="getSelect">
                    <option v-for="(item, index) in career" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </td>
        </tr>
        <tr>
            <th>학력 구분<span class="important">*</span></th>
            <td>
                <select v-model="getAcadAbil" id="acadAbil" @change="getSelect">
                    <option v-for="(item, index) in edu" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </td>
        </tr>
        <tr>
            <th>접수 기간<span class="important">*</span></th>
            <td>
                <div class="flex">
                    <DateBox class="rmdeium" :inputId="'startRegDt'" :inputValue="info['startRegDt']" :selectFunction="getSelectDate" :timePicker="false" :disableDate="true"/>
                    <DateBox class="rmdeium ml-10" :inputId="'endRegDt'" :inputValue="info['endRegDt']" :selectFunction="getSelectDate" :timePicker="false" :disableDate="true" />
                </div>
            </td>
        </tr>
    </LabelTable>

    <LabelTable title="제출서류" class="mt-10">
        <tr>
            <th>필수 제출서류</th>
            <td>
                <InputBox :inputId="'reqDoc'" inputType="text" :inputValue="info['reqDoc']" class="medium" placeholder="예: 경력증명서, 범죄이력사실확인서, 주민등록 초본" @input="getInputVal" />
            </td>
        </tr>

        <tr>
            <th>기타사항</th>
            <td>
                <InputBox :inputId="'etcDoc'" inputType="text" :inputValue="info['etcDoc']" class="medium" placeholder="예: 경력증명서, 범죄이력사실확인서, 주민등록 초본" @input="getInputVal" />
            </td>
        </tr>
    </LabelTable>

    <LabelTable title="제출서류 양식 업로드" class="mt-10">
        <tr>
            <th>제출서류 양식 업로드</th>
            <td>
                <div v-for="(file, index) in info['fileOther']" :key="index">
                    <FileBox :fileBtn="'파일첨부'" :inputId="'file' + index" :fileNm="file['text']" :greyButton="'삭제하기'"
                        @file-selected="getDataFileSelect" @file-cleared="getDataFile('D', index)" />
                </div>

                <button type="button" @click="getDataFile('A')" class="blue-button">파일 추가 생성</button>
            </td>
        </tr>
    </LabelTable>

    <div class="editor-box">
        <div>
            <NoticeBox simpleTitle="모집 공고 입력" showNotice showSimpleTitle class="blue3" />
            <TextEditor v-model="info.lectMemo" />
        </div>
    </div>

    <LabelTable title="공고 담당자 / 기업정보" class="mt-10">
        <tr>
            <th>기관명</th>
            <td>
                <InputBox inputType="text" :inputValue="info['ogNm']" class="medium" placeholder="예: 노트북, 빔 프로젝터" readonly />
            </td>
        </tr>
        <tr>
            <th>위치</th>
            <td>
                <InputBox inputType="text" :inputValue="info['location']" class="medium" placeholder="예: 노트북, 빔 프로젝터" readonly />
            </td>
        </tr>
        <tr>
            <th>이메일</th>
            <td>
                <InputBox :inputId="'chEmail'" inputType="text" :inputValue="info['chEmail']" class="medium" placeholder="예: admin@gmail.com" @input="getInputVal" />
            </td>
        </tr>
        <tr>
            <th>담당자 전화번호</th>
            <td>
                <InputBox :inputId="'chPerTel'" inputType="number" :inputValue="info['chPerTel']" class="medium" placeholder="예: 010-0000-0000" @input="getInputVal" />
            </td>
        </tr>
        <tr>
            <th>담당자명<span class="important">*</span></th>
            <td>
                <InputBox :inputId="'chPerNm'" inputType="text" :inputValue="info['chPerNm']" class="medium" @input="getInputVal" />
            </td>
        </tr>
    </LabelTable>
</template>

<script>
import LabelTable from "@/components/table/LabelTable.vue";
import RepImage from "@/components/input/RepImage.vue";
import InputBox from "@/components/input/InputBox.vue";
import DateBox from "@/components/input/DateBox.vue";

// import VueTimepicker from 'vue3-timepicker'
// import 'vue3-timepicker/dist/VueTimepicker.css'
import BaseButton from "@/components/button/BaseButton.vue";
import FileBox from "@/components/input/FileBox.vue";
import NoticeBox from "@/components/common/NoticeBox.vue";
import TextEditor from "@/components/common/TextEditor.vue";

import { getCommas } from "@/assets/js/function";
import { mapState } from 'vuex';

export default {
    name: "AdminCollecTable",
    components: { TextEditor, NoticeBox, FileBox, BaseButton, DateBox, InputBox, RepImage, LabelTable },
    data() {
        return {
            uploadFileGb : '',
            deleteFileGb : ''
        }
    },
    computed: {
        ...mapState('data', {
            mainLecture : state => state.data.mainLecture,
            lecture     : state => state.data.lecture,
            area1       : state => state.data.area1,
            area2       : state => state.data.area2,
            career      : state => state.data.career,
            edu         : state => state.data.edu
        }),
        ...mapState('collect', {
            info : state => state.collect.info
        }),
        getMainFieldCd() {
            if(this.info['mainFieldCd'] === '')
            {
                this.$store.commit('collect/setMainFieldCd', this.mainLecture[0]['value']);
                return this.mainLecture[0]['value'];
            }
            else
            {
                return this.info['mainFieldCd'];
            }
        },
        getFieldCd() {
            const mainFieldCd = this.info['mainFieldCd'] === '' ? this.mainLecture[0]['value'] : this.info['mainFieldCd'];
            const fieldCd     = this.lecture.filter(item => item.key === mainFieldCd);

            if(fieldCd.length && !this.$route.params.lpCd)
            {
                this.$store.commit('collect/setFieldCd', fieldCd[0]['value']);
            }

            return fieldCd;
        },
        getArea1() {
            if(this.info['area1'] === '')
            {
                this.$store.commit('collect/setArea1', this.area1[1]['value']);
                return this.area1[1]['value'];
            }
            else
            {
                return this.info['area1'];
            }
        },
        getArea2() {
            const area1 = this.info['area1'] === '' ? this.area1[0]['value'] : this.info['area1'];
            const area2 = this.area2.filter(item => item.key === area1);

            if(area2.length && !this.$route.params.lpCd)
            {
                this.$store.commit('collect/setArea2', area2[0]['value']);
            }

            return area2;
        },
        getCareer() {
            if(this.info['career'] === '')
            {
                this.$store.commit('collect/setSelect', { gb : 'career', value : this.career[0]['value'] });
                return this.career[0]['value'];
            }
            else
            {
                return this.info['career'];
            }
        },
        getAcadAbil() {
            if(this.info['acadAbil'] === '')
            {
                this.$store.commit('collect/setSelect', { gb : 'acadAbil', value : this.edu[0]['value'] });
                return this.edu[0]['value'];
            }
            else
            {
                return this.info['acadAbil'];
            }
        }
    },
    methods: {
        getFileSelect({inputId, file}) {
            let num = 1;
            this.uploadFileGb = 'P';

            switch(inputId)
            {
                case 'img2':
                    num = 2;
                break;
                case 'img3':
                    num = 3;
                break;
                case 'img4':
                    num = 4;
                break;
            }

            const fileCheck = this.info['fileGb'].indexOf(item => item.fileGb === this.uploadFileGb && item.fileNo === num);

            if(fileCheck !== -1)
            {
                this.info['fileGb'].splice(fileCheck, 1);
            }

            if(this.info['fileGb'].length === 0)
            {
                this.info['delYn'] = 'N';
            }

            this.$store.commit('collect/updateFile', { fileType: inputId, file: file });
        },
        getFileDelete(inputId) {
            if(this.info[inputId]['file'] !== null )
            {
                if(this.info[inputId]['gb'] === 'Y')
                {
                    this.info['delYn'] = 'Y';
                }

                let num = 1;
                this.deleteFileGb = 'I';

                switch(inputId)
                {
                    case 'img2':
                        num = 2;
                    break;
                    case 'img3':
                        num = 3;
                    break;
                    case 'img4':
                        num = 4;
                    break;
                }

                let gbCheck = this.info['fileGb'].some(item => item.fileGb === this.deleteFileGb && item.no === num);

                if (!gbCheck) 
                {
                    this.info['fileGb'].push({fileGb : this.deleteFileGb, fileNo : num});
                }
            }

            this.$store.commit('collect/clearFile', inputId);
        },
        getInputVal(event) {
            const id    = event.target.id;
            const value = event.target.value;

            this.info[id] = value;

            if(id === 'amt' || id === 'lectRealDt' || id === 'lectRealTime')
            {
                const amt  = this.info['amt'];
                const day  = this.info['lectRealDt'];
                const time = this.info['lectRealTime'];

                if (Number(day) !== 0 && Number(time) !== 0) 
                {
                    const totalTime = day * time;
                    const estAmt    = amt / totalTime;

                    this.info['totalTime'] = totalTime;
                    this.info['estAmt']    = getCommas(Math.ceil(estAmt));
                } 
                else 
                {
                    this.info['totalTime'] = 0;
                    this.info['estAmt']    = 0;
                }
            }
        },
        getSelect(){
            const id    = event.target.id;
            const value = event.target.value;

            this.info[id] = value;
        },
        getSelectDate(date, id) {
            this.info[id] = date;
        },
        getDataFile(gb, index = 0) {
            this.$store.commit('collect/getDataFile', { gb : gb, index : index });
        },
        getDataFileSelect({inputId, file}) {
            const index = Number(inputId.replace('file', ''));

            this.$store.commit('collect/getDataFileSelect', { index: index, file: file });
        },
        getAddr() {
            new window.daum.Postcode({
                oncomplete: (data) => {
                    this.info['zip']  = data.zonecode;
                    this.info['addr'] = data.address;

                    const inputElement = document.getElementById('addrDetail');
                    inputElement.focus();
                }
            }).open();
        }
    }
}
</script>

<style lang="scss" scoped>
table {
    tr {
        td {
            position: relative;

            .input-button {
                .input {
                    width: 385px;
                }

                .small{
                    background-color: $button-color-lv-3;
                }
            }

            .blue-button {
                padding: 8px 20px;
                border-radius: 2px;
                height: 40px;
                background-color: $bk-color-lv-3;
                font-size: $font14;
                color: $blue-color-lv-4;
                border: 1px solid $blue-color-lv-4;
                cursor: pointer;
                min-width: 80px;
                margin-left: 20px;
                display: flex;
                align-items: center;
                font-weight: 500;
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }
    }
}

select {
    width: 140px;
    height: 38px;
    border: 1px solid #E5E5EC;
    font-size: 0.875rem;
    font-weight: 400;
    border-radius: 4px;
}

.editor-box {
    border-radius: 4px;
    background-color: $bk-color-lv-3;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.20);
    padding: 12px 20px 100px 20px;
    margin-top: 10px;

    .editor {
        margin-top: 46px;
    }
}
</style>