<template>
    <div class="side-header inner">
        <ul>
            <li v-for="(item, index) in items" :key="index">
                <router-link :to="item.to" :class="{ 'active' : isLinkActive(item.to) }">
                    {{ item.title }}
                </router-link>
            </li>
        </ul>
        <router-link to="/user/mypage/logout" class="logout">로그아웃</router-link>
    </div>
</template>

<script>
export default {
    name: "SectionHeader",
    props: {
        items: Array,
    },
    methods: {
        isLinkActive(to) {
            return this.$route.path === to;
        }
    }
}
</script>

<style lang="scss" scoped>
.side-header{
    position: relative;
    ul{
        li{
            display: inline-block;
            font-size: $font18;
            font-weight: 400;
            color: $text-color-lv-0;

            a{
                display: block;
                padding: 18px 0;
                position: relative;

                &::after {
                    display: block;
                    content: '';
                    width: 0;
                    height: 3px;
                    background-color: $blue-color-lv-4;
                    left: 0;
                    bottom: 0;
                    position: absolute;
                    transition: width .2s ease;
                }

                &:hover, &.active {
                    &::after {
                        width: 100%;
                    }
                }
            }
        }

        li + li {
            margin-left: 16px;
        }
    }

    .logout{
        @include my-auto;
        right: 0;
        font-size: $font14;
        color: $text-color-lv-4;
    }
}
</style>