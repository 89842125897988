<template>
    <div class="card" v-for="(item, index) in list" :key="index">
        <div class="card-left">
            <p>{{ item['name'] }}</p>
            <p>나이: 만</p>
            <p>{{ item['career'] }}</p>
            <FavoriteButton />
        </div>

        <div class="card-center">
            <TagSpan content="위클즈 인증 🚀" class="green" />
            <h2>{{ item['appeal'] }}</h2>
            <ul>
                <li>강의분야: {{ item['fieldNm'] }}</li>
                <li>대표경력: {{ item['repCareer'] }}</li>
                <li>최종학력: {{ item['school'] }}, {{ item['major'] }}</li>
                <!-- <li>증빙자료: {{ item['fileCnt'] }}건</li>
                <li>매칭건수: {{ item['matchCnt'] }}건</li>
                <li>평점: {{ item['rate'] }}</li> -->
            </ul>
        </div>

        <div class="card-right">
            <router-link :to="{ path : `/admin/userFind/detail/${item['itCd']}` }">이력서 상세보기</router-link>
            <input type="checkbox" class="ml-15" :value="item['itCd']" @change="getItCd"/>
        </div>
    </div>
</template>

<script>
import TagSpan from "@/components/common/TagSpan.vue";
import FavoriteButton from "@/components/button/FavoriteButton.vue";
import { mapState } from 'vuex';

export default {
    name: "UserDetailCard",
    components: {FavoriteButton, TagSpan},
    props: {
        list    : Array
    },
    computed: {
        ...mapState('userMngDetail', {
            itCd  : state => state.userMngDetail.itCd
        })
    },
    methods: {
        getItCd() {
            const checkStat = event.target.checked;
            const value     = event.target.value;

            if (!this.itCd.includes(value)) 
            {
                if(checkStat)
                {
                    this.itCd.push(value);
                }
            }
            else 
            {
                if(!checkStat)
                {
                    let index = this.itCd.indexOf('c');
                    this.itCd.splice(index, 1);
                }
            }

            console.log(this.itCd);
        }
    }
}
</script>

<style lang="scss" scoped>
.card {
    background-color: $bk-color-lv-3;
    padding: 34px;
    display: flex;
    justify-content: space-between;
    font-size: $font14;
    border-bottom: 1px solid $line-color-lv-4;

    &-left {
        width: 100px;

        p+p {
            margin-top: 8px;
        }
    }

    &-center {
        width: 850px;

        h2 {
            font-size: $font20;
            margin: 20px 0 10px 0;
        }

        ul {
            li {
                color: $line-color-lv-0;
            }

            li+li {
                margin-top: 8px;
            }
        }
    }

    &-right {
        width: 140px;
        display: flex;
        align-items: center;

        a {
            padding: 10px 14px;
            border: 1px solid $line-color-lv-4;
            border-radius: 2rem;
            font-size: $font12;
            font-weight: 700;
            color: $text-color-lv-4;
        }

        input{
            width: 14px;
            height: 14px;
        }
    }
}

</style>