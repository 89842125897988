import { getAxiosData } from "@/assets/js/function";
import store from "@/store";
import { useRouter } from "vue-router";

/**
 * @description 채팅방 리스트 
 */
export async function getRooms() 
{
    const router = useRouter();

    try 
    {
        const res = await getAxiosData("https://data.wecls.com/chatMessage/chatList");

        console.log(res);

        const rooms = res.data.list.map((item) => {
            return {
                roomId      : item.roomId,
                roomName    : item.receiverNm+' 기관',
                unreadCount : item.unreadCnt,
                lastMessage : {
                    _id         : item.receiverCd,
                    content     : item.lastMessage,
                    senderId    : item.receiverCd,
                    username    : item.receiverNm,
                    timestamp   : item.lastTimestamp,
                    saved       : true,
                    distributed : false,
                    seen        : false,
                    new         : true
                },
                users       : [{ _id: res.data.user, username: '' }, { _id: item.receiverCd, username: item.receiverNm }]
            }
        });

        store.commit("login/setItCd", res.data.user);
        store.commit("userMsg/setRooms", rooms);
    } 
    catch (error) 
    {
        console.error(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'inst');
            router.push('/login');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}

export async function getMessages(params) 
{
    const router = useRouter();

    try 
    {
        const res = await getAxiosData("https://data.wecls.com/chatMessage/getChatMessage", params);

        console.log(res);

        const messages = res.data.reverse().map((item) => {
            let files = [];

            if(item['imageUrls'].length > 0)
            {
                files = item['imageUrls'].map((file, fIndex) => {
                    return {
                        name     : `file_${fIndex}`,
                        type     : 'image/gif, image/jpeg, image/jpg, image/png',
                        saved    : true,
                        url      : file,
                        preview  : file
                    }
                });
            }

            return {
                _id         : item['ikey'],
                content     : item['message'],
                senderId    : item['senderCd'],
                timestamp   : item['regDt'],
                files       : files
            }
        });

        console.log(messages);

        store.commit("userMsg/setMessages", messages);
    } 
    catch (error) 
    {
        console.error(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'inst');
            router.push('/login');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}