<template>
    <div class="star-rating">
        <div class="star" v-for="index in 5" :key="index" @click="check(index)">
            <font-awesome-icon icon="fas fa-solid fa-star" class="yellow" v-if="index < score" />
            <font-awesome-icon icon="fas fa-solid fa-star" class="grey" v-if="index >= score" />
        </div>
    </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas, far, fab);

export default {
    name: "StarRating",
    components: {FontAwesomeIcon},
    data() {
        return{
            score: 0,
        }
    },
    methods: {
        check(index) {
            this.score = index + 1;
            this.$emit('updateScore', (this.score - 1));
        }
    }
}
</script>

<style lang="scss" scoped>
.star-rating {
    display: flex;
    width: 100px;
    margin: 16px auto;

    svg{
        cursor: pointer;
    }

    .grey {
        color: #E0E0E0;
    }

    .yellow {
        color: #FFBE00;
    }
}
</style>