<template>
    <div class="detail">
        <p class="border" />
        <div class="inner section">
            <div>
                <img src="@/assets/images/icon-clap.png" alt="clap" title="clap" />
                <div>
                    <h2>매칭이 완료되었습니다.</h2>
                    <p>공고문 제목 : 조향사와 함께하는 나만의 향수 만들기 체험</p>
                </div>
                <div class="button-wrapper">
                    <BaseButton buttonText="목록보기" class="grey" @click="getList" />
                    <BaseButton buttonText="지원내역보기" class="grey" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from "@/components/button/BaseButton.vue";

export default {
    name: "MainDetailClear",
    components: {BaseButton},
    methods: {
        getList() {
            this.$router.push({path : `/user/main`});
        }
    }
}
</script>

<style lang="scss" scoped>
.detail {
    padding: 90px 0;

    .section{
        & > div{
            position: fixed;
            text-align: center;
            margin: 0 auto;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            div{
                margin-top: 42px;

                h2{
                    font-size: $font32;
                    font-weight: 700;
                    color: $red-color-lv-5;
                }

                p{
                    margin-top: 14px;
                    color: $text-color-lv-0;
                    font-weight: 500;
                    font-size: $font24;
                }
            }

            .button-wrapper{
                text-align: center;

                button{
                    display: inline-block;
                    width: 160px;
                    padding: 10px;

                    &:first-child{
                        margin-right: 8px;
                    }

                    &:last-child{
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}
</style>