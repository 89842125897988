/**
 * @description 기관 일정 vuex 모듈 관련
 * @author 정해원, @date 2023-11-30
 */
const dateAdmModule = {
    namespaced  : true,
    state       : {
        dateAdmInfo : {
            info : getDateAdmInfo()   /* 일정 정보 */
        }
    },
    mutations   : {
        setDateAdmInfo(state, newDateAdmInfo) {
            state.dateAdmInfo.info = newDateAdmInfo ? newDateAdmInfo : getDateAdmInfo;
        }
    },
   
};

function getDateAdmInfo(){
    return [];
}
  
export default dateAdmModule;