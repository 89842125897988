<template>
    <div class="find admin-div">
        <div class="inner section">
            <div class="select-wrapper">
             
                <Select :selectId="'area'" :selectOptions="getArea" :selectVal="search['area']" :selectChange="getSelectSet" class="small" :labelText="'지역'" />
                    &nbsp;
                <Select :selectId="'career'" :selectOptions="getCareer" :selectVal="search['career']" :selectChange="getSelectSet" class="small" :labelText="'경력'" />
                    &nbsp;
                <Select :selectId="'lecture'" :selectOptions="getLecture" :selectVal="search['lecture']" :selectChange="getSelectSet" class="small" :labelText="'강의분야'" />
                    &nbsp;
            </div>
            <div class="card-wrapper">
                <DetailListCard v-for="(item, index) in list" :key="index" :data="item"/>
            </div>
        </div>
    </div>
    <infinite-scroll @infinite="getLoad" :distance="scrollDistance" :infinite-scroll-disabled="loading">
        <div v-if="loading">로딩 중...</div>
    </infinite-scroll>
</template>

<script>
import InfiniteScroll from 'vue-infinite-scroll';
import DetailListCard from "@/views/admin/template/DetailListCard.vue";
import Select from "@/components/input/Select.vue";

import { mapState } from 'vuex';
import { getUserFindAdmList } from  "@/assets/js/admin/userfind";

export default {
    name: "AdmUserFind",
    components: { InfiniteScroll, DetailListCard, Select },
    data() {
        return {
            loading         : false,
            scrollDistance  : 10,
            testList        : [
                { name : '1' },
                { name : '2' },
                { name : '3' },
                { name : '4' },
                { name : '5' },
                { name : '6' },
                { name : '7' },
                { name : '8' },
                { name : '9' },
                { name : '10' },
            ]
        }
    },
    created() {
        this.getStatCheck()
    },
    computed : {
        ...mapState('data', {
            area    : state => state.data.area1,
            career  : state => state.data.career,
            lecture : state => state.data.lecture
        }),
        ...mapState('userFindAdm', {
            search : state => state.userFindAdm.search,
            list   : state => state.userFindAdm.list
        }),
        getArea() {
            return [{ name: '전체', value: '' }, ...this.area];
        },
        getCareer() {
            return [{ name: '전체', value: '' }, ...this.career];
        },
        getLecture() {
            return [{ name: '전체', value: '' }, ...this.lecture];
        }
    },
    methods : {
        getLoad() {
            console.log('test');
        },
        getStatCheck(){
            const params = {
                area    : this.search['area']['value'],
                career  : this.search['career']['value'],
                fieldCd : this.search['lecture']['value'],
                order   : 'ASC'
            };

            getUserFindAdmList(params);
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            const params = {
                area    : this.search['area']['value'],
                career  : this.search['career']['value'],
                fieldCd : this.search['lecture']['value'],
                order   : 'ASC'
            };

            getUserFindAdmList(params);
        }
    }
}
</script>

<style lang="scss" scoped>
.find {
    padding: 90px 0;

    .margin {
        margin-top: 46px;
    }

    .select-wrapper{
        display: flex;
        padding-bottom: 24px;
        border-bottom: 1px solid $line-color-lv-3;
        margin-bottom: 30px;

        .select + .select{
            margin-left: 10px;
        }
    }

    .card-wrapper{
        padding: 10px 10px 100px 10px;
        border-radius: 8px;
        background-color: $bk-color-lv-2;
    }
}
</style>