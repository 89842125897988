<template>
    <div class="card">
        <div class="card-left">
            <p>{{ data['name'] }}</p>
            <!-- <p>나이: {{ list['age'] }}만</p> -->
            <p>경력 {{ data['career'] }}</p>
        </div>
        <div class="card-center">
            <TagSpan content="위클즈 인증 🚀" class="green" />
            <h2>{{ data['appeal'] }}</h2>
            <ul>
                <li>강의분야: {{ data['fieldNm'] }}</li>
                <li>대표경력: {{ data['repCareer'] }}</li>
                <li>최종학력: {{ data['school'] }} {{ data['major'] }}</li>
                <!-- <li>증빙자료: {{ data['fileCnt'] }}</li>
                <li>매칭건수: {{ data['matchCnt'] }}</li>
                <li>평점: {{ data['rate'] }}</li> -->
            </ul>
        </div>
        <div class="card-right">
            <router-link :to="{ path : `/admin/userFind/detail/${data['itCd']}` }">
                이력서 상세보기
            </router-link>
        </div>
    </div>
</template>

<script>
import TagSpan from "@/components/common/TagSpan.vue";

export default {
    name: "DetailListCard",
    components: { TagSpan },
    props: {
        data : Object
    }
}
</script>

<style lang="scss" scoped>
.card {
    background-color: $bk-color-lv-3;
    padding: 34px;
    display: flex;
    justify-content: space-between;
    font-size: $font14;
    border-bottom: 1px solid $line-color-lv-4;

    &-left {
        width: 100px;

        p+p {
            margin-top: 8px;
        }
    }

    &-center {
        width: 880px;

        h2 {
            font-size: $font20;
            margin: 20px 0 10px 0;
        }

        ul {
            li {
                color: $line-color-lv-0;
            }

            li+li {
                margin-top: 8px;
            }
        }
    }

    &-right {
        width: 111px;
        display: flex;
        align-items: center;

        a {
            padding: 10px 14px;
            border: 1px solid $line-color-lv-4;
            border-radius: 2rem;
            font-size: $font12;
            font-weight: 700;
            color: $text-color-lv-4;
        }
    }
}
</style>