<template>
  <div class="slider">
    <div class="slides">
      <img v-for="(image, index) in images" :key="index" :src="getImage(image.url, index)" :class="{ active: index === currentIndex }"/>
    </div>
    <div class="slider-dots">
      <span v-for="(image, index) in images" :key="index" @click="goToImage(index)"
            :class="{ active: index === currentIndex }"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SliderImage",
  props: {
    images : Array
  },
  data() {
    return {
        currentIndex : 0,
        imgPath      : 'https://elasticbeanstalk-ap-northeast-2-627549176645.s3.ap-northeast-2.amazonaws.com/wecls/lect'
    }
  },
  methods: {
    goToImage(index) {
        this.currentIndex = index;
    },
    getImage(imgUrl, index) {
        if(this.currentIndex === index){
            return `${this.imgPath}/${imgUrl}`;
        }
    }
  }
}
</script>

<style lang="scss" scoped>
.slider {
  width: 374px;
  height: 374px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 50px;
  position: relative;

  &-dots{
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 10px;
    margin: 0 auto;
    text-align: center;

    span{
      &.active{
        background-color: $blue-color-lv-4;
      }
      background-color: $text-color-lv-4;
      width: 8px;
      height: 8px;
      border-radius: 2rem;
      display: inline-block;
      margin-left: 10px;
      cursor: pointer;
      & + &{
        margin-left: 10px;
      }
    }
  }

  .slides{
    width: 100%;
    height: 100%;

    img{
      &.active{
        display: block;
      }
    }

    img {
      display: none;
      width: 100%;
      height: 100%;
    }
  }
}
</style>