<template>
    <div class="input flex">
        <input type="checkbox" :id="inputId" :name="inputNm" :checked="checkStat"/>
        <label :for="inputId">
            <span></span> {{ labelText }}
        </label>
        <img v-if="showDetail" src="@/assets/images/arrow-down.png" alt="자세히 보기" title="자세히 보기" @click="getDetail"/>
    </div>
</template>

<script>
export default {
    name : "CheckBox",
    props: {
        inputId   : String,
        inputNm   : String,
        checkStat : Boolean,
        labelText : String,
        showDetail: Boolean,
    },
    methods : {
        getDetail() {
            this.$emit('get-detail', this.inputId);
        }
    }
}
</script>

<style lang="scss" scoped>
.input {
    align-items: center;
    padding: 4px 0;
    position: relative;
    cursor: pointer;

    input {
        display: none;

        & + label{
            display: flex;
            align-items: center;
            span {
                width: 16px;
                height: 16px;
                border: 1px solid $line-color-lv-1;
                border-radius: 50%;
                display: inline-block;
                margin-right: 10px;
            }
        }

        &:checked {
            & + label{
                span {
                    border: 5px solid $blue-color-lv-4;
                }
            }
        }
    }

    label {
        cursor: pointer;
        font-size: $font12;
        padding-left: 7px;
        font-weight: 500;
        color: $text-color-lv-5;
        position: relative;
        top: 0;
        left: 0;
    }

    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotateZ(270deg);
        right: 0;
        width: 10px;
    }
}
</style>