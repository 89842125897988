<template>
    <button type="button" class="button" :class="buttonClass">
        <img v-if="iconBefore" src="@/assets/images/icon-setting.png"/>
        {{ buttonText }}
    </button>
</template>

<script>
export default {
    name    : "BaseButton",
    props   : {
        buttonText: String,
        size      : String,
        theme     : String,
        iconBefore: Boolean,
    },
    computed: {
        buttonClass() {
            return {
                'small': this.size === 'small',
                'light': this.theme === 'light',
                'grey' : this.theme === 'grey',
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.button {
    border-radius: 4px;
    background-color: $blue-color-lv-4;
    color: $bk-color-lv-3;
    font-size: $font14;
    font-weight: 600;
    padding: 5px 12px;
    display: flex;
    align-items: center;

    &.small {
        padding: 8px 20px;
        font-size: $font14;
        font-weight: 500;
        height: 38px;
    }

    &.small2{
        padding: 8px 20px;
        font-size: $font14;
        border-radius: 2px;
        font-weight: 500;
        height: 38px;
        display: inline-block;
    }

    &.light {
        background-color: $bk-color-lv-3;
        border: 1px solid $line-color-lv-3;
        color: $text-color-lv-2;
    }

    &.grey {
        width: 100%;
        text-align: center;
        display: block;
        padding: 14px 36px;
        border-radius: 2px;
        background-color: $line-color-lv-2;
        color: $text-color-lv-2;
        font-weight: 700;
    }

    &.deep{
        background-color: #5C5C5C;
        color: $bk-color-lv-3;
        width: 100%;
        text-align: center;
        display: block;
        padding: 14px 36px;
        font-weight: 700;
        border-radius: 2px;
    }

    &.blue {
        width: 100%;
        text-align: center;
        display: block;
        padding: 14px 36px;
        border-radius: 2px;
        font-weight: 700;
        color: white;
    }

    &.orange{
        padding: 10px;
        border-radius: 2px;
        background-color: $red-color-lv-5;
        width: 220px;
        color: $bk-color-lv-3;
        font-weight: 500;
        text-align: center;
        display: block;
    }

    img {
        margin-right: 4px;
        width: 12px;
    }
}
</style>