import { getAxiosData } from "@/assets/js/function";
import store from '@/store';
import { useRouter } from "vue-router";

/**
 *  @description 기관 대시보드
 */

export async function getDashAdmInfo()
{
    const router = useRouter();
    try 
    {
        const res = await getAxiosData('https://data.wecls.com/organ/dashboard/getMain');

        const aggregatedData = res.data.reduce((acc, segment) => ({ ...acc, ...segment }), {});
    
        store.commit('dashAdm/setInfo', aggregatedData);
    }
    catch(error)
    {
        console.error(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'organ');
            router.push('/organLogin');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}