<template>
    <div class="join intro">
        <MobileBox title="비밀번호 찾기" showPrev>
            <InputBox labelText="비밀번호" :inputId="'pw'" :inputType="'password'"
                :inputValue="info['pw']['value']" :helperText="info['pw']['text']" :class="info['pw']['class']"
                placeholder="비밀번호를 입력해주세요" @input="getInputValSet"/>

            <InputBox labelText="비밀번호 확인" :inputId="'pwChk'" :inputType="'password'"
                :inputValue="info['pwChk']['value']" :helperText="info['pwChk']['text']" :class="info['pwChk']['class']"
                placeholder="비밀번호를 입력해주세요" @input="getInputValSet"/>

            <BaseButton class="grey" buttonText="비밀번호 변경" @click="getPwChange" />
        </MobileBox>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import MobileBox from "@/components/common/MobileBox.vue";
import InputBox from "@/components/input/InputBox.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { getAxiosData } from "@/assets/js/function";
import { mapState } from 'vuex';

export default {
    name: "JoinFirst",
    components: {BaseButton, InputBox, MobileBox, Confirm},
    computed: {
        ...mapState('pwFind', {
            certified   : state => state.pwFind.certified,
            info        : state => state.pwFind.info
        })
    },
    data() {
        return {
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            pwComplete          : 'N'
        }
    },
    created() {
        this.getCertifiedCheck();
    },
    methods: {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.pwComplete === 'Y')
            {
                this.$store.commit('pwFind/setReset');

                if(this.$route.query.type === 'I')
                {
                    this.$router.push({path : `/login`});
                }
                else
                {
                    this.$router.push({path : `/organLogin`});                    
                }
            }

            if(this.confirmType === 'confirm')
            {
                this.getPwComplete();
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getCertifiedCheck() {
            if(this.certified === 'N')
            {
                this.$router.go(-1);
            }
        },
        getInputValSet(event) {
            const id    = event.target.id;
            const value = event.target.value;

            this.info[id]['value'] = value;
        },
        getInputCheck() {
            let stat = true;

            stat = stat && this.getValueCheck('pw', '비밀번호를 입력하세요.');
            stat = stat && this.getValueCheck('pwChk', '비밀번호 확인란을 입력하세요.');
            stat = stat && this.getPwCheck();
            stat = stat && this.getPwRecomm();

            return stat;
        },
        getValueCheck(id, text){
            if(this.info[id]['value'] === '')
            {
                this.getConfirmSet('alert', '확인', text);
                return false;
            }

            return true;
        },
        getPwCheck() {
            if(this.info['pw']['value'] !== this.info['pwChk']['value']) 
            {
                this.getConfirmSet('alert', '확인', '비밀번호가 일치하지 않습니다.');
                return false;
            }
            
            return true;
        },
        getPwRecomm() {
            if(this.info['pw']['value'].length < 6) 
            {
                this.getConfirmSet('alert', '확인', '비밀번호는 최소 6자리 이상입니다.');
                return false;
            }

            return true;
        },
        getPwChange() {
            const inputCheck = this.getInputCheck();

            if(!inputCheck)
            {
                return false;
            }

            this.getConfirmSet('confirm', '비밀번호 변경', '비밀번호를 변경하시겠습니까?');
        },
        async getPwComplete()
        {
            const params = {
                id    : this.info['id']['value'],
                newPw : this.info['pw']['value'],
                type  : this.$route.query.type
            };

            console.log(params);

            try
            {
                const res = await getAxiosData('https://data.wecls.com/searchInfo/resetPw', params);
                

                if(res['status'] === 200)
                {
                    this.pwComplete = 'Y';
                    this.getConfirmSet('alert', '완료', '비밀번호 변경이 완료되었습니다.');
                }
                else
                {
                    this.pwComplete = 'N';
                    this.getConfirmSet('alert', '실패', '비밀번호 변경 도중 문제가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
            catch(error)
            {
                console.log(error);
                this.pwComplete = 'N';
                this.getConfirmSet('alert', '실패', '비밀번호 변경 도중 문제가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.join{
    .mobile-content{
        div + div, div + button {
            margin-top: 24px;
        }
    }
}
</style>