<template>
    <button
            class="favorite-button"
            :class="{ 'active': isFavorite }"
            @click="toggleFavorite"
    >
        <font-awesome-icon icon="fas fa-solid fa-star" />
        즐겨찾기
    </button>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    name: "FavoriteButton",
    components: {
        FontAwesomeIcon,
    },
    data() {
        return {
            isFavorite: false,
        };
    },
    methods: {
        toggleFavorite() {
            this.isFavorite = !this.isFavorite;
        },
    },
}
</script>

<style lang="scss" scoped>
.favorite-button {
    cursor: pointer;
    border: 1px solid $line-color-lv-4;
    border-radius: 4px;
    padding: 5px 14px;
    background-color: #fff;
    display: flex;
    align-items: center;
    font-size: $font12;
    font-weight: 700;
    color: $line-color-lv-0;
    margin-top: 14px;

    &.active{
        .svg-inline--fa{
            color: $blue-color-lv-4;
        }
    }

    .svg-inline--fa {
        margin-right: 8px;
        color: $bk-color-lv-6;
    }
}
</style>