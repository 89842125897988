<template>
    <div class="dashboard">
        <MypageHeader />
        <p class="section-border"/>
        <div class="inner section">
            <h2 class="notice-title">
                <span>편리하고 똑똑한 매칭 솔루션 위클즈</span>
                <p>
                    <span class="orange">{{ itNm }}</span>님의 영업 비서가 되어드릴게요!
                </p>
            </h2>

            <div class="dashboard-mng">
                <div class="flex">
                    <SortListCard title="매칭관리" showTitle :sorts="announceManagement" class="w-half" />
                    <SortListCard title="수업관리" showTitle :sorts="announceManagement2" class="w-half margin-left" />
                </div>
                <div class="flex margin">
                    <SortListCard title="정산관리" showTitle :sorts="announceManagement3" class="w-half" />
                    <SortListCard title="리뷰관리" showTitle :sorts="announceManagement4" class="w-half margin-left" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MypageHeader from "@/views/user/mypage/MypageHeader.vue";
import SortListCard from "@/components/card/SortListCard.vue";

import { getCommas } from "@/assets/js/function";
import { mapState } from "vuex";
import { getDashInfo } from "@/assets/js/user/dash"

export default {
    name: "DashBoard",
    components: {SortListCard, MypageHeader},
    computed : {
        ...mapState('login', {
            itNm : state => state.login.itNm
        }),
        ...mapState('dash', {
            info : state => state.dashInfo
        }),
        announceManagement() {
            return [
                { title: "매칭신청", count: this.info.matchReady || "0", color: "default-color", url : "/user/mypage/matchMng" },
                { title: "매칭성공", count: this.info.matchComp || "0", color: "success-color", url : "/user/mypage/matchMng" },
                { title: "매칭불발", count: this.info.matchFailed || "0", color: "error-color", url : "/user/mypage/matchMng" },
            ];
        },
        announceManagement2() {
            return [
                { title: "강의진행",     count: this.info.instReady || "0", color: "default-color", url : "/user/mypage/calender" },
                { title: "이번 주 강의", count: this.info.instWeek || "0", color: "success-color", url : "/user/mypage/calender" },
                { title: "강의완료",     count: this.info.instEnd || "0", color: "error-color", url : "/user/mypage/lectMng" },
            ];
        },
        announceManagement3() {
            return [
                { title: "정산대기",    count: this.info.payReady || "0", color: "default-color", url : "/user/mypage/calcMng" },
                { title: "정산신청",    count: this.info.payComp || "0", color: "success-color", url : "/user/mypage/calcMng" },
                { title: "총 정산금액", count: getCommas(Number(this.info.payPrice)) || "0", color: "error-color", url : "/user/mypage/calcMng" },
            ];
        },
        announceManagement4() {
            return [
                { title: "답변 전",     count: this.info.rateReady || "0", color: "default-color", url : "/user/mypage/gradeMng" },
                { title: "답변완료",    count: this.info.rateComp || "0", color: "success-color", url : "/user/mypage/gradeMng" },
                { title: "총 리뷰",     count: this.info.rateTotal || "0", color: "error-color", url : "/user/mypage/gradeMng" },
            ];
        },
    },
    created(){
        getDashInfo()
    }
}
</script>

<style lang="scss" scoped>
.dashboard {
    padding: 90px 0;
    min-height: 100vh;

    .margin {
        margin-top: 46px;
    }

    .margin-left{
        margin-left: 20px;
    }

    .notice-title {
        font-size: $font28;
        font-weight: 700;
        margin-bottom: 46px;

        & > span{
            color: #616161;
            font-size: $font20;
            margin-bottom: 5px;
            display: block;
            font-weight: 400;
        }

        p {
            span {
                color: $blue-color-lv-4;
            }
        }
    }
}
</style>