<template>
    <footer class="footer">
        <div class="inner">
            <h3>위클즈</h3>
            <p>상호 : 주식회사 위클즈 ㅣ 대표 : 김지은 ㅣ 사업자등록번호 : 545-87-01070 | 통신판매신고번호 : 제 2020-부산북구-0343호 <br>주소: 부산광역시 북구 금곡대로616번길 126, 성주빌딩 5층 <br>copyright ©
                Another Day right reserved.</p>
            <div class="flex">
                <p>문의전화 : 051-925-1561</p>
                <p>이메일 : wecls@naver.com</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "MainFooter"
}
</script>

<style lang="scss" scoped>
.footer {
    width: 100%;
    background-color: $text-color-lv-0;
    padding: 60px 0;

    .inner {
        width: 923px;
        margin: 0 auto;
        font-weight: 400;
        color: $bk-color-lv-5;

        h3 {
            color: $text-color-lv-5;
            font-weight: 700;
        }

        & > p {
            margin: 21px 0;
        }

        .flex{
            p{
                &:last-child{
                    margin-left: 49px;
                }
            }
        }
    }
}
</style>