<template>
    <ul class="card" data-aos="fade-up">
        <li>
            <h2>
                쉽고 빠른 매칭
                <p>효율적이고 빠르게<br>강사를 찾아보세요.</p>
            </h2>
        </li>
        <li>
            <h2>
                높은 수준 기관 강의
                <p>전문성있는 강사들의<br>질 좋은 교육을 경험해보세요.</p>
            </h2>
        </li>
        <li>
            <h2>
                업무효율 상승
                <p>빠른 서류 발급과 처리로<br>도움이 되어드릴게요.</p>
            </h2>
        </li>
    </ul>
</template>

<script>
export default {
    name: "Image2Card"
}
</script>

<style lang="scss" scoped>
.card {
    width: 100%;
    display: flex;

    li {
        width: 100%;
        height: 630px;
        border-radius: 6px;
        @include shadow8;
        overflow: hidden;
        text-align: justify;
        transition: all .3s ease;
        position: relative;
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: center;
        background-color: $text-color-lv-0;

        &:first-child{
            background-image: url("@/assets/images/image_4.jpg");
        }

        &:nth-child(2){
            background-image: url("@/assets/images/image_5.jpg");
        }

        &:last-child{
            background-image: url("@/assets/images/image_6.jpg");
        }

        &:hover {
            transition: all .3s ease;

            &:first-child{
                background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url("@/assets/images/image_4.jpg");
            }

            &:nth-child(2){
                background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url("@/assets/images/image_5.jpg");
            }

            &:last-child{
                background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url("@/assets/images/image_6.jpg");
            }

            h2{
                display: block;
                filter: brightness(100%);
                z-index: 3;
            }
        }

        &:nth-child(2) {
            margin: 0 20px;
        }

        h2 {
            font-size: $font30;
            text-align: center;
            font-weight: 700;
            transition: all .3s ease;
            color: $bk-color-lv-3;
            margin: 0 auto;
            display: none;

            p {
                font-size: $font20;
                font-weight: 400;
                margin-top: 10px;
            }
        }
    }
}
</style>