<template>
    <div class="card" :class="bgClass">
        <div class="inner">
            <div class="card-header">
                <h2>{{ title }}</h2>
                <p v-if="!type" class="blue" @click="getMore">더보기</p>
            </div>

            <div class="card-content">
                <router-link v-for="(item, index) in lists" :key="index" :to="{ path: '/user/main/detail', query: { lpCd: item['lpCd'] } }">
                    <div class="card-image">
                        <img :src="filePath+item['fileNm']" alt="" title="" />
                        <font-awesome-icon icon="fas fa-solid fa-star" :class="item['likeCd'] !== '' ? 'active' : false" @click.stop.prevent="getFavorite(item['lcCd'])" />
                    </div>
                    <h5 class="mt-14 mb-9">
                        {{ item['subject'] }}
                    </h5>
                    <p>
                        {{ item['ogNm'] }}
                    </p>
                    <p>
                        기간: {{ item['lectPeriod'] }}
                    </p>
                    <p class="mt-12 mb-12">
                        지역: {{ item['lectArea1'] }}
                    </p>
                    <p>
                        강의금액: {{ getCommas(item['amt']) }}
                    </p>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { getAxiosData, getCommas } from "@/assets/js/function"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
    name : "TableListCard",
    components: { FontAwesomeIcon },
    props: {
        title: String,
        lists: Array,
        style: String
    },
    data() {
        return {
            filePath : 'https://elasticbeanstalk-ap-northeast-2-627549176645.s3.ap-northeast-2.amazonaws.com/wecls/thumb/lect/',
            type     : this.$route.params.type
        }
    },
    computed: {
        bgClass() {
            return {
                'grey' : this.style === 'grey',
            }
        }
    },
    methods : {
        getCommas(val) {
            return getCommas(Number(val));
        },
        async getFavorite(lcCd) {
            try
            {
                const res = await getAxiosData('https://data.wecls.com/organ/mainList/likeState', {lcCd : lcCd});

                if(res.status === 200)
                {
                    this.$emit('get-list');
                }
            }
            catch(error)
            {
                console.log(error);
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'inst');
                    this.$router.push('/login');
                }
                else
                {
                    alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
                    history.back();
                }
            }
        },
        getMore() {
            this.$emit('get-more');
        }
    }
}
</script>

<style lang="scss" scoped>
.card{

    &.grey{
        .inner{
            background-color: $bk-color-lv-4;
        }
    }
    .inner{
        padding: 90px 0;
    }

    &-header{
        width: 100%;
        border-bottom: 1px solid $line-color-lv-3;
        padding: 16px 0;
        display: flex;
        align-items: center;

        h2{
            font-size: $font24;
            font-weight: 500;
            width: 50%;
        }

        p{
            width: 50%;
            text-align: right;
            font-size: $font18;
            cursor: pointer;
        }
    }

    &-content{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        a{
            display: block;
            width: 25%;
            margin-top: 46px;
            padding: 10px;

            &:hover{
                h5, p:last-child{
                    color: $blue-color-lv-4;
                }
            }

            .card-image{
                width: 100%;
                height: 230px;
                border-radius: 8px;
                background-color: black;
                position: relative;
                overflow: hidden;

                svg{
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    color: $text-color-lv-3;

                    &.active{
                        color: $blue-color-lv-4;
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 8px;
                }
            }

            h5{
                font-size: $font18;
                font-weight: 700;
                transition: color .1s ease-in;
            }

            p{
                font-size: $font14;
                transition: color .1s ease-in;

                &:nth-child(odd){
                    color: $text-color-lv-3;
                }
            }
        }
    }
}
</style>