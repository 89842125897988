<template>
    <button type="button" class="button">
        {{ buttonText }}
    </button>
</template>

<script>
export default {
    name: "RoundButton",
    props: {
        buttonText: String,
    },
}
</script>

<style lang="scss" scoped>
.button{
    border-radius: 30px;
    background-color: $blue-color-lv-4;
    padding: 16px 26px;
    align-items: center;
    display: inline-block;
    color: $bk-color-lv-3;
    font-size: $font16;
    font-weight: 700;

    &.grey{
        background-color: $button-color-lv-1;
        color: $text-color-lv-5;
    }
}
</style>