import { getAxiosData } from "@/assets/js/function";
import store from '@/store';
import { useRouter } from "vue-router";

/**
 *  @description 기관 공고지원자 관리
 */
export async function getMngList(params)
{
    const router = useRouter();

    try
    {   
        let wait    = 0;
        let update  = 0;
        let proc    = 0;
        let comp    = 0;
        let turn    = 0;

        let stNm  = '';
        let color = 'gray';

        const list = [];
        const res  = await getAxiosData('https://data.wecls.com/organ/dashboard/lectList', params);

        console.log(res);
        
        res.data['list'].map((item) => {
            if(item.state === 'E' || item.state === 'M' || item.state === 'N')
            {
                switch(item.state)
                {
                    case 'E':
                        stNm = '승인대기';
                        wait++;
                        color = 'blue';
                    break;
                    case 'M':
                        stNm = '수정대기';
                        update++;
                        color = 'blue';
                    break;
                    case 'N':
                        stNm = '반려';
                        turn++;
                        color = 'red';
                    break;
                }
            }
            else
            {
                switch(item.status)
                {
                    case 'E':
                        stNm = '진행중';
                        color = 'red';
                        proc++;
                    break;
                    case 'Y':
                        stNm = '모집완료';
                        color = 'gray';
                        comp++;
                    break;
                }
            }

            list.push({
                stNm            : stNm,
                color           : color,
                mainNm          : item.mainNm,
                subNm           : item.subNm,
                subject         : item.subject,
                name            : item.name,
                lectPeriod      : item.lectPeriod,
                totalTime       : item.totalTime,
                amt             : item.amt,
                viewCnt         : item.viewCnt,
                regPeriod       : item.regPeriod,
                lsCd            : item.lsCd,
                lpCd            : item.lpCd,
                lcCd            : item.lcCd,
                source          : item.source,
                cnt             : [
                    { title : "대기", count: item.count.ready },
                    { title : "서류통과", count: item.count.doc },
                    { title : "2차검증", count: item.count.interview },
                    { title : "최종매칭", count: item.count.matchEnd }
                ]
            });
        });

        const cnt = [
            { title : '승인대기', count : wait },
            { title : '수정대기', count : update },
            { title : '진행중', count : proc },
            { title : '모집완료', count : comp },
            { title : '반려', count : turn },
            { title : '전체', count : list.length }
        ];

        store.commit('userMng/setCnt', cnt);
        store.commit('userMng/setAllList', list);
    }
    catch(error)
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'organ');
            router.push('/organLogin');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}