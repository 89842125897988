<template>
    <div class="dialog">
        <div class="dialog-wrapper">
            <h3>{{ title }}</h3>
            <p v-html="description"></p>
            <!-- <img src="@/assets/images/btn-close.png"/> -->
            <div>
                <BaseButton :buttonText="blueText" @click="getBlueClick"/>
                <BaseButton v-if="type === 'confirm' ? true : false" :buttonText="whiteText" class="light" @click="getWhiteClick"/>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from "@/components/button/BaseButton.vue";

export default {
    name      : "DialogPopup",
    components: {BaseButton},
    props     : {
        type       : String,
        title      : String,
        description: String,
        blueText   : String,
        whiteText  : String,
    },
    methods : {
        getBlueClick() {
            this.$emit("getResult");
        },
        getWhiteClick() {
            this.$emit("getClose");
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    z-index: 11;

    &-wrapper {
        position: relative;
        width: 600px;
        padding: 24px;
        border-radius: 8px;
        @include shadow64;
        margin: 0 auto;
        background-color: $bk-color-lv-3;
        color: $text-color-lv-1;
        text-align: left;

        h3 {
            font-size: $font20;
            font-weight: 600;
        }

        p {
            font-size: $font14;
            font-weight: 400;
            margin: 14px 0;
        }

        img{
            position: absolute;
            right: 24px;
            top: 24px;
            cursor: pointer;
        }

        div{
            text-align: right;

            button{
                display: inline-block;
                &:not(:first-child){
                    margin-left: 8px;
                }
            }
        }
    }
}
</style>