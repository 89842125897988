/**
 * @description 로그인 vuex 모듈 관련
 * @author 김원명, @date 2023-12-12
 */
const loginModule = {
    namespaced  : true,
    state       : {
        login : {
            inst  : 'N',
            itNm  : '',
            itCd  : '',
            itCnt : 0,
            organ : 'N',
            ogNm  : '',
            ogCd  : '',
            ogCnt : 0
        }
    },
    mutations   : {
        setLogin(state, {type, gb})
        {
            state.login[type] = gb;
        },
        setOgNm(state, ogNm)
        {
            state.login.ogNm = ogNm;
        },
        setOgCd(state, ogCd) 
        {
            state.login.ogCd = ogCd;
        },
        setItNm(state, itNm)
        {
            state.login.itNm = itNm;
        },
        setItCd(state, itCd)
        {
            state.login.itCd = itCd;
        },
        setCntReset(state)
        {
            state.login.itCnt = 0;
            state.login.ogCnt = 0;
        }
    },
    actions : {
        login({ commit }, type)
        {
            commit('setLogin', { type : type, gb : 'Y' });
            localStorage.setItem(type, 'Y');
        },
        logout({ commit }, type)
        {
            commit('setLogin', { type : type, gb : 'N' });
            commit('setCntReset');
            localStorage.setItem(type, 'N');
        },
        initializeState({ commit }) 
        {
            const inst  = localStorage.getItem('inst');
            const organ = localStorage.getItem('organ');
            
            if (inst)
            {
                commit('setLogin', { type : 'inst', gb : inst });

                const itNm = localStorage.getItem('itNm');
        
                if (itNm) commit('setItNm', itNm);
            }

            if(organ)
            {
                commit('setLogin', { type : 'organ', gb : organ });

                const ogNm = localStorage.getItem('ogNm');
        
                if (ogNm) commit('setOgNm', ogNm);
            }
        }
    }
};
  
export default loginModule;