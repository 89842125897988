<template>
    <div class="input">
        <div class="input-wrapper">
            <div class="input-content" :class="contentClass">{{ fileNm }}</div>
            <label :for="inputId" class="click-btn">{{ fileBtn }}</label>
            <button v-if="showSubButton" class="sub"><a href="" download="">{{ blueButton }}</a></button>
            <button @click="clearFile">{{ greyButton }}</button>
            <input type="file" :id="inputId" :name="inputNm" @change="handleFileChange"/>
        </div>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import Confirm from "@/components/alert/Confirm.vue";

export default {
    name : "FileBox",
    components : { Confirm },
    props: {
        fileNm       : String,
        fileBtn      : String,
        inputId      : String,
        inputNm      : String,
        blueButton   : String,
        greyButton   : String,
        showSubButton: Boolean,
    },
    data() {
        return {
            selectedFileName    : null,
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
        };
    },
    computed: {
        contentClass() {
            return {
                'disabled': this.style === 'disabled',
                'readonly': this.style === 'readonly'
            }
        }
    },
    methods : {
        getConfirmResult() {
            this.confirmStat = false;
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        handleFileChange(event) {
            const fileInput = event.target;
            const maxSize   = 20 * 1024 * 1024; // 20MB

            if(fileInput.files[0].size > maxSize)
            {
                this.getConfirmSet('alert', '파일 크기 확인', '파일 크기는 20MB를 넘을 수 없습니다.');
                return false;
            }

            if (fileInput.files.length > 0) 
            {
                const file = fileInput.files[0];
                this.$emit('file-selected', { inputId: this.inputId, file : file });
            }
        },
        clearFile() {
            this.$emit('file-cleared', this.inputId);

            const fileInput = this.$el.querySelector("input[type=file]");
            
            if (fileInput) 
            {
                fileInput.value = "";
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.input {
    display: block;
    position: relative;

    &-wrapper {
        position: relative;
        display: flex;

        .input-content {
            border-radius: 4px;
            width: 440px;
            height: 40px;
            padding: 10px;
            font-size: $font14;
            font-weight: 400;
            background-color: $table-color-lv-1;
            border: 1px solid $line-color-lv-2;
        }

        .click-btn {
            padding: 8px 20px;
            border-radius: 2px;
            background-color: $text-color-lv-7;
            font-size: $font14;
            color: $bk-color-lv-3;
            cursor: pointer;
            min-width: 80px;
            margin-left: 20px;
            display: flex;
            align-items: center;
            font-weight: 500;

            & ~ input {
                display: none;
            }
        }

        button{
            padding: 8px 20px;
            border-radius: 2px;
            font-size: $font14;
            color: $text-color-lv-0;
            background-color: $table-color-lv-3;
            cursor: pointer;
            min-width: 80px;
            margin-left: 20px;
            display: flex;
            align-items: center;
            font-weight: 500;

            &.sub{
                color: $bk-color-lv-3;
                background-color: $button-color-lv-3;
            }
        }
    }
}
</style>