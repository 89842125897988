<template>
    <div class="payment admin-div">
        <div class="section inner">
            <h2 class="notice-title">
                결제하기
            </h2>

            <LabelTable title="상품정보" class="mt-50 no-shadow">
                <tr v-for="(item, index) in info['lcInfo']" :key="index">
                    <th>{{ index + 1 }}</th>
                    <td>
                        <img :src="`${downloadPath}/${item['filePath']}`"/>
                        <p>{{ item['subject'] }} / {{ getCommas(item['amt']) }}</p>
                    </td>
                </tr>
            </LabelTable>

            <LabelTable title="결제정보" class="no-shadow">
                <tr>
                    <th>상품총액</th>
                    <td><b>{{ getCommas(info['totalAmt']) }}원</b></td>
                </tr>

                <tr>
                    <th>쿠폰</th>
                    <td>
                        <span class="red mr-30"><b>0원</b></span>
                        <b class="ml-30 mr-18">쿠폰명 : </b>
                        <BaseButton class="small2" buttonText="쿠폰적용" @clikc="getConfirmSet('alert', '확인', '준비중인 기능입니다.')"/>
                    </td>
                </tr>

                <tr>
                    <th>포인트</th>
                    <td>
                        <span class="red mr-30"><b>0원</b></span>
                        <b class="ml-30 mr-18">보유 : 0원</b>
                        <BaseButton class="small2" buttonText="전액사용" @clikc="getConfirmSet('alert', '확인', '준비중인 기능입니다.')"/>
                    </td>
                </tr>

                <tr>
                    <th>총결제금액</th>
                    <td><b>{{ getCommas(info['calcAmt']) }}원</b></td>
                </tr>

                <tr>
                    <th>결제방법</th>
                    <td>
                        <div>
                            <input type="radio" id="payGbA" name="payGb" :checked="info['payGb'] === 'A' ? true : false" @click="getRadio('payGb', 'A')"/>
                            <label for="payGbA">계좌이체</label>
                            <input type="radio" id="payGbC" name="payGb" :checked="info['payGb'] === 'C' ? true : false" @click="getRadio('payGb', 'C')"/>
                            <label for="payGbC">신용/체크카드</label>
                        </div>
                        <template v-if="info['payGb'] === 'A'">
                            <div class="orange">
                                예금주: 주식회사 어나더데이 / 우리은행 / 계좌번호: 1005-403-951730
                            </div>
                            <div>
                                <label class="w100">입금 은행명</label>
                                <input type="text" id="bankNm" :value="info['bankNm']" placeholder="입금하실 은행명" @input="getInputValue"/>
                            </div>
                            <div>
                                <label class="w100">입금자명</label>
                                <input type="text" id="bankAcc" :value="info['bankAcc']" placeholder="입금하실 예금주명 입력" @input="getInputValue"/>
                            </div>
                            <!-- <div>
                                <label class="w100">입금 계좌번호</label>
                                <input type="text" id="bankNum" :value="info['bankNum']" placeholder="숫자만 입력해주세요." @input="getInputValue"/>
                            </div> -->
                            <div>
                                <ul>
                                    <li>고객님의 계좌이체가 접수되면, 위클즈 관리자가 송금을 확인한 후 결제 완료 처리를 진행합니다.</li>
                                    <li>이 과정은 보통 1일 영업일 정도 소요될 수 있습니다.</li>
                                    <li>입금 시 위클즈에서 제공한 계좌번호 및 입금자명을 정확히 입력해주세요.</li>
                                </ul>
                            </div>
                        </template>
                    </td>
                </tr>
            </LabelTable>

            <LabelTable title="서류 신청" class="no-shadow" v-if="info['payGb'] === 'A'">
                <tr>
                    <th>증빙서류</th>
                    <td>
                        <div>
                            <input type="checkbox" :id="'payRec'" :value="'Y'" :checked="info['payRec']" @click="getCheckBox"/>
                            <label for="payRec">현금영수증 신청</label>
                        </div>
                        <template v-if="info['payRec']">
                            <div>
                                <input type="radio" id="payDocT" name="payDoc" :checked="info['payDoc'] === 'T' ? true : false" @click="getRadio('payDoc', 'T')"/>
                                <label for="payDocT">세금계산서</label>
                                <input type="radio" id="payDocC" name="payDoc" :checked="info['payDoc'] === 'C' ? true : false" @click="getRadio('payDoc', 'C')"/>
                                <label for="payDocC">현금영수증</label>
                            </div>
                            <template v-if="info['payDoc'] === 'T'">
                                <div>
                                    <ul>
                                        <li>세금계산서, 현금영수증은 중복 신청 및 발급 이후 변경이 불가능하니 신중하게 선택해주세요.</li>
                                        <li>발급에 문제가 있는 경우 ‘FAQ-세금계산서' 항목을 참고해주세요.</li>
                                    </ul>
                                </div>
                                <div>
                                    <label class="w100">사업자명</label>
                                    <input type="text" id="einNm" :value="info['einNm']" placeholder="사업자명" @input="getInputValue"/>
                                </div>
                                <div>
                                    <label class="w100">대표자명</label>
                                    <input type="text" id="ceoNm" :value="info['ceoNm']" placeholder="대표자명" @input="getInputValue"/>
                                </div>
                                <div>
                                    <label class="w100">사업자등록번호</label>
                                    <input type="text" id="einNum" :value="info['einNum']" placeholder="사업자등록번호" @input="getInputValue"/>
                                </div>
                                <div>
                                    <label class="w100">업태</label>
                                    <input type="text" id="einType" :value="info['einType']" placeholder="업태" @input="getInputValue"/>
                                </div>
                                <div>
                                    <label class="w100">종목</label>
                                    <input type="text" id="einItem" :value="info['einItem']" placeholder="종목" @input="getInputValue"/>
                                </div>

                                <div>
                                    <label class="w100">대표자명</label>
                                    <input type="text" id="ceoNm" :value="info['ceoNm']" placeholder="대표자명" @input="getInputValue"/>
                                </div>
                                <div>
                                    <label class="w100">주소</label>
                                    <input type="text" id="addr" :value="info['addr']" placeholder="주소" @input="getInputValue"/>
                                </div>
                                <div>
                                    <label class="w100" for="">이메일주소</label>
                                    <input type="text" id="email" :value="info['email']" placeholder="이메일주소" @input="getInputValue"/>
                                </div>
                                <div>
                                    <label class="w100" for="">사업자명</label>
                                    <input type="text" id="einNm" :value="info['einNm']" placeholder="사업자명" @input="getInputValue"/>
                                </div>
                            </template>
                            <template v-if="info['payDoc'] === 'C'">
                                <div>
                                    <label class="w100">현금영수증번호</label>
                                    <input type="text" id="cashNum" :value="info['cashNum']" placeholder="현금영수증번호" @input="getInputValue"/>
                                </div>
                            </template>
                        </template>
                    </td>
                </tr>
            </LabelTable>

            <h5 class="center">위 주문 내용을 확인 하였으며, 회원 본인은 개인정보 이용 및 제공 결제에 동의합니다.</h5>

            <BaseButton v-if="info['stat'] === 'N'" buttonText="결제하기" class="w240" @click="getPaymentsSet"/>
        </div>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
    <TossPayments v-if="toss" :amount="Number(this.info['lcInfo'][0]['amt'])" :orderName="this.info['lcInfo'][0]['subject']" :customerName="this.customerName" 
        @get-result="getResult" @get-close="toss=false" @get-alert="getAlert"/>
</template>

<script>
import LabelTable from "@/components/table/LabelTable.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import TossPayments from "@/views/admin/TossPayments.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from "vuex";
import { getPaymentInfo } from "@/assets/js/admin/payment";
import { getAxiosData, getCommas } from "@/assets/js/function";

export default {
    name: "AdmPaymentSet",
    components: {BaseButton, LabelTable, TossPayments, Confirm},
    data() {
        return {
            resultYn            : 'N',
            compYn              : 'N',
            mtCd                : this.$route.params.mtCd,
            downloadPath        : 'https://elasticbeanstalk-ap-northeast-2-627549176645.s3.ap-northeast-2.amazonaws.com',
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            toss                : false,
            customerName        : this.$store.state.login.login.ogNm
        }
    },
    created() {
        this.getInfo();
    },
    computed: {
        ...mapState('payment', {
            info  : state => state.payment.info
        })
    },
    methods: {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.compYn === 'N')
            {
                if(this.resultYn === 'Y')
                {
                    if(this.info['payGb'] === 'A')
                    {
                        this.getResult();
                    }
                    else
                    {
                        this.toss = true;
                    }
                }
            }

            if(this.compYn === 'Y')
            {
                this.compYn = 'N';
                this.$router.push('/admin/paymentMng');
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getInfo() {
            const params = {
                mtCd : this.mtCd
            };

            getPaymentInfo(params);
        },
        getCommas(value) {
            return getCommas(Number(value));
        },
        getRadio(id, value) {
            this.info[id] = value;
        },
        getCheckBox() {
            const id        = event.target.id;
            const checked   = event.target.checked;

            this.info[id] = checked;
        },
        getInputValue() {
            const id    = event.target.id;
            const value = event.target.value;

            this.info[id] = value;
        },
        getPaymentsSet() {
            this.getConfirmSet('confirm', '확인', '결제하시겠습니까?');
            this.resultYn = 'Y';
        },
        getAlert(title, contents) {
            this.getConfirmSet('alert', title, contents);
        },
        async getResult (data = 'N') {
            try
            {
                const params = {
                    userCd      : this.info['oaCd'],
                    mtCd        : this.mtCd,
                    price       : Number(this.info['totalAmt']),
                    cpPrice     : 0,
                    point       : 0,
                    calcPrice   : Number(this.info['calcAmt']),
                    payGb       : this.info['payGb'],
                    payRec      : this.info['payRec'] ? 'Y' : 'N',
                    payDoc      : this.info['payDoc'],
                    einNm       : this.info['einNm'],
                    einNum      : this.info['einNum'],
                    einType     : this.info['einType'],
                    einItem     : this.info['einItem'],
                    ceoNm       : this.info['ceoNm'],
                    addr        : this.info['addr'],
                    email       : this.info['email'],
                    cashNum     : this.info['cashNum'],
                    bankNm      : this.info['bankNm'],
                    bankAcc     : this.info['bankAcc']
                };

                if(this.info['payGb'] === 'C')
                {
                    params['amount']        = Number(this.info['totalAmt']);
                    params['orderId']       = data['orderId'];
                    params['paymentKey']    = data['paymentKey'];
                }

                const res = await getAxiosData('https://data.wecls.com/organ/dashboard/calcProc', params);

                if(res['status'] === 200)
                {
                    this.compYn = 'Y';
                    this.getConfirmSet('alert', '완료', '결제가 완료되었습니다.');
                    /* 결제 완료 로직 넣어야됨!*/
                }
            }
            catch(error)
            {
                console.log(error);
                if(error.response.data.status === 401)
                {
                    alert('로그인 세션이 만료되었습니다. 결제 취소 후 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'organ');
                    this.$router.push('/login');
                }
                else
                {
                    this.resultYn = 'N';
                    this.getConfirmSet('alert', '에러', '결제 처리 중 에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }

            this.toss = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.payment {
    padding: 90px 0;

    .margin {
        margin-top: 46px;
    }

    .notice-title {
        font-size: $font28;
        font-weight: 700;
        margin-bottom: 46px;
    }

    table{
        tr{
            td{
                img{
                    width: 78px;
                    height: 87px;
                    border-radius: 6px;
                    margin: 9px 20px 9px 16px;
                    vertical-align: middle;
                    display: inline-block;
                }
                p{
                    display: inline-block;
                    vertical-align: middle;
                }

                label{
                    margin-right: 12px;
                    &.w100{
                        width: 100px;
                        margin-right: 10px;
                    }
                }

                input[type="radio"], input[type="checkbox"]{
                    margin: 0 8px;
                }

                input[type="text"]{
                    border-radius: 4px;
                    border: 1px solid $line-color-lv-2;
                    width: 200px;
                    height: 40px;
                    padding: 10px;
                }

                div + div {
                    margin-top: 10px;
                }

                ul{
                    li{
                        color: $text-color-lv-5;
                        list-style: disc;
                        margin-left: 25px;
                    }
                }
            }
        }
    }

    h5.center{
        margin: 34px 0;
        color: $text-color-lv-0;
        font-size: $font20;
        font-weight: 400;
    }

    button.w240{
        margin: 0 auto;
        padding: 12px;
        width: 240px;
        text-align: center;
        display: block;
        font-size: $font20;
    }
}
</style>