import { getAxiosData } from "@/assets/js/function";
import store from "@/store";
import { useRouter } from "vue-router";

export async function getProfileUser() 
{
    const router = useRouter();

    try 
    {
        const res = await getAxiosData('https://data.wecls.com/inst/mainList/getMyProfile');

        console.log(res);

        const check = res.data['itCd'] ? true : false;

        console.log(check);
        const data  = store.state.data.data;

        const desArea  = check ? JSON.parse(res.data['desArea']) : '';

        const dataFile = [];

        if(check)
        {
            if(res.data['etcFile'].length === 0)
            {
                dataFile.push({
                    text : '',
                    file : null,
                    gb   : 'N'
                });
            }
            else
            {
                res.data['etcFile'].map((item) => {
                    dataFile.push({
                        fileNo  : item.fileNo,
                        text    : item.fileNm,
                        file    : item.filePath,
                        gb      : 'Y'
                    });
                })
            }
        }
        else
        {
            dataFile.push({
                text : '',
                file : null,
                gb   : 'N'
            });
        }

        const info = {
            itCd            : check ? res.data['itCd'] : '',
            iaCd            : res.data['iaCd'],
            appeal          : check ? res.data['appeal'] : '',
            proofImg        : {
                text        : res.data['idPic'] ? res.data['idPic']['fileNm'] : '',
                file        : res.data['idPic'] ? res.data['idPic']['filePath'] : null,
                gb          : res.data['idPic'] ? 'Y' : 'N'
            },
            name            : res.data['name'],
            tel             : res.data['tel'],
            birthday        : res.data['birth'],
            gender          : res.data['gender'],
            emerTel         : check ? res.data['emerTel'] : '',
            area1           : check ? desArea['area1'] : data['area1'][0]['value'],
            prefDay         : check ? (res.data['prefDay'] === '' ? [] : res.data['prefDay']) : [],
            acadAbil        : check ? res.data['acadAbil'] : data['edu'][0]['value'],
            school          : check ? res.data['school'] : '',
            major           : check ? res.data['major'] : '',
            mainFieldCd     : check ? res.data['mainFieldCd'] : data['mainLecture'][0]['value'],
            fieldCd         : check ? res.data['fieldCd'] : data['lecture'][0]['value'],
            career          : check ? res.data['career'] : data['career'][0]['value'],
            repCareer       : check ? res.data['repCareer'] : ['', '', ''],
            instMemo        : check ? res.data['instMemo'] : '',
            careerMemo      : check ? res.data['careerMemo'] : '',
            finalEduImg     : {
                text        : res.data['finalEdu'] ? res.data['finalEdu']['fileNm'] : '',
                file        : res.data['finalEdu'] ? res.data['finalEdu']['filePath'] : null,
                gb          : res.data['finalEdu'] ? 'Y' : 'N'
            }, 
            credentialsImg  : {
                text        : res.data['careerFile'] ? res.data['careerFile']['fileNm'] : '',
                file        : res.data['careerFile'] ? res.data['careerFile']['filePath'] : null,
                gb          : res.data['careerFile'] ? 'Y' : 'N'
            },
            dataFile        : dataFile,
            pubYn           : check ? res.data['pubYn'] : 'Y',
            delYn           : 'N',
            fileGb          : []
        };

        console.log(info);

        store.commit('profile/setInfo', info);
    } 
    catch (error) 
    {
        console.error(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'inst');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
            history.back();
        }
    }
}