<template>
    <div class="login intro organ-login">
        <LoginBox>
            <div class="relative">
                <img src="@/assets/images/arrow-prev.png" alt="" title="" @click="goToPreviousPage"/>
                <h2 class="mobile-header"><span>기관</span> 로그인</h2>
            </div>
            <div>
                <InputBox :inputId="'id'" inputType="email"
                          :inputValue="id"
                          labelText="이메일" placeholder="이메일을 입력해주세요." class="mb-24" @keyup.enter="getEnter" @input="getInputSet"/>
                <InputBox :inputId="'pw'" inputType="password"
                          :inputValue="pw"
                          labelText="비밀번호" placeholder="비밀번호를 입력해주세요." class="mb-16" @keyup.enter="getEnter" @input="getInputSet"/>

                <div class="flex">
                    <CheckBox inputId="auto" inputNm="auto" labelText="자동 로그인" class="w-half"/>
                    <ul class="w-half right flex">
                        <li>
                            <router-link :to="{ path: '/help/idCheck', query: { type : type } }">비밀번호 찾기</router-link>
                        </li>
                        <li @click="getFindId">아이디 찾기</li>
                    </ul>
                </div>
                <BaseButton buttonText="이메일 로그인" class="orange" @click="getLogin"/>
                <p>
                    마음에 드는 강사를 찾고 싶다면? 👉🏻
                    <router-link :to="{ path: '/join/agree', query: { type: type } }">
                        <span>회원가입</span>
                    </router-link>
                </p>
            </div>
            <img src="@/assets/images/logo.png" alt="logo" title="logo" class="logo" />
        </LoginBox>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmResult"/>
</template>

<script>
import InputBox from "@/components/input/InputBox.vue";
import CheckBox from "@/components/input/CheckBox.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { getAxiosData } from "@/assets/js/function";
import { getCertified } from "@/assets/js/certified";
import { mapState } from 'vuex';
import LoginBox from "@/components/common/LoginBox.vue";

export default {
    name      : "MainLogin",
    components: {LoginBox, BaseButton, CheckBox, InputBox, Confirm},
    computed: {
        ...mapState('idFind', {
            certified   : state => state.idFind.certified,
            list        : state => state.idFind.list
        })
    },
    data() {
        return {
            id                  : '',
            pw                  : '',
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            type                : 'O'
        }
    },
    methods: {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.certified['yn'] === 'Y')
            {
                this.getIdList();
                this.certified['yn'] = 'N';
            }
        },
        goToPreviousPage() {
            this.$router.push({ path : '/' });
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getInputSet() {
            const id  = event.target.id;
            const val = event.target.value;

            if(id === 'id')
            {
                this.id = val;
            }
            else
            {
                this.pw = val;
            }
        },
        getEnter() {
            const id  = event.target.id;

            if(id === 'id')
            {
                const pw = document.getElementById("pw");

                pw.focus();
            }
            else
            {
                this.getLogin();
            }
        },
        async getLogin() {
            try 
            {
                const params = {
                    id   : this.id,
                    pw   : this.pw,
                    type : this.type
                };

                const res  = await getAxiosData('https://data.wecls.com/login/loginCheck', params);

                if(res['status'] === 200)
                {
                    localStorage.setItem('ogNm', res.data['ogNm']);
                    this.$store.commit('login/setOgNm', res.data['ogNm']);

                    this.$store.dispatch('login/login', 'organ');

                    /** 기관 */
                    if(res.data['check'] === 'Y')
                    {
                        this.$router.push({path : `/admin/main`});
                    }
                    else
                    {
                        this.$router.push({path : `/admin/profileMng`});
                    }
                }
                else
                {
                    this.getConfirmSet('alert', '실패', '로그인에 실패하였습니다. 이메일과 비밀번호를 확인해주세요.');
                }
            }
            catch (error)
            {
                this.getConfirmSet('alert', '실패', '로그인에 실패하였습니다. 이메일과 비밀번호를 확인해주세요.');
                console.log(error);
            }
        },
        getFindId() {
            getCertified().then(result => {
                if(result.status)
                {
                    this.$store.commit('idFind/setCertifed', {
                        yn          : 'Y',
                        name        : result.data['name'],
                        tel         : result.data['phone'],
                        birthday    : result.data['birthday']
                    });

                    this.getConfirmSet('alert', '성공', result.msg);
                }
                else
                {
                    this.getConfirmSet('alert', '실패', result.msg);
                }
            }).catch(error => {
                console.log(error);
                this.getConfirmSet('alert', '에러', error.msg);
            });
        },
        async getIdList() {
            const params = {
                type  : this.type,
                name  : this.certified['name'],
                birth : this.certified['birthday']
            };

            console.log(params);

            try 
            {
                const res  = await getAxiosData('https://data.wecls.com/searchInfo/searchId', params);

                console.log(res);

                const idList = res.data.map((item, index) => {
                    return `<h3>${index+1}. ${item.id}</h3>`;
                });

                this.getConfirmSet(`alert`, `아이디 찾기 완료`, `회원님의 아이디 목록입니다. <br><br>${idList.join('')}`);
            }
            catch (error)
            {
                this.getConfirmSet('alert', '실패', '아이디 찾기에 실패하였습니다. 문제가 지속될 경우 관리자에게 문의하세요.');
                console.log(error);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.login {
    .relative{
        img{
            @include my-auto;
            left: 10px;
            width: 10px;
            display: inline-block;
            cursor: pointer;
        }
    }

    .mobile-header{
        text-align: center;
        font-size: $font24;
        margin-bottom: 50px;
        font-weight: 800;

        span{
            color: $brand-color-lv-1;
        }
    }
    button {
        &.orange{
            width: 100%;
            border-radius: 6px;
            padding: 15px 0;
            margin: 10px 0 24px 0;
        }
    }

    ul {
        height: 32px;
        align-items: center;
        flex-direction: row-reverse;

        li {
            font-size: $font12;
            font-weight: 500;
            color: $text-color-lv-3;
            margin-left: 10px;
            cursor: pointer;
            height: $font12;
            line-height: $font12;
        }
    }

    p {
        text-align: center;
        font-size: $font14;
        color: $text-color-lv-2;
        font-weight: 500;

        span {
            color: $blue-color-lv-4;
            font-weight: 700;
            cursor: pointer;
        }
    }

    .logo{
        @include mx-auto;
        bottom: 40px;
        width: 86px;
    }
}
</style>