<template>
    <div>
        <MainHeader/>
        <div class="section">
            <img src="@/assets/images/brandstory.jpg" data-aos="fade-up" />
        </div>
    </div>
</template>

<script>
import MainHeader from "@/views/static/MainHeader.vue";

export default {
    name: "BrandStory",
    components: {MainHeader}
}
</script>

<style scoped>
img{
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    display: block;
}
</style>