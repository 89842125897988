<template>
    <div class="info">
        <MypageHeader />
        <p class="section-border" />
        <div class="inner section">
            <NoticeBox showTitle title="내 정보관리" showNotice />
            <p class="section-border" />
            <div class="flex center">
                <div>
                    <img src="@/assets/images/img-notice.png" />
                    <h5>서비스 준비중입니다.</h5>
                    <p>이용에 불편을 드려서 죄송합니다.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import MypageHeader from "@/views/user/mypage/MypageHeader.vue";
import NoticeBox from "@/components/common/NoticeBox.vue";

export default {
    name: "InfoMng",
    components: {NoticeBox, MypageHeader}
}

</script>

<style lang="scss" scoped>
.info{
    padding: 90px 0;

    .flex{
        align-items: center;

        div{
            padding: 120px 0;

            img{
                width: 130px;
                margin: 0 auto;
            }

            h5{
                color: #6f6f6f;
                font-size: 2rem;
                margin-bottom: 20px;
            }

            p{
                color: #a3a3a3;
                font-size: $font16;
            }
        }
    }
}
</style>