/**
 * @description 회원가입 vuex 모듈 관련
 * @author 김원명, @date 2023-11-20
 */
const joinModule = {
    namespaced  : true,
    state       : {
        joinInfo : {
            certified   : 'N',               /** 본인인증 완료 여부 (Y/N) */
            agree       : getAgreeInfo('N'), /** 약관 동의 정보 */
            terms       : getTermsInfo(),    /** 약관 내용 정보 */
            login       : getLoginInfo(),    /** 로그인 계정 정보 */
            instrInfo   : getInstrInfo()     /** 강사 정보 */
        }
    },
    mutations   : {
        setAgree(state, { gb, value })
        {
            if(gb === 'all')
            {
                state.joinInfo.agree = getAgreeInfo(value);
            }
            else
            {
                state.joinInfo.agree[gb] = value;
            }
        },
        setTerms(state, newTerms)
        {
            state.joinInfo.terms = newTerms;
        },
        setJoinInfo(state, { gb, value })
        {
            state.joinInfo[gb] = value;
        },
        setFile(state, { gb, file })
        {
            state.joinInfo[gb].img.file.push(file);
        },
        setReset(state)
        {
            state.joinInfo = {
                certified   : 'N',
                agree       : getAgreeInfo('N'),
                login       : getLoginInfo(),
                instrInfo   : getInstrInfo()
            }
        }
    }
};

function getAgreeInfo(gb)
{
    return {
        age    : gb,
        use    : gb,
        info   : gb,
        choice : gb
    }
}

function getTermsInfo()
{
    return {
        age    : '',
        use    : '',
        info   : '',
        choice : ''
    }
}

function getLoginInfo()
{
    return {
        id      : getObject(),
        pw      : getObject(),
        pwChk   : getObject()
    }
}

function getInstrInfo()
{
    return {
        // instrNm     : getObject(),
        // addr        : getObject(),
        // addrDetail  : getObject(),
        tel         : '',
        name        : '',
        birthday    : '',
        gender      : '',
        // service     : {
        //     value : '',
        //     text  : ''
        // },
        // url         : '',
        // day         : [],
        // worktime    : {
        //     value : '',
        //     text  : ''
        // },
        // memo        : '',
        // img         : {
        //     text : '',
        //     file : ''
        // }
    }
}

function getObject()
{
    return {
        value : '',
        text  : '',
        class : ''
    }
}
  
export default joinModule;