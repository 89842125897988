<template>
    <div class="user admin-div">
        <div class="section inner">
            <div>
                <h2 class="notice-title">
                    공고 관리
                </h2>
                <router-link to="/admin/collectMng">
                    <RoundButton buttonText="신규 공고 등록하기" />
                </router-link>
            </div>
            <FilterBox :filters="cnt" class="margin" @filter-changed="getListChange"/>
            <GreySelectBox class="margin">
                <Select :selectId="'type'" :selectOptions="dateType" :selectVal="search['type']" :selectChange="getSelectSet" class="small" :labelText="'기간 구분'" />
                &nbsp;&nbsp;
                <DateBox class="regular" :inputId="'startDt'" :inputValue="search['startDt']" :selectFunction="getSelectDate" :timePicker="false" />
                &nbsp;&nbsp;
                <DateBox class="regular" :inputId="'endDt'" :inputValue="search['endDt']" :selectFunction="getSelectDate" :timePicker="false" />
            </GreySelectBox>

            <div class="card-wrapper">
                <UserMngCard :rows="list" title="[부산] 치매예방에 좋은 힐링 독서 강사모집 안내" @get-lect="getLectSet" @get-update="getLectDetail"/>
            </div>
        </div>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import FilterBox from "@/components/common/FilterBox.vue";
import GreySelectBox from "@/components/common/GreySelectBox.vue";
import Select from "@/components/input/Select.vue";
import DateBox from "@/components/input/DateBox.vue";
import UserMngCard from "@/views/admin/template/UserMngCard.vue";
import RoundButton from "@/components/button/RoundButton.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from 'vuex';
import { getDate, getConvertDate, getAxiosData } from "@/assets/js/function";
import { getMngList } from "@/assets/js/admin/userMng"

export default {
    name: "AdmUserMng",
    components: { RoundButton, UserMngCard, DateBox, GreySelectBox, FilterBox, Select, Confirm },
    data() {
        return {
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            resultYn            : 'N',
            approval            : 'N',
            lsCd                : ''
        }
    },
    created() {
        this.getStack();
    },
    computed: {
        ...mapState('data', {
            dateType  : state => state.data.dateType,
        }),
        ...mapState('userMng', {
            search  : state => state.userMng.search,
            cnt     : state => state.userMng.cnt,
            allList : state => state.userMng.allList,
            list    : state => state.userMng.list
        })
    },
    methods: {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.approval === 'N')
            {
                if(this.resultYn === 'Y')
                {
                    this.getLectResult();
                }
            }

            if(this.approval === 'Y')
            {
                this.approval = 'N';
                this.getStack();
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getStack() {
            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
            };

            getMngList(params);
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            const value = event['value'];
            const date  = getDate(value);

            this.search['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
            this.search['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');

            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
            };

            getMngList(params);
        },
        getSelectDate(date, id) {
            this.search[id] = date;

            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt']
            }

            getMngList(params);
        },
        getListChange(params) {
            const list = [];

            if(params['title'] === '전체')
            {
                this.$store.commit('userMng/setList', this.allList);
            }
            else
            {
                this.allList.map((item) => {
                    if(item.stNm === params['title'])
                    {
                        list.push({
                            stNm        : item.stNm,
                            color       : item.color,
                            mainNm      : item.mainNm,
                            subNm       : item.subNm,
                            subject     : item.subject,
                            name        : item.name,
                            lectPeriod  : item.lectPeriod,
                            totalTime   : item.totalTime,
                            amt         : item.amt,
                            viewCnt     : item.viewCnt,
                            regPeriod   : item.regPeriod,
                            lsCd        : item.lsCd,
                            lpCd        : item.lpCd,
                            lcCd        : item.lcCd,
                            source      : item.source,
                            cnt         : item.cnt
                        })
                    }
                });

                this.$store.commit('userMng/setList', list);
            }
        },
        getLectSet(lsCd) {
            this.lsCd       = lsCd;
            this.resultYn   = 'Y';

            this.getConfirmSet('confirm', '공고 마감', '해당 공고를 마감처리하시겠습니까?');
        },
        async getLectResult() {
            const params = {
                lsCd : this.lsCd
            };
            
            try
            {
                const res = await getAxiosData("https://data.wecls.com/organ/dashboard/lectEndProc", params);

                if(res['status'] === 200)
                {
                    this.approval = 'Y';
                    this.getConfirmSet('alert', '완료', '해당 공고가 마감처리되었습니다.');
                }
            }
            catch(error)
            {
                console.log(error);
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'organ');
                    this.$router.push('/organLogin');
                }
                else
                {
                    this.resultYn = 'N';
                    this.getConfirmSet('alert', '에러', '공고 마감 처리 중 에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        },
        getLectDetail(lpCd, source) {
            this.$router.push({ path: `/admin/collectMng/${lpCd}/${source}` });
        }
    }
}
</script>

<style lang="scss" scoped>
.user {
    padding: 90px 0;

    .section {
        div {
            position: relative;

            button {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
    }

    .margin {
        margin-top: 30px;
    }

    .grey {
        flex-direction: row-reverse;
    }

    .notice-title {
        font-size: $font28;
        font-weight: 700;
        margin-bottom: 30px;

        span {
            font-weight: 500;
            display: block;
        }
    }

    .card-wrapper {
        margin-top: 30px;
        padding: 10px 10px 100px 10px;
        border-radius: 8px;
        background-color: $bk-color-lv-2;
    }
}
</style>