/**
 * @description 메세지 vuex 모듈 관련
 * @author 김원명, @date 2024-01-23
 */
const userMsgModule = {
    namespaced  : true,
    state       : {
        userMsg : {
            rooms       : [],
            messages    : [],
            roomId      : ''
        }
    },
    mutations : {
        setRooms(state, newRooms)
        {
            state.userMsg.rooms = newRooms;
        },
        setMessages(state, newMessages)
        {
            state.userMsg.messages = newMessages;
        },
        setRoomId(state, newRoomId)
        {
            state.userMsg.roomId = newRoomId;
        }
    }
};
  
export default userMsgModule;