<template>
    <div class="container">
        <component :is="$route.meta.layout || 'div'" class="box">
            <router-view></router-view>
        </component>
    </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
    name: 'App',
    mounted() {
        AOS.init({
            duration       : 1000,
            once           : true,
            anchorPlacement: "top-top",
        });
    },
}
</script>

<style lang="scss">
.container {
    position: relative;
    width: 100%;
}

.section {
    position: relative;
    padding: 80px 0 !important;
}

.box {
    width: 100%;
    background-color: $bk-color-lv-3;
}

.inner{
    width: 1300px;
    margin: 0 auto !important;
}

.border{
    width: 100%;
    height: 1px;
    background-color: $line-color-lv-4;
}

.section-border{
    width: 100%;
    height: 1px;
    background-color: $line-color-lv-4;
}

div {
    width: 100%;
}

.admin-div{
    width: calc(100% - 240px);
    margin-left: 240px !important;
    background-color: $bk-color-lv-3;
}

.intro{
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center center;
    background-image: url("@/assets/images/login_user_bg2.png");

    &.user-login{
        background-image: url("@/assets/images/login_user_bg.jpg");
    }

    &.organ-login{
        background-image: url("@/assets/images/login_admin_bg.jpg");
    }
}

.editor{
    p{
        min-height: 24px;
    }
}
</style>
