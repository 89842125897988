<template>
    <div class="section">
        <div class="inner">
            <div class="banner">
                <a href="http://blenkit.co.kr/" target="_blank">
                    <img src="@/assets/images/img_user_banner.png" title="배너 이미지" alt="배너 이미지" />
                </a>
            </div>
         
            <!-- <div class="tag-box">
                <TagSpan content="베스트 모집공고 🚀" class="green" />
                <TagSpan content="내 지역과 가까운 👟" class="purple" />
                <TagSpan content="실시간 온라인 강의 모집 🌏" class="blue" />
                <TagSpan content="오프라인 강의 모집 🏢" class="yellow" />
                <TagSpan content="동영상 강의 모집 🌈" class="purple" />
                <TagSpan content="기간이 얼마없어요 🚨" c6lass="blue" />
                <TagSpan content="위클즈 추천 픽 📌" class="purple" />
            </div> -->

            <TableListCard :title="`${today.getMonth() + 1}월달 BEST 강사 모집공고`" :lists="list['bestList']" @get-list="getStatCheck" @get-more="getMoreList('BEST')"/>
            <TableListCard :title="'전체 강사 모집공고'" :lists="list['mainList']" @get-list="getStatCheck" @get-more="getMoreList('MAIN')"/>
            <!-- <TableListCard :title="'내가 즐겨찾기한 기관 강의 공고'" :lists="list['likeList']" @get-list="getStatCheck" @get-more="getMoreList('LIKE')"/> -->
        </div>
    </div>
</template>

<script>
import TableListCard from "@/components/card/TableListCard.vue";

import { mapState } from "vuex";
import { getMainList } from "@/assets/js/user/main"

export default {
    name: "UserMain",
    components: { TableListCard },
    created() {
        this.getStatCheck();
    },
    computed: {
        ...mapState('main', {
            list : state => state.main.list
        })
    },
    data() {
        return {
            today : new Date()
        }
    },
    methods: {
        getStatCheck() {
            const params = {
                order : 'ASC'
            };

            getMainList(params);
        },
        getMoreList(type) {
            this.$router.push({ path : `/user/main/more/${type}`});
        }
    }
}
</script>

<style lang="scss" scoped>
.banner {
    margin: 46px 0;
}

.tag-box {
    border-top: 1px solid $line-color-lv-3;
    padding: 24px 0;
}
</style>