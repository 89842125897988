<template>
    <SectionHeader :items="items" />
</template>

<script>
import SectionHeader from "@/components/common/SectionHeader.vue";

export default {
    name: "MypageHeader",
    components: {SectionHeader},
    data() {
        return {
            items: [
                {title: "대시보드", to: "/user/mypage/dashboard"},
                {title: "일정", to: "/user/mypage/calender"},
                {title: "매칭관리", to: "/user/mypage/matchMng"},
                {title: "강의관리", to: "/user/mypage/lectMng"},
                {title: "정산관리", to: "/user/mypage/calcMng"},
                {title: "리뷰관리", to: "/user/mypage/gradeMng"},
                {title: "메세지", to: "/user/mypage/message"},
                {title: "이력서 관리", to: "/user/mypage/profileMng"},
                {title: "내 정보관리", to: "/user/mypage/infoMng"}
            ]
        }
    }
}
</script>

<style scoped>

</style>