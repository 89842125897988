import { getAxiosData, getCommas } from "@/assets/js/function";
import store from '@/store';
import { useRouter } from "vue-router";

export async function getMatchingInfo(params) 
{
    const router = useRouter();

    try 
    {
        const res = await getAxiosData('https://data.wecls.com/inst/dashboard/matchList', params);

        console.log(res);

        /** 카운트 헤더 */
        const info = [
            { title : '매칭 신청', count : res.data['cnt']['readyCnt'] },
            { title : '매칭 평가중', count : res.data['cnt']['evalCnt'] },
            { title : '매칭 성공', count : res.data['cnt']['successCnt'] },
            { title : '매칭 실패', count : res.data['cnt']['failedCnt'] },
            { title : '매칭 성공율', count : res.data['cnt']['rate']+'%' }
        ];

        store.commit("match/setInfo", info);

        const list = [];

        let ready       = 0;
        let eva         = 0;
        let success     = 0;
        let failed      = 0;

        let stNm   = '';

        res.data['list'].map((item) => {
            if(item.state === 'E')
            {
                ready++;
                stNm = '매칭 신청';
            }
            else if(item.state === 'D' || item.state === 'I')
            {
                eva++;
                stNm = '매칭 평가중';
            }
            else if(item.state === 'Y')
            {
                success++;
                stNm = '매칭 성공';
            }
            else if(item.state === 'N')
            {
                failed++;
                stNm = '매칭 실패';
            }

            list.push({
                lpCd        : item.lpCd,
                state       : item.state,
                stNm        : stNm,
                regDt       : item.regDt.slice(0, -3),
                ogNm        : item.ogNm,
                subject     : item.subject,
                lectPeriod  : item.lectPeriod,
                amt         : getCommas(Number(item.amt))
            })
        });

        const cnt = [
            { title : '매칭 신청', count : ready, state : 'E' },
            { title : '매칭 평가중', count : eva, state : 'I' },
            { title : '매칭 성공', count : success, state : 'Y' },
            { title : '매칭 실패', count : failed, state : 'N' },
            { title : '전체', count : list.length, state : '' }
        ];

        store.commit('match/setCnt', cnt);
        store.commit('match/setAllList', list);
    } 
    catch (error) 
    {
        console.error(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'inst');
            router.push('/login');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}