/**
 * @description 비밀번호 찾기 vuex 모듈 관련
 * @author 김원명, @date 2023-01-23
 */
const pwFindModule = {
    namespaced  : true,
    state       : {
        pwFind : {
            certified   : getCertifiedInfo(),
            info        : getInfo()
        }
    },
    mutations   : {
        setCertifed(state, newCertified)
        {
            state.pwFind.certified = newCertified;
        },
        setInfo(state, {gb, value})
        {
            state.pwFind.info[gb] = value;
        },
        setReset(state)
        {
            state.pwFind = {
                certified   : getCertifiedInfo(),
                info        : getInfo()
            }
        }
    }
};


function getCertifiedInfo()
{
    return {
        yn          : 'N', /** 본인인증 완료 여부 (Y/N) */
        name        : '',
        tel         : '',
        birthday    : ''
    }
}

function getInfo()
{
    return {
        id      : getObject(),
        pw      : getObject(),
        pwChk   : getObject()
    }
}

function getObject()
{
    return {
        value : '',
        text  : '',
        class : ''
    }
}
  
export default pwFindModule;