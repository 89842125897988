<template>
    <div>
        <MainHeader/>

        <div class="main-banner main-box">
            <h1 data-aos="fade-up">
                <span class="point">더 즐거운 문화예술 교육 🎨 을 원하시나요?</span><br>
                <span class="brand">위클즈</span>와 함께해보세요!
            </h1>

            <p data-aos="fade-up">
                문화예술 강사님과 기관 담당자님들을 위한 즐거운 솔루션,<br>
                위클즈를 이용해보세요!
            </p>

            <div data-aos="fade-up">
                <a href="https://data.wecls.com/file/OrganProposal.pdf" download>
                    <img src="@/assets/images/icon_download.png" alt="기관 제안서 받기" title="기관 제안서 받기"/>
                    <span>기관 제안서 받기</span>
                </a>

                <a href="https://data.wecls.com/file/InstProposal.pdf" download>
                    <img src="@/assets/images/icon_download.png" alt="강사 제안서 받기" title="강사 제안서 받기"/>
                    <span>강사 제안서 받기</span>
                </a>
            </div>

            <MainSlide/>
        </div>

        <div class="why-us contact main-box">
            <div class="inner">
                <h5 class="brand margin-bottom" data-aos="fade-up">
                    WHY US?
                </h5>

                <h1 data-aos="fade-up">
                    강의에만 집중할 수 있는 환경 👩‍💼<br><span class="brand">위클즈</span>가 만들어드릴게요!
                </h1>

                <p data-aos="fade-up">
                    오직 강사님들을 위한 단 하나의 매칭서비스 위클즈!<br>
                    많은 강사님들이 눈에 띄는 변화를 경험하고 있답니다.
                </p>

                <ImageCard/>
            </div>
        </div>

        <div class="why-us review-box main-box">
            <div class="inner">
                <h5 class="brand margin-bottom" data-aos="fade-up">
                    WHY US?
                </h5>

                <h1 data-aos="fade-up">
                    📑 복잡하고 머리 아픈 강사 선정, 서류업무는<br><span class="brand">위클즈</span>에게 맡기세요
                </h1>

                <p data-aos="fade-up">
                    강사 찾고, 섭외하고, 서류업무하고... 몸이 두 개여도 모자른 담당자님들 주목!<br>
                    고민 많고 손 많이 가는 업무, 위클즈가 도와드릴게요!
                </p>

                <Image2Card/>
            </div>
        </div>

        <!--        <div class="why-us main-box">
                    <div class="inner">
                        <h5 class="brand" data-aos="fade-up">
                            WHY US?
                        </h5>

                        <h1 data-aos="fade-up">
                            🔒 개인 회원님들을 위한 인증된 안전한 교육
                        </h1>

                        <p data-aos="fade-up">
                            위클즈의 깐깐한 강사 검증을 통한<br>
                            안전하고 수준 높은 강의를 여러 장소에서도 즐겨보세요.
                        </p>

                        <Image3Card/>
                    </div>
                </div>-->

        <div class="review-box why-us main-box">
            <div class="inner">
                <h5 class="brand margin-bottom" data-aos="fade-up">
                    WHY US?
                </h5>

                <h1 data-aos="fade-up">
                    🌟 고객님들의 진짜 리뷰
                </h1>

                <p data-aos="fade-up">
                    위클즈 서비스를 이용하고 계신 다양한 회원분들의<br>
                    생생한 리뷰들을 만나보세요.
                </p>

                <ReviewCard data-aos="fade-up"/>
            </div>
        </div>

        <div class="why-us contact main-box no-border">
            <div class="inner">
                <h5 class="brand margin-bottom" data-aos="fade-up">
                    CONTACT
                </h5>

                <h1 data-aos="fade-up">
                    📬 위클즈 문의하기
                </h1>

                <p data-aos="fade-up">
                    궁금한 사항이 있으시다면 언제든지 문의해주세요.<br>위클즈는 항상 여러분의 의견을 듣고자 합니다.
                </p>

                <ContactBox data-aos="fade-up"/>
            </div>
        </div>
        <MainFooter/>
    </div>
</template>

<script>
import MainSlide from "@/views/static/MainSlide.vue";
import ImageCard from "@/views/static/ImageCard.vue";
import MainHeader from "@/views/static/MainHeader.vue";
import Image2Card from "@/views/static/Image2Card.vue";
import ReviewCard from "@/views/static/ReviewCard.vue";
// import Image3Card from "@/views/static/Image3Card.vue";
import ContactBox from "@/views/static/ContactBox.vue";
import MainFooter from "@/views/static/MainFooter.vue";

export default {
    name      : "indexVue",
    components: {MainFooter, ContactBox, ReviewCard, Image2Card, MainHeader, ImageCard, MainSlide},
};
</script>

<style lang="scss" scoped>
.main-box {
    width: 100%;
    color: $text-color-lv-2;
    text-align: center;
    border-bottom: 20px solid $bk-color-lv-2;

    &.no-border {
        border-bottom: 0;
    }

    h1 {
        font-size: $font48;
        color: black;

        .point{
            font-size: $font32;
        }
    }

    p {
        font-size: $font24;
        margin: 42px 0;
    }
}

.main-banner {
    padding: 200px 0;

    div {
        width: 100%;
        margin: 0 auto;
        text-align: center;

        a {
            padding: 10px 14px;
            border-radius: 8px;
            border: 1px solid $text-color-lv-2;
            font-weight: 700;
            display: inline-block;
            background-color: $bk-color-lv-3;
            transition: all .2s ease;

            &:hover{
                background-color: $brand-color-lv-1;
                border: 1px solid $brand-color-lv-1;
            }

            &:first-child {
                margin-right: 9px;
            }

            img {
                vertical-align: middle;
                margin-right: 8px;
            }

            span {
                vertical-align: middle;
            }
        }
    }
}

.why-us {
    padding: 120px 0;

    .inner {
        & > p {
            margin-bottom: 80px;
            font-size: $font20;
        }

        .margin-bottom{
            margin-bottom: 30px;
        }
    }
}

.review-box {
    background-color: $bk-color-lv-4
}

.contact {
    background-color: $bk-color-lv-1;
}
</style>