<template>
    <div class="mobile">
        <div class="mobile-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoginBox"
}
</script>

<style lang="scss" scoped>
.mobile {
    width: 360px;
    height: 736px;
    padding: 38px 21px;
    border-radius: 30px;
    @include shadow16;
    background-color: $bk-color-lv-3;
    position: relative;
    margin-left: 22.5%;
    overflow: hidden;

    &-content {
        padding-top: 20px;
        @include hiddenScroll;
        height: calc(100% - 40px);
        padding-bottom: 100px;
    }

    img {
        position: absolute;
        left: 16px;
        cursor: pointer;
        width: 20px;
    }

    h2 {
        text-align: center;
        font-size: $font14;
        font-weight: 700;
        color: $text-color-lv-1;
    }
}
</style>