import { createStore } from 'vuex';
import dataModule from '@/modules/dataModule'; 
import joinModule from '@/modules/joinModule'; 
import loginModule from '@/modules/loginModule'; 
import idFindModule from '@/modules/idFindModule'; 
import pwFindModule from '@/modules/pwFindModule'; 
import profileUserModule from '@/modules/user/profileUserModule';               // 강사 프로필 
import dashUserModule from '@/modules/user/dashUserModule';                     // 강사 대시보드
import dateUserModule from '@/modules/user/dateUserModule';                     // 강사 일정
import matchingModule from '@/modules/user/matchingModule';                     // 강사 매칭관리
import userMsgModule from '@/modules/user/msgModule';                           // 강사 메세지 관리
import gradeModule from '@/modules/user/gradeModule';                           // 강사 리뷰관리
import lectModule from '@/modules/user/lectModule';                             // 강사 강의 관리
import calcModule from '@/modules/user/calcModule';                             // 강사 정산
import admLectMngModule from '@/modules/admin/lectMngModule'                    // 기관 강의관리
import admMsgModule from '@/modules/admin/msgModule';                           // 기관 메세지 관리
import paymentModule from '@/modules/admin/paymentModule'                       // 기관 정산
import profileAdmModule from '@/modules/admin/profileAdmModule';                // 기관 정보
import collectMngModule from '@/modules/admin/collectMngModule';                // 기관 강의 등록
import detailModule from '@/modules/user/detailModule';                         // 강사 강의 상세 정보
import mainModule from '@/modules/user/mainModule';                             // 강사 강의 리스트 정보
import userFindAdmModule from '@/modules/admin/userFindAdmModule';              // 기관 유저 목록 정보
import userFindDetailAdmModule from '@/modules/admin/userFindDetailAdmModule';  // 기관 유저 상세  정보
import dashAdmModule from '@/modules/admin/dashAdmModule';                      // 기관 대시보드  정보
import dateAdmModule from '@/modules/admin/dateAdmModule';                      // 기관 일정
import userMngModule from '@/modules/admin/userMngModule';                      // 기관 공고 관리
import userMngDetailModule from '@/modules/admin/userMngDetailModule';          // 기관 공고 지원자 관리
import gradeMngModule from '@/modules/admin/gradeMngModule';                    // 기관 리뷰관리

const store = createStore({
    modules: {
        data                :   dataModule,
        join                :   joinModule,
        idFind              :   idFindModule,
        pwFind              :   pwFindModule,
        login               :   loginModule,
        profile             :   profileUserModule,
        dash                :   dashUserModule,
        date                :   dateUserModule,
        userMsg             :   userMsgModule,
        match               :   matchingModule,
        grade               :   gradeModule,
        lect                :   lectModule,
        gradeMng            :   gradeMngModule,
        calc                :   calcModule,
        admLectMng          :   admLectMngModule,
        admMsg              :   admMsgModule,
        payment             :   paymentModule,
        profileAdm          :   profileAdmModule,
        collect             :   collectMngModule,
        detail              :   detailModule,
        main                :   mainModule,
        userFindAdm         :   userFindAdmModule,
        userAdmDetailFind   :   userFindDetailAdmModule,
        dashAdm             :   dashAdmModule,
        dateAdm             :   dateAdmModule,
        userMng             :   userMngModule,
        userMngDetail       :   userMngDetailModule
    }
});

export default store;