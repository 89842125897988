/**
 * @description 기관 유저 상세  vuex 모듈 관련
 * @author 김원명, @date 2023-12-21
 */

const userMngDetailModule = {
    namespaced: true,
    state: {
        userMngDetail: {
            subject: '',
            search : {
                area    : { name : '전체', value : ''},
                career  : { name : '전체', value : ''},
                lecture : { name : '전체', value : ''},
                order   : { name : '오름차순', value : 'ASC'}
            },
            cnt    : [
                { title : '지원자 접수', count : 0 },
                { title : '서류 통과', count : 0 },
                { title : '2차 검증', count : 0 },
                { title : '최종 매칭', count : 0 },
                { title : '전체', count : 0 }
            ],
            allList : [],
            list    : [],
            itCd    : []
        }
    },
    mutations: {
        setSubject(state, newSubject)
        {
            state.userMngDetail.subject = newSubject;
        },
        setCnt(state, newCnt)
        {
            state.userMngDetail.cnt = newCnt
        },
        setAllList(state, newAllList)
        {
            state.userMngDetail.allList = newAllList;
            state.userMngDetail.list    = newAllList;
        },
        setList(state, newList)
        {
            state.userMngDetail.list = newList;
        },
        setItCdReset(state)
        {
            state.userMngDetail.itCd = [];
        }
    },
};


export default userMngDetailModule;
