/**
 * @description 유저 이력서 정보 vuex 모듈 관련
 * @author 정해원, @date 2023-11-20
 */

const profileUserModule = {
    namespaced: true,
    state: {
        profile: {
            info    : getProfileInfo(),
        }
    },
    mutations: {
        setInfo(state, profileData) {
            state.profile.info = profileData;
        },
        setMainFieldCd(state, newMainFieldCd)
        {
            state.profile.info.mainFieldCd = newMainFieldCd
        },
        setFieldCd(state, newFieldCd)
        {
            state.profile.info.fieldCd = newFieldCd
        },
        updateFile(state, { fileType, file }) 
        {
            state.profile.info[fileType] = {
                text: file.name,
                file: file
            };
        },
        clearFile(state, key) 
        {
            if (state.profile.info[key]['file'] !== null) 
            {
                state.profile.info[key] = {
                    text : '',
                    file : null
                };
            }
        },
        getDataFile(state, { gb, index }) 
        {
            if(gb === 'A')
            {
                /** 파일 추가 */
                state.profile.info.dataFile.push({ text : '', file : null, gb : 'N' });

                const fileCheck = state.profile.info['fileGb'].indexOf(item => item.fileGb === 'O' && item.fileNo === (index + 1));

                if(fileCheck !== -1)
                {
                    state.profile.info['fileGb'].splice(fileCheck, 1);
                }
    
                if(state.profile.info['fileGb'].length === 0)
                {
                    state.profile.info['delYn'] = 'N';
                }
            }
            else
            {
                /** 파일 삭제 */
                const data = state.profile.info['dataFile'][index];

                if(data['gb'] === 'Y')
                {
                    state.profile.info['delYn'] = 'Y';
                }

                const check = state.profile.info['fileGb'].some(item => item.fileGb === 'O' && item.fileNo === data['fileNo']);

                if (!check) 
                {
                    state.profile.info['fileGb'].push({fileGb : 'O', fileNo : data['fileNo']});
                }

                if(index !== 0)
                {
                    state.profile.info.dataFile.splice(index, 1);
                }
                else
                {
                    state.profile.info.dataFile[0] = {
                        text : '',
                        file : null,
                        gb   : 'N'
                    };
                }
            }
        },
        getDataFileSelect(state, { index, file }) 
        {
            state.profile.info.dataFile[index] = {
                text: file.name,
                file: file
            };
        }
    },
};

function getProfileInfo()
{
    return {
        itCd            : '',
        iaCd            : '',
        mainFieldCd     : '',
        fieldCd         : '001',
        appeal          : '',
        proofImg        : {
            text        : '',
            file        : null,
            gb          : 'N'
        },
        name            : '',
        tel             : '',
        birthday        : '',
        gender          : '',
        emerTel         : '',
        area1           : '',
        prefDay         : [],
        acadAbil        : '',
        school          : '',
        major           : '',
        career          : '',
        repCareer       : ['', '', ''],
        instMemo        : '',
        careerMemo      : '',
        finalEduImg     : {
            text        : '',
            file        : null,
            gb          : 'N'
        }, 
        credentialsImg  : {
            text        : '',
            file        : null,
            gb          : 'N'
        },
        dataFile        : [
            {
                text    : '',
                file    : null,
                gb      : 'N'
            }
        ],
        pubYn           : 'Y',
        delYn           : 'N',
        fileGb          : []
    };
}



export default profileUserModule;
