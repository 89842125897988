<template>
    <div>
        <MainHeader/>
        <div class="inner section">
            <div class="info-content">
                <h5>매칭플랫폼</h5>
                <h1>위클즈 소개</h1>
                <p>
                    위클즈는 다양하고 질 좋은 컨텐츠를 가진<br>
                    <b>강사와 기관을 연결</b>해주는 것뿐만 아니라<br>
                    <b>정보제공과 소통</b>도 제공합니다
                </p>

                <div class="button-wrapper">
                    <button type="button" @click="showTemplate(1)" :class="{ 'active': activeTemplate === 1 }">강사님 👩🏻‍🏫</button>
                    <button type="button" @click="showTemplate(2)" :class="{ 'active': activeTemplate === 2 }">교육담당자님 👩🏻‍💻</button>
                </div>

                <div class="banner" v-if="activeTemplate === 1">
                    <img src="@/assets/images/user_banner_1.jpg"  data-aos="fade-up" />
                    <img src="@/assets/images/user_banner_2.jpg" data-aos="fade-up" />
                    <img src="@/assets/images/user_banner_3.jpg" data-aos="fade-up" />
                </div>

                <div class="banner" v-if="activeTemplate === 2">
                    <img src="@/assets/images/admin_banner_1.jpg" data-aos="fade-up" />
                    <img src="@/assets/images/admin_banner_2.jpg" data-aos="fade-up" />
                    <img src="@/assets/images/admin_banner_3.jpg" data-aos="fade-up" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MainHeader from "@/views/static/MainHeader.vue";

export default {
    name: "WclsInfo",
    components: {MainHeader},
    data() {
        return {
            activeTemplate: 1
        };
    },
    methods: {
        showTemplate(templateNumber) {
            this.activeTemplate = templateNumber;
        }
    }
}
</script>

<style lang="scss" scoped>
.section{
    color: #010101;

    .info-content{
        padding: 200px 0 400px 0;
        text-align: center;

        h5{
            font-size: $font16;
            font-weight: 400;
            margin-bottom: 10px;
        }

        h2{
            font-size: 50px;
            font-weight: 800;
        }

        p{
            font-size: $font30;
            font-weight: 300;
            margin-top: 50px;
            margin-bottom: 150px;

            b{
                font-weight: 500;
            }
        }

        .button-wrapper{
            text-align: center;

            button{
                border-radius: 50px;
                padding: 25px 40px;
                font-size: $font28;
                font-weight: 500;
                border: 2px solid #ababab;

                &.active{
                    background-color: #000000;
                    box-shadow: 0px 10px 40px -10px rgba(0, 0, 0, 0.40);
                    border: 2px solid #000000;
                    color: white;
                }
            }

            button + button{
                margin-left: 20px;
            }
        }

        .banner{
            margin-top: 100px;

            img + img{
                margin-top: 30px;
            }
        }
    }
}
</style>