import { getDate, getConvertDate } from "@/assets/js/function";

const date = getDate('mon');

/**
 * @description 기관 강의 관리 vuex 모듈 관련
 * @author 김원명, @date 2024-01-10
 */
const admLectMngModule = {
    namespaced  : true,
    state       : {
        admLectMng : {
            search  : {
                type    : { name : '이달', value : 'mon' },
                startDt : getConvertDate(date['st'], 'yyyy-mm-dd'),
                endDt   : getConvertDate(date['et'], 'yyyy-mm-dd')
            },
            headers     : [
                { title : '전체 리뷰수', count : 0 },
                { title : '평균 평점', count : 0 },
                { title : '최고 평점', count : 0 },
                { title : '최소 평점', count : 0 },
                { title : '미답글', count : 0 }
            ],
            filters     : [
                { title : '대기중인 평가', count : 0 },
                { title : '평가 완료', count : 0 },
                { title : '전체', count : 0 }
            ],
            allList : [],
            list    : []
        }
    },
    mutations : {
        setHeaders(state, newHeaders)
        {
            state.admLectMng.headers = newHeaders
        },
        setFilters(state, newFilters)
        {
            state.admLectMng.filters = newFilters
        },
        setAllList(state, newAllList)
        {
            state.admLectMng.allList = newAllList;
            state.admLectMng.list    = newAllList;
        },
        setList(state, newList)
        {
            state.admLectMng.list = newList;
        }
    }
};
  
export default admLectMngModule;