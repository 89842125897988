import { getAxiosData } from "@/assets/js/function";
import store from "@/store";
import { useRouter } from "vue-router";

/**
 *  @description 기관 일정관리
 */
export async function getDateAdmInfo() 
{
    const router = useRouter();

    try 
    {
        const res = await getAxiosData('https://data.wecls.com/organ/dashboard/getCalendar');
        
        const DateAdmInfo = res.data.map(item => {
            const [startDate, endDate] = item.lectPeriod.split(' ~ ');

            if(item.matchSt === 'R' || item.matchSt === 'Y')
            {
                return {
                    matchSt     : item.matchSt,
                    ogNm        : item.ogNm,
                    title       : `[${item.matchStNm}] ${item.subject}`, 
                    start       : startDate,
                    end         : endDate,
                    color       : item.matchSt === 'R' ? '#9FE4FF' : '#FFC0BD',
                    textColor   : '#000000'
                };
            }
        });

        store.commit('dateAdm/setDateAdmInfo', DateAdmInfo);
    } 
    catch (error) 
    {
        console.error(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'organ');
            router.push('/organLogin');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}